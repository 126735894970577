<div class="content-wrapper container-xxl p-0">
    <div class="content-body card-body card">
        <!-- <div class="content-header">
            <h5 class="mb-0 text-black fw-700 fs-14  mb-10">Buy Currency Notes</h5>
            <hr class="my-50 hr-color">
        </div> -->
        <div class="row" *ngIf="!isCartEmpty">
            <div class="col-md-3"></div>

            
        </div>
        <loader *ngIf="showLoader"></loader>
        <div class="row mt-30">
            <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12 card-order">
                <div class="col-md-7">
                    <div class="col-md-9 mt-20">
                        <section class="card-section">
                            <div class="row">
                            <div class="swipe-container">
                              <div class="">
                                <div class="newcard-border dash-newcard">
                                  <img src="assets/images/smartfx-portal-assets/buy-forex/card.png" class="newcard-img">
                                  <div class="newcard-text">Smart Currency Card</div>
                                  <div class="newcard-text2">Multicurrency Card with INR Wallet</div>
                                  <div class="newcard-text3">Ideal for International Travellers</div>
                                  <button class="btn btn-newcard btn-prev fs-14" (click)="proceedToBuySelectedCard('SmartCurrencyCard')" *ngIf="!isSmartCardAvl">
                                    Buy Card
                                  </button>
                                  <button class="btn btn-newcard1 btn-prev fs-14" (click)="goToLinkExistingCard()" *ngIf="!isSmartCardAvl">
                                    Link Card
                                  </button>
                                  <button class="btn btn-newcard1 btn-prev fs-14" (click)="goToMyCard('smartCurrencyCard')" *ngIf="isSmartCardAvl">
                                    Choose Card
                                  </button>
                                  <div class="newcard-text4" (click)="goToSmartCurrCardFeatures()">Know the Features</div>
                                </div>
                              </div>
                          
                             
                              <div class="">
                                <div class="newcard-border dash-newcard1">
                                  <img src="assets/images/smartfx-portal-assets/buy-forex/student-pay-card.png" class="newcard-img">
                                  <div class="newcard-text">Student Pay Card</div>
                                  <div class="newcard-text2">US Dollar Card with Zero Cross Currency Fee</div>
                                  <div class="newcard-text3" style="margin-left: 40px;">Ideal for Students</div>
                                  <button class="btn btn-newcard btn-prev fs-14" (click)="openModalForRequsetForexCard()" *ngIf="!isStudentPayAvl && !isApplyForCard">
                                    Buy Card
                                  </button>
                                  <button class="btn btn-newcard1 btn-prev fs-14" (click)="goToLinkStudentCardExistingCard()" *ngIf="!isStudentPayAvl">
                                    Link Card
                                  </button>
                                  <button class="btn btn-newcard1 btn-prev fs-14" (click)="goToMyCard('studentPayCard')" *ngIf="isStudentPayAvl">
                                    Choose Card
                                  </button>
                                  <div class="newcard-text4" (click)="goToNewFeatures()">Know the Features</div>
                                </div>
                              </div>
                            </div>
                            </div>
                          </section>
                    </div>
                </div>
                
            </div>



            <!--<div class="col-md-6 col-xl-6 col-lg-6 col-sm-12 col-xs-12" *ngIf="!isCartEmpty">
            
                <div>
                    <table class="table table-bordered newtable-responsive" style="width: 50%;">
                        <thead>
                            <tr>
                                <th>Product</th>
                                <th>Currency</th>
                                <th>Amount</th>
                                <th>Rate</th>
                                <th>INR Amount</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody class="fs-10 text-black">
                            <tr *ngFor="let singleTransaction of quotationCurrencyResponsesList; let i = index">
                                <td>
                                    {{singleTransaction?.product}}
                                </td>
                                <td>
                                    {{singleTransaction?.currencyCode}}
                                </td>
                                <td>
                                    {{singleTransaction?.currencyAmount}}
                                </td>
                                <td>
                                    {{singleTransaction.rateString | number : '1.2-2'}}
                                </td>
                                <td>
                                    {{singleTransaction.inrEquivalentAmountString}}
                                </td>
                              
                                
                                <td>
                                    <span class="blue-text"><a
                                            (click)="openModalForEditCart()">Edit
                                        </a></span>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <section class="invoice-preview-wrapper">
                    <div class="newtable-responsive mt-15 amout-breakup-bg">
                        <table class="table table-condensed table-borderless  fs-12">
                            <tbody>
                                <tr style="border-top:1px solid #f4f4f4">
                                    <td colpan="2"></td>
                                </tr>
                                <tr>
                                    <td class="thick-line text-black fw-600" colspan="3"><strong>Amount</strong></td>
                                    <td class="thick-line text-right text-black fw-600 "> ₹ {{quotationResponse?.billableAmountString}}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="no-line " colspan="3"><strong>Forex Margin</strong></td>
                                    <td class="no-line text-right">₹ {{ quotationResponse?.marginChargesString}} </td>
                                </tr>
                                <tr>
                                    <td class="no-line " colspan="3"><strong>Service Charges </strong></td>
                                    <td class="no-line text-right">₹ {{ quotationResponse?.finalChargesString }} </td>
                                </tr>
                                <tr *ngIf="quotationResponse?.tcs?.tcsAmountString!=0.0">
                                    <td class="no-line " colspan="3"><strong>TCS ({{ quotationResponse?.tcs?.tcsPercentageString }}%)</strong></td>
                                    <td class="no-line text-right">₹ {{ quotationResponse?.tcs?.tcsAmountString }} </td>
                                </tr>
                                <tr>
                                    <td class="no-line " colspan="3"><strong>GST</strong></td>
                                    <td class="no-line text-right">₹ {{ quotationResponse?.gstString }} </td>
                                </tr>

                                <tr style="border-top:1px solid #bdbcbc">
                                    <td class="no-line text-blue fw-700 pt-10" colspan="3"> Total Payable </td>
                                    <td class="no-line text-right text-blue fw-700 pt-10"> ₹ {{ quotationResponse?.totalBillableAmountString | number }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </section>

            </div>-->
            <div class="col-md-1 newdashed-border"></div>
             <div class="custom-width" style="border: 1px solid #1D2A62;">
                <ul ngbNav #nav="ngbNav" class="nav nav-pills" style="margin-bottom: 0px !important; ">
                    <li class="nav-item w-100" ngbNavItem >
                        <a ngbNavLink class="nav-link" href="#buyForex" data-toggle="tab" style="border-radius: 7px;">Buy Forex</a>
                        <ng-template ngbNavContent>
                            <section id="buyForex">
                                <div class="row">
                                    <div class="col-md-12"
                                         placeholder="Select" style="height: 36.54px;width: 90%;margin-left: 14px;" class="form-control card-shadow fs-12" readonly>
                                        <option selected> Forex Card </option>
                                       
                                    </div>
                                    <div class="col-md-12 mt-20">
                                        <div class="row">
                                            <div class="col-md-12 col-12">
                                                <div class="input-group">
                                                    <div ngbDropdown class="input-group-prepend">
                                                        <ng-select class="fs-12" [clearable]="false" [items]="currencyItems" bindLabel="currencyCode" bindValue="currencyCode" [(ngModel)]="currencySelected" (change)='convertAmount()' [searchable]="false">
                                                            <ng-template class="ng-select-container" ng-option-tmp ng-label-tmp let-item="item">
                                                                <img src="assets/images/currencyFlag/{{item.currencyCode}}.png" style="height:15px;width: 15px;"> {{item.currencyCode}}
                                                            </ng-template>
                                                        </ng-select>
                                                    </div>
                                                    <input type="number" class="form-control" placeholder="Amount" (keypress)="keyPressNumbers($event)"[(ngModel)]="currencyAmount" name="currencyAmount" 
                                                        (keyup)="convertAmount()" />
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-12 ">
                                                <div class="input-group  mt-20">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text bg-inr" id="basic-addon3">
                                                            <img src="assets/images/logo/india.png" class="inr-flag"  style="height:15px;width: 15px;">
                                                            &nbsp; INR
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" id="basic-url3" aria-describedby="basic-addon3" placeholder="Amount" [value]="rateCalculatorResponse?.inrAmount" readonly />
                                                </div>
                                                <small class="form-text blue-text fs-9 text-right fw-600" *ngIf="rateCalculatorResponse?.rate !=null"> 
                                                    <!-- 1 {{quotationResponse?.quotationCurrencyResponses[0].currencyCode}} = ₹ {{quotationResponse?.quotationCurrencyResponses[0].rateString}} -->
                                                    1 {{ currencySelected }} = {{
                                                        rateCalculatorResponse?.rate }} INR

                                                </small>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-12 mt-10">
                                        <span class="fs-18 fw-600">You Pay -  <span class="fs-19 fw-600"> ₹ {{rateCalculatorResponse?.inrAmountWithCharges}}
                                        </span></span>

                                        <div class="text-black40 fs-9 mt-5">Including Forex Markup
                                        </div>
                                        <div class="fw-600 fs-9">*Plus Government taxes as applicable</div>
                                    </div>
                                    <div class="col-md-12 mt-20">
                                        <!-- <button type="submit" rippleEffect class="btn btn-primary fs-10 w-100" (click)="proceedToBuySelectedCard()">
                                            Book Now
                                        </button> -->
                                    </div>

                                </div>
                            </section>
                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="nav" class="card p-20"></div>
            </div>
        </div>
        

       
    
        <hr class="dotted">

       <!-- <div class="d-flex justify-content-between mt-20">
            <button type="button" class="btn btn-primary btn-back fs-12" rippleEffect (click)="backClick()">
                    <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                    <span class="align-middle">Back</span>
                </button>
            <button type="submit" class="btn btn-primary btn-next fs-12 web-currency-buy-order-started" (click)="checkVcip()" rippleEffect>
                    <span class="align-middle">Next</span>
                    <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                </button>
        </div>-->
    </div>
</div>
<ng-template let-modal #AmountLimitModal>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <span class="text-center">Dear Customer,</span><br>
        <span class="text-center"> {{cashLimitErrorMsg}}</span><br>
    </div>
    <div class="row">
        <button class="offset-md-5 mt-10 mb-10 btn btn-primary fs-10" rippleEffect
            (click)="modal.dismiss('Cross click')">
            Okay </button>
    </div>
</ng-template>

<ng-template #deleteConfirmationDailog let-modal>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <div class="text-center text-black fw-500 mt-10 mb-10 fs-12">
              <span class="text-center"> Do you want to Remove </span> <br> {{deleteItemMesg}}
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6 modal-footer border-right">
            <div class="row col-12">
                <div class="fw-500 fs-12 blue-text" (click)="removeCartItem()" style="cursor: pointer; padding-left: 50%;">
                    Yes
                </div>
            </div>
        </div>
        <div class="col-6 modal-footer border-left">
            <div class="row col-12">
                <div class="fw-500 fs-12 red-text-delete" (click)="modal.dismiss('Cross click')"
                    style="cursor: pointer; padding-left: 40%;">
                    No
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- vcip complete or not modal  -->
<ng-template let-modal #vcipModalNewUser>
    <!-- <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div> -->
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <div class="text-center text-black fw-500 mt-10 mb-10 fs-14" style="color: black">
                Dear Customer, Your <span class="fw-600 text-black">Video Based Identification Process (VCIP)</span> is pending.
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="row ">
            <button class="col-md-4 offset-md-1  btn  fs-10 vcip-btn" rippleEffect (click)="VCIPNow()">
                I will complete my VCIP Now
            </button>
            <!-- <div class="col-md-1"></div> -->
            <button class="col-md-4  offset-md-1 btn btn-primary fs-10" rippleEffect (click)="proceedWithourVCIP()">
                I will complete my VCIP Later
            </button>
        </div>
    </div>
</ng-template>

<!-- vcip complete or not modal  -->
<ng-template let-modal #vcipCompleteOrNotModal>
    <div class="modal-header" >
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="proceedWithourVCIP()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body  fs-13 p-40" tabindex="0" ngbAutofocus>
        <div class="row">
            <div class="col-md-3">
                <img src="assets/images/VCIP.png" style="width:100%">
            </div>
            <div class="col-md-9 fs-13">
                <div class="font-bold-black  mb-20">In order to complete the transaction, Video-Know Your Customer (VKYC) step has to be completed by you.</div>
                <p class="font-normal-black">Kindly complete the video KYC at the earliest. Video KYC is mandatory and is to be completed within 24 hours of receiving the VKYC link. For transactions booked on Saturday, please ensure that VKYC is done within the next working day</p>

                <p class="font-bold-red">Also note that non-completion of VKYC shall render your order to be cancelled, and funds will be returned to the originating bank account.</p>

                <p class="font-bold-black  mb-20">{{vkycTimingText}}</p>

                <p class="font-normal-blue mb-20 ">If you have any further queries, please contact our Customer Care at 8976707222 or email us at customersupport@wsfx.in
                </p>

                <div class="fw-500 fs-13 blue-text" style="cursor: pointer;" (click)="modal.dismiss()">
                    <!-- <button class="btn vcip-btn fs-13" (click)="proceedWithourVCIP()"> Schedule your VKYC later</button> &nbsp; &nbsp; -->
                    <button class="btn btn-primary fs-13" (click)="initiateVcip()"> Complete your VKYC now</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template let-modal #PANDetailModal>
        <div class="modal-header" style="display:block; ">
            <h5 class="modal-title fw-600" id="myModalLabel1" style="text-align:center; color: #1d2a62;">Customer Details</h5>
        </div>
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <label class="form-label mb-5"><b>Permanent Account Number (PAN)*</b></label>
            <input required type="text" name="panNo" (keypress)="alphaNumberOnly($event)" (input)="getPanName()" class="form-control fs-12" style="border-color:black" placeholder="Enter your PAN"  maxlength="10" (paste)="onPaste($event)" [(ngModel)]="updateProfileModel.pan" required minlength="10" />
        </div>
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <label class="form-label mb-5"><b>Name as per Permanent Account Number*</b></label>
            <input required type="text" name="panName" class="form-control fs-12" style="border-color:black" placeholder="Name as per PAN"   (paste)="onPaste($event)" [(ngModel)]="updateProfileModel.name" required/>
        </div>
        <div class="modal-body">
        <div class="custom-control custom-checkbox col-md-12 fs-12">
            <input type="checkbox" [(ngModel)]="isPANCBChecked" id="checkboxForAcceptTermsAndCondition" class="custom-control-input"  style="border-top:  1.2px dashed rgb(138 144 150 / 75%) !important">
            <label class="custom-control-label text-black fs-11" for="checkboxForAcceptTermsAndCondition">  I declare that the PAN provided belongs to me.</label>
        </div>
        </div>
        <div class="modal-body">
            <label class="form-label"><b>Date Of Birth*</b></label>
            <div class="input-group">
                <input  class="form-control fs-12"   (click)="basicDP.toggle()" name="dobb" style="border-color:black" placeholder="Select Date of Birth" [maxDate]="dobMaxDate" [(ngModel)]="updateProfileModel.dob" ngbDatepicker #basicDP="ngbDatepicker"  [minDate]="{year: 1900, month: 1, day: 1}"
                   >
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="basicDP.toggle()" type="button" rippleEffect></button>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            
            <button class="col-md-12  mt-10 btn btn-primary fs-10"  rippleEffect (click)="validatePanAPI()">
            Proceed
        </button>
        </div>
</ng-template>
<ng-template #errorModal let-modal>
    <div class="modal-header">
        <div class="modal-title fs-10 text-black text-center" id="myModalLabel1">

        </div>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="text-center red-text-delete">Invalid Details !
        </div>
        <div class="text-center">
            {{panVerificationErrMsg}}
        </div>
    </div>
    
    <div class="mcol-md-12  text-center">
        <div class="mb-10 fw-500 fs-10 btn btn-primary" (click)="modal.dismiss('Cross click')"
            style="cursor: pointer;">
            Close
        </div>
    </div>
</ng-template>
<ng-template let-modal #EditCardModal>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="row">
            <div class="col-md-6 col-6 mb-1">
                <label class="form-label" for="country" style="color: black">Select Currency*</label>
                <ng-select [disabled]="isGICorBlockAccount" class="fs-12 dropdown-black" [clearable]="false" [items]="currencyItems" bindLabel="currencyCode" bindValue="currencyCode" [(ngModel)]="quotationRequest.quotationCurrencies[0].currencyCode" [searchable]="false">
                    <ng-template ng-option-tmp ng-label-tmp let-item="item">
                        <img src="assets/images/currencyFlag/{{item.currencyCode}}.png" style="height:15px;width: 15px;"> {{item.currencyCode}}
                    </ng-template>
                </ng-select>
            </div>
            <div class="col-md-6 col-6 mb-1">
                <label class="form-label" for="country" style="color: black">Enter Amount*</label>
                <input required type="number" name="amount" class="form-control fs-12" style="border-color:black" placeholder="Amount*" (keypress)="keyPressNumbers($event)" (paste)="onPaste($event)" [(ngModel)]="reqCurrencyAmount" required />
            </div>
        </div>
    </div>
    <div class="col-md-12  text-center ">
        <button class="mt-10 mb-10 btn btn-primary fs-10" rippleEffect (click)="getRateRange();updateCurrencyAmount()">
            Update
        </button>
    </div>
</ng-template>


<ng-template #requestForexCardModal let-modal>
    <div class="modal-header text-center">
        <div class="modal-title fs-10 text-black" id="requestForexCardModal">
            Register for Student Pay Card
        </div>
        <button type="button" class="close" (click)="closeModal()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="row">
            <div class="offset-0 col-12 offset-md-1 col-md-10">
                <form #form="ngForm" (ngSubmit)="onSubmitRequestForex(form)">
                    <div class="row">
                        <div class="col-6 form-group">
                            <label class="form-label text-black fs-11">First Name<span class="field-mandatory">*</span></label>
                            <input type="text" name="firstName" class="form-control fs-12" placeholder="First Name*" [(ngModel)]="firstName" required (keypress)="letterOnly($event)">
                            <span *ngIf="form.submitted && !form.controls['firstName']?.valid" class="invalid-form">
                                <small class="form-text text-danger">This field is required!</small>
                            </span>
                        </div>

                        <div class="col-6 form-group">
                            <label class="form-label text-black fs-11">Last Name<span class="field-mandatory">*</span></label>
                            <input type="text" name="lastName" class="form-control fs-12" placeholder="Last Name*" [(ngModel)]="lastName" required (keypress)="letterOnly($event)">
                            <span *ngIf="form.submitted && !form.controls['lastName']?.valid" class="invalid-form">
                                <small class="form-text text-danger">This field is required!</small>
                            </span>
                        </div>
            
                        <div class="col-6 form-group">
                            <label class="form-label text-black fs-11">Mobile No<span class="field-mandatory">*</span></label>
                            <input type="text" name="mobile_no" class="form-control fs-12" placeholder="Enter Mobile No*" [(ngModel)]="mobileNo" required maxlength="10" pattern="^[1-9][0-9]*$" oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';">
                            <span *ngIf="form.submitted && !form.controls['mobile_no']?.valid" class="invalid-form">
                                <small class="form-text text-danger">This field is required!</small>
                            </span>
                        </div>            
                
                        <div class="col-6 form-group">
                            <label class="form-label text-black fs-11">Email ID<span class="field-mandatory">*</span></label>
                            <input type="email" name="email" class="form-control fs-12" placeholder="Enter Email ID*" [(ngModel)]="email" required>
                            <span *ngIf="form.submitted && !form.controls['email']?.valid" class="invalid-form">
                                <small class="form-text text-danger">This field is required!</small>
                            </span>
                        </div>
                
                        <div class="col-md-6 form-group">
                            <label class="form-label" for="country">Select City<span class="field-mandatory">*</span></label>
                            <ng-select class="fs-12" [clearable]="false" [items]="cityItemList" bindLabel="cityItemList" bindValue="cityItemList" [(ngModel)]="city" placeholder="Select" name="city" #cityRef="ngModel" [class.error]="form.submitted && cityRef.invalid"
                                required [searchable]="false"> </ng-select>
                            <span *ngIf="form.submitted && cityRef.invalid" class="invalid-form">
                                <small class="form-text text-danger" *ngIf="cityRef.errors.required">This field is required!</small>
                            </span>
                        </div>                  
                    </div>
                
                    <div class="row mt-20">
                        <div class="col-12 text-center">
                            <button type="submit" rippleEffect class="btn btn-primary fs-12">
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #succesForRegisterStudentCard let-modal>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="row" style="justify-content: center; padding: 20px;">
            <img src="assets/images/smartfx-portal-assets/buy-forex/student_reg_popup_Icon.gif" style="width: 150px; height: 150px;"  />
            <p class="px-20 text-center">Hey, you've made the right choice in securing your trusted payment companion! Your request has been placed, and our enthusiastic service executive will reach out to you shortly to ensure a smooth and seamless experience!</p> 
            <button type="button" rippleEffect class="btn btn-primary fs-12" (click)="closeModal()"> Ok </button>            
        </div>
    </div>
</ng-template>
