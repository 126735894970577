import { Component, ComponentFactoryResolver, OnInit, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router';
import { FaqService } from 'app/modules/faq/services/faq.service';
import { HttpClient } from "@angular/common/http";


@Component({
  selector: 'student-faq',
  templateUrl: './student-faq.component.html',
  styleUrls: ['./student-faq.component.scss'],

})
export class StudentFaqComponent implements OnInit {
  public showLoader : boolean = true;

  public faqList:any = [];

  goToNewDashboard() {
    this.router.navigate(['/my-card']);
  }
  constructor(
    public router: Router,
    public httpClient: HttpClient,
  ) { }


  ngOnInit(): void {

    this.httpClient.get("https://s3.ap-south-1.amazonaws.com/faqs.wsfl.in/cardfaq.json").subscribe(res => {
      let resList:any = res;
      console.log("RESPONSE ------------------- ", res);
      for(let i = 0; i< resList.length; i++) {
        if(resList[i]["srNo"] == "3") {
          let desc = resList[i]["description"];
          resList[i]["description"] = desc.concat(' <a class="btn btn-link p-0 m-0 faq-link" style="color: blue !important; text-decoration: underline;" href="/branch-network">Click here</a> to locate a WSFx Branch.');
        } else if(resList[i]["srNo"] == "16") {
          let desc = resList[i]["description"];
          resList[i]["description"] = ' For an updated schedule of fees <a class="btn btn-link p-0 m-0 faq-link" style="color: blue !important; text-decoration: underline;" target="_blank" href="https://b2capptnc.s3.ap-south-1.amazonaws.com/Fee-and-Charges.pdf">Click here</a>.\n '+" "+desc;
        } else if(resList[i]["srNo"] == "32") {
          let desc = resList[i]["description"];
          resList[i]["description"] = desc.concat(' <a class="btn btn-link p-0 m-0 faq-link" style="color: blue !important; text-decoration: underline;" target="_blank" href="https://s3.ap-south-1.amazonaws.com/files.wsfx.in/smartFxWebPortalDocuments/WSFx-Forex-Prepaid-Card-Insurance-Cover-and-Policy-terms-and-conditions.pdf"> Click here</a> to know more.');
        }
      }
      console.log("FAQ LIST -- ", resList);
      this.faqList = resList;

    });
  }
  
}




 
 

  

 
 

