import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'isic-card',
  templateUrl: './isic-card.component.html',
  styleUrls: ['./isic-card.component.scss'],
  encapsulation: ViewEncapsulation.None
  // styleUrls:['../../../../../@core/scss/base/pages/app-ecommerce-details.scss']
})
export class IsicCardComponent implements OnInit { 
  public showMoreContents : boolean = false;
  

  public menuItems = [
    {
      'icon': 'isic1.png',
      'title': 'Global Student Identity Proof',
      'url': '',
    },
    {
      'icon': 'isic2.png',
      'title': 'Exclusive Discounts Worldwide',
      'url': '',
    },
    {
      'icon': 'isic3.png',
      'title': 'Travel and Accommodation Perks',
      'url': '',
    },
    {
      'icon': 'isic4.png',
      'title': 'Digital Card for Convenience',
      'url': '',
    },
    {
      'icon': 'isic5.png',
      'title': 'Special Versions for Teachers',
      'url': '',
    },
 
  ];
  public specItems = [
    {
      'icon': 'spec1.png',
      'title': 'For Full-Time Students Only',
      'url': '',
    },
    {
      'icon': 'spec2.png',
      'title': 'Annual Renewal Required',
      'url': '',
    },
    {
      'icon': 'spec3.png',
      'title': 'Valid for One Year',
      'url': '',
    },
    {
      'icon': 'spec4.png',
      'title': 'Proof of Enrollment Needed',
      'url': '',
    },
    {
      'icon': 'spec5.png',
      'title': 'Apply Online or Through Partners',
      'url': '',
    },
  ]

  public activeTab: number = 0;
  constructor(
    public router: Router,
  ) {}
  

  ngOnInit() {
  }
  setActiveTab(tabIndex: number) {
    this.activeTab = tabIndex;
  }
  goToNewFeatures() {
    this.router.navigate(['/newfeatures']);
  }
  // showContents(){
  //   this.showMoreContents = true;
  //   this.buttonText = 'View Less';
  // }

  // hideContents(){
  //   this.showMoreContents = false;
  //   this.buttonText = 'View More';
  // }

}
