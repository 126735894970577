import { Injectable } from "@angular/core";
import { BaseProvider } from "app/shared/helper/http/BaseProvider";

@Injectable()
export class SupportService extends BaseProvider {

    addServiceRequest(subject ,comment) {
        var resourceUrl = "addConsumerTicket?comment=" + comment + "&subject=" + subject;
        return this.makePostCall(resourceUrl, {});
    }

    getConsumerTickets(){
        var resourceUrl = "consumerTickets";
        return this.makeGetCall(resourceUrl);
    }

    getSingleConsumerTicketDetails(ticketID){
        var resourceUrl = "consumerTicketTrail?ticketId=" + ticketID;
        return this.makeGetCall(resourceUrl);
    }
    
    addConsumerTicket(comment,subject,primaryTicketID){
        var resourceUrl = "addConsumerTicket?comment=" + comment + "&subject=" + subject + "&primaryTicketId=" + primaryTicketID;
        return this.makePostCall(resourceUrl, {});
    }
    
    addFeedback(comment,subject){
        var resourceUrl = "addConsumerTicket?comment=" + comment + "&subject=" + subject ;
        return this.makePostCall(resourceUrl, {});
    }

    /* addRequestForCard(comment,subject){
        var resourceUrl = "b2c/addConsumerTicket?comment=" + comment + "&subject=" + subject ;
        return this.makePostCall(resourceUrl, {});
    } */    
}