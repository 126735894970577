import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Constants } from 'app/shared/constant/constants';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { BaseProvider } from 'app/shared/helper/http/BaseProvider';

@Injectable()
export class CurrencyConverterService extends BaseProvider {
  
  constructor(public http: HttpClient) {
    super(http);
  }
  
  getCurrencyConverterValues(surrenderCurrencyCode, requiredCountryCode, amount, conversionType) {
    var resourceUrl = "pricing/convertCurrency?partnerId=" + Constants.partnerId + "&surrenderingCurrencyCode=" + surrenderCurrencyCode +  "&requiredCurrencyCode=" + requiredCountryCode + "&surrenderingCurrencyAmount=" + amount + "&conversionType="+ conversionType;
    return this.makeGetCall(resourceUrl);
}

}
