export class CustomerValidationRequestModel {
    panNo: string;
    passport: string;
}

export class CardValidationRequestModel {
    cardExpiry: string;
    dob: string;
}

export class SetNewPinModel {
    newPin: string;
    newPinConfirm: string;
    kitNo: string;
    expDate: string;
    dob: string;
    otp: string;
}

export class OTPRequestModel {
    authType: string;
    id: string;
    otpRequestReason: string;
}

export class CardBlockModel {
    cardManageOperations: string;
    cardSwitch: string;
    kitId: string;
    comment: string;
}

export class SetCardLimitModel {
    currencyCode: string;
    dailyAtmLimitValue: string;
    dailyPosLimitValue: string;
    dailyEcomLimitValue: string;
    dailyContactLessLimitValue: string;
}

export class RemittanceStep01Model {
    currencyAmount: any;
    currencyCode: string;
    product: string;
    purpose: string = "";
    countryCode: string;
    branchId: string;
    bidRate: string;
    beneficiaryName: string;
    nostroChargeType: string;
    sellType: string;
}

export class QuotationRequestModel {
    email: string;
    name: string;
    mobile: string;
    location: string;
    sellType: string;
    partnerId: string;
    promoCode: string;
    nostroChargeType: string;
    calculateTcs: boolean;
    quotationSnapshotId: string;
    quotationCurrencies: QuotationCurrenciesRequestModel[] = new Array<QuotationCurrenciesRequestModel>();
    tcsPaymentSource: string;
    pan: string;
    tcsPaymentSourceDetails: string;
    otherSourcePaymentDetails: string;
    paymentSourceDetails: string;
    pointsToRedeem: string;
    subPurpose : string;
    purpose : string;
    issuerCode: string;
}
export class QuotationCurrenciesRequestModel {
    currencyCode: string;
    rate: number;
    bidRate: number;
    currencyAmount: number;
    product: string;
    purpose: string;
    subPurpose: string;
    partnerId: string;
    sellType: string;
}

export class CartItemCreateRequestList {
    currencyAmount: any;
    currencyCode: string;
    product: string;
    subPurpose: string;
    bidRate: number;
    ibrRate: any;
    userId: string;
    partnerId: string;
    branchId: string;
    currencyInUsd: string;
    cardNumber: string;
    orderType: string;
    nostroCharges: string;
    sellType: string;
    countryCode: string;
    purpose: string;
    beneficiaryName: string;
    nostroChargeType: string;
}

export class QuotationResponseModel {
    billableAmount: number = 0;
    gst: number = 0;
    partnerCommissionTotal: number = 0;
    quotationCurrencyResponses: QuotationCurrencyResponsesModel[] = new Array<QuotationCurrencyResponsesModel>();
    totalCharges: number = 0;
    quotationSnapshotId: string;
    totalBillableAmount: number = 0;
}
export class QuotationCurrencyResponsesModel {
    beneficiaryCharges: number;
    convenienceFees: number;
    currencyAmount: number;
    currencyCode: string;
    inrEquivalentAmount: number;
    partnerCommissionTotal: number;
    product: string;
    purpose: string;
    rate: number;
    transactionFees: number;
    partnerCommission = new PartnerCommission();
    quotationCurrencyResponses: QuotationCurrencyResponsesModel[] = new Array<QuotationCurrencyResponsesModel>();
}

export class PartnerCommission {
    commission: string;
    serviceChargesShare: string;
}

export class OrderRequestModel {
    sellType: string;
    consumerId: string;
    orderItems: OrderItemModel[] = new Array<OrderItemModel>();
    orderDocumentMappingRequest: any = null;
    payerType: string = "SELF";
    beneficiaryDetails = new BeneficiaryCreateRequest();
    kycMode: string = "AUTO";
    partnerId: string;
    quotationSnapshotId: string;
    passportNumber: string;
    pan: string;
    departureDate: string;
    arrivalDate: string;
    address: string;
    visitingCountry: string;
    numberOfDays: number;
    passportIssueDate: string;
    passportExpiryDate: string;
    passportIssuePlace: string;
    wsflReferenceBranchId: string;
    kitId: string;
    bankId: string;
}
export class BeneficiaryCreateRequest {
    consumerId: string;
    address: string;
    bankAccountNumber: string;
    bankAddress: string;
    bankName: string;
    countryCode: string;
    createdAt: string;
    id: string;
    name: string;
    sortCode: string;
    swiftCode: string;
    updatedAt: string;
    additionalDetails: string;
}

export class OrderItemModel {
    currencyAmount: number;
    currencyCode: string;
    product: string;
    purpose: string;
    countryCode: string;
    bidRate: number;
    cardNumber: string;
    orderType: string;
    cardRate: number;
    inrAmount: number;
    commission: number;
    commissionPerUnit: number;
    serviceChargesShare: number;
    min: number;
    max: number;
    discount: number;
}

export class TransferBalanceModel {
    authType: string;
    id: string;
    otpRequestReason: string;
}

export class TransferBalanceToWalletModel {
    toCurrencyCode: string;
    fromCurrencyCode: string;
    amount: string;
    agreement: string;
    otp: string;
}

export class ManageCardUpdateModel {
    cardManageOperations: string;
    cardSwitch: string;
    comment: string;
    kitId: string;
}

export class InitiatePaymentforCartRequest {
    amount: string;
    paymentMedium: string;
    paymentModeCategoryId: string;
    payertype: string;
    agreement: string;
}

export class WalletTransferRequestModel {
    fromCurrencyCode: string;
    toCurrencyCode: string;
    rate: number;
    amount: number;
    inrAmount: number;
    balAmount: number;
    agreement: boolean;
    otp: string;
    minimumRateInINR: number;
    isAdded: boolean;
}

export class ReplaceCardModel {
    reason: string;
    kitId: string;
    constructor(reason?: string, kitId?: string) {
        this.reason = reason;
        this.kitId = kitId;
    }
}

export class LinkCardMobileRequestModel {
    mobileNumber: string;
    otp: string;
    constructor(otp?: string, mobileNumber?: string) {
        this.otp = otp;
        this.mobileNumber = mobileNumber;
    }
}

export class LinkCardRequest02Model {
    cardExpiry: string;
    dob: string;
    constructor(cardExpiry?: string, dob?: string) {
        this.cardExpiry = cardExpiry;
        this.dob = dob;
    }
}

export class OfacFatfCardModel {
    occupationLengthResidenceForeign: string;
    sourceOfFund: string;
    nationality: string;
    lengthResidenceForeign: string;
    natureBusinessInIndia: string;
    companyProfile: string;
    transactionHistory: string;
    refOrderId: string;
    isFrequentFlyerCountry: boolean;
}