<loader *ngIf="showLoader"></loader>
<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6 mb-20">
                        <h6 class=" float-left mb-0 ml-10 fs-18 fw-700" style="color: #1D2A62">International eSIM</h6>
                    </div> 
                </div>
                <div class="col-md-12 mb-20 text-start" style="color: black;">
                    <p>Select your country to view free esim plans</p>  
                </div>
                <div class="row mt-6" style="display: flex;">
                    <div class="col-md-2 col-6 mb-3 country-section" *ngFor="let singleItem of menuItems" style="">
                        <div class="d-flex flex-column align-items-center" (click)="countrySelectionChange(singleItem)" >
                            <img src="assets/images/international-esim/{{singleItem.icon}}" style="width: 110px; height: 110px;" >
                            <div class="fs-14 fw-600 country-name">
                                {{ singleItem.title }}
                            </div>
                        </div>
                    </div>
                </div>
                
                <!-- Free Plan Card -->
                <div class="container-fluid" *ngIf="showFreePlanCard" >
                    <div class="row">        
                        <div class="col-md-12 mb-20 text-start">
                            <p><strong>Free Plan</strong></p>  
                        </div>                  
                        <div class="col-md-3 col-12 plan-details" >
                            <div class="row">
                                <div class="col-12">
                                    <div class="d-flex " style="justify-content: space-evenly;align-items: center;">
                                        <img src="/assets/images/flag/{{selectedCountry.flag}}" style="height: 30px; width: 30px;border-radius: 50%; ">
                                        <div class="text" style="font-size: 16px;">
                                            <strong>{{selectedCountry.title}}</strong>
                                        </div>
                                    </div>

                                    <!-- <div class="int-text1" style="margin-left: 60px;margin-top: 15px;">
                                        Data
                                    </div>
                                    <div class="int-text2" style="margin-left: 120px;margin-top: 15px;">
                                        Days
                                    </div>
                                    <div style=" margin-top: 40px; margin-left: -180px;">
                                        1.5
                                    </div>
                                    <div style=" margin-top: 40px; margin-left: 130px;">
                                        15 Days
                                    </div> -->
                                </div>
                                <div class="offset-2 col-8">
                                    <div class="d-flex" style="justify-content: space-around;">
                                        <div>Data<br> {{planData.data}}</div>
                                        <div>Days<br> {{planData.validity}} Days</div>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-20">
                                    <button type="submit" rippleEffect class="btn-intcard fs-10 w-80" (click)="placeOrder()" >
                                        Place Order
                                    </button>
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>       
