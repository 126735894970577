import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { CardService } from '../../services/card.service';
import { ToastrService } from 'ngx-toastr';
import { ConstantsKey } from "app/shared/constant/constants-keys";

@Component({
  selector: 'international-esim',
  templateUrl: './international-esim.component.html',
  styleUrls: ['./international-esim.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InternationalEsimComponent implements OnInit {
    public showLoader: boolean = false;
    showFreePlanCard: boolean = false;
    planData: any;
    selectedCountry: any;


    public menuItems = [
      {
        'icon': 'usa.png',
        'title': 'United States of America',
        'url': '',
        'countryCode': 'US',
        'flag': 'usd.png'
      },
      {
        'icon': 'cad.png',
        'title': 'Canada',
        'url': '',
        'countryCode': 'CA',
        'flag': 'cad.png'
      },
      {
        'icon': 'aus.png',
        'title': 'Australia',
        'url': '',
        'countryCode': 'AU',
        'flag': 'aud.png'
      },
      {
        'icon': 'uk.png',
        'title': 'United Kingdom',
        'url': '',
        'countryCode': 'GB',
        'flag': 'gbp.png'
      },

      {
        'icon': 'germany.png',
        'title': 'Germany',
        'url': '',
        'countryCode': 'DE',
        'flag': 'usd.png'
      },
      {
        'icon': 'newzea.png',
        'title': 'New-Zealand',
        'url': '',
        'countryCode': 'NZ',
        'flag': 'nzd.png'
      },
      {
        'icon': 'france.png',
        'title': 'France',
        'url': '',
        'countryCode': 'FR',
        'flag': 'usd.png'
      },
      
    ];
  
    constructor(
      public router: Router,
      public cardService: CardService, 
      public toast: ToastrService
     
    ) { }
  
    ngOnInit() {
      this.getProfileList();
    }
  
   
  
    getProfileList() {
     
    }
    showFreePlan(singleItem: any) {
       
        this.showFreePlanCard = true;
      }
  
   
  
    proceedToNextPage(pageUrl) {
      this.router.navigate([pageUrl]);
    }

    countrySelectionChange(obj) {
      this.showLoader = true;
      this.selectedCountry = obj;
      this.cardService.esimPackages(obj.countryCode).subscribe(res => {
        this.showLoader = false;
        if(res.body) {
          this.showFreePlanCard = true;
          this.planData = res.body;
        } else {
          this.showFreePlanCard = false;
          this.planData = {};          
        }
      }, err => {
        this.showFreePlanCard = false;
        this.planData = {};
        this.toast.error(err.error.message);        
      })
    }
  
    placeOrder() {
      sessionStorage.setItem(ConstantsKey.ESIM_SELECTED_PLAN, JSON.stringify(this.planData));
      this.router.navigate(['/link-international-card']);
    }
}
