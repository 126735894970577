<div class="content-wrapper  container-xxl p-0">
    <div class="row mb-20">
        <div class="col-md-12">
            <h5 class="content-header-title float-left mt-20 mb-0 fs-13">Set Limit</h5>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <ng-select class="fs-12" [items]="currencyItems" [(ngModel)]="currencyCode" bindLabel="currencyCode"
                bindValue="currencyCode" [clearable]="false" [searchable]="false" (change)="onCurrencyChange($event)" [disabled]="cardType == 'studentPayCard'">
                <ng-template class="ng-select-container" ng-option-tmp ng-label-tmp let-item="item">
                    <img src="assets/images/currencyFlag/{{item?.currencyCode}}.png"
                        style="height:15px;width: 15px;"> {{ item?.currencyCode }}
                </ng-template>
            </ng-select>

            <!-- <small class="form-text text-black20 fs-10">
                1 USD = ₹ 73.2
            </small> -->
        </div>
    </div>
    <div class="row mt-20">
        <div class="col-md-12 mb-40">
            <h6 class="card-title fs-12">Set Daily ATM Limit</h6>
            <div class="row">
                <div class="col-md-6 fs-10 text-black">Current Limit</div>
                <div class="col-md-6 text-right fs-10 text-black">{{ setCardLimitModel.dailyAtmLimitValue }}</div>
                <div class="col-md-12">
                    <nouislider [min]="0" [connect]="'lower'" [behaviour]="'tap'" [max]="tempDailyATMLimit" [step]="100"
                        [(ngModel)]="setCardLimitModel.dailyAtmLimitValue"></nouislider>
                </div>
                <div class="col-md-4 fs-10 text-black">0</div>
                <div class="col-md-4 fs-10 text-black text-center">{{ setCardLimitModel.dailyAtmLimitValue }}
                </div>
                <div class="col-md-4 fs-10 text-black text-right">{{tempDailyATMLimit}}</div>
            </div>
        </div>

        <div class="col-md-12 mb-40">
            <h6 class="card-title fs-12">Set Daily ECOM Limit</h6>
            <div class="row">
                <div class="col-md-6 fs-10 text-black">Current Limit</div>
                <div class="col-md-6 text-right fs-10 text-black"> {{ setCardLimitModel.dailyEcomLimitValue }} </div>
                <div class="col-md-12">
                    <nouislider [min]="0" [connect]="'lower'" [behaviour]="'tap'" [max]="tempDailyEcomLImit" [step]="100"
                        [(ngModel)]="setCardLimitModel.dailyEcomLimitValue"></nouislider>
                </div>
                <div class="col-md-4 fs-10 text-black">0</div>
                <div class="col-md-4 fs-10 text-black text-center">
                    {{ setCardLimitModel.dailyEcomLimitValue }}
                </div>
                <div class="col-md-4 fs-10 text-black text-right">{{tempDailyEcomLImit}}</div>
            </div>
        </div>

        <div class="col-md-12 mb-40">
            <h6 class="card-title fs-12">Set Daily POS Limit</h6>
            <div class="row">
                <div class="col-md-6 fs-10 text-black">Current Limit</div>
                <div class="col-md-6 text-right fs-10 text-black"> {{setCardLimitModel.dailyPosLimitValue }}</div>
                <div class="col-md-12">
                    <nouislider [min]="0" [connect]="'lower'" [behaviour]="'tap'" [max]="tempDailyPOSLimit" [step]="100"
                        [(ngModel)]="setCardLimitModel.dailyPosLimitValue"></nouislider>
                </div>
                <div class="col-md-4 fs-10 text-black">0</div>
                <div class="col-md-4 fs-10 text-black text-center">
                    {{ setCardLimitModel.dailyPosLimitValue }}
                </div>
                <div class="col-md-4 fs-10 text-black text-right">{{tempDailyPOSLimit}}</div>
            </div>
        </div>

        <div class="col-md-12" *ngIf="this.cardType == 'studentPayCard'">
            <h6 class="card-title fs-12">Set Daily Contact Less Limit</h6>
            <div class="row">
                <div class="col-md-6 fs-10 text-black">Current Limit</div>
                <div class="col-md-6 text-right fs-10 text-black"> {{setCardLimitModel.dailyContactLessLimitValue }}</div>
                <div class="col-md-12">
                    <nouislider [min]="0" [connect]="'lower'" [behaviour]="'tap'" [max]="tempDailyContactLessLImit" [step]="100"
                        [(ngModel)]="setCardLimitModel.dailyContactLessLimitValue"></nouislider>
                </div>
                <div class="col-md-4 fs-10 text-black">0</div>
                <div class="col-md-4 fs-10 text-black text-center">
                    {{ setCardLimitModel.dailyContactLessLimitValue }}
                </div>
                <div class="col-md-4 fs-10 text-black text-right">{{tempDailyContactLessLImit}}</div>
            </div>
        </div>        

        <div class="col-md-12 text-right mt-20  mb-20">
            <button type="submit" rippleEffect class="btn btn-primary fs-10" (click)="setCardLimit()">
                Apply Now
            </button>
        </div>
    </div>
</div>
<ng-template #disableCardModal let-modal>
    <div class="modal-header">
        <div class="modal-title fs-10 text-black text-center" id="myModalLabel1">

        </div>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="text-center">
            Your card is temporarily Locked. Please Unlock your card to proceed!
        </div>
    </div>
    <div class="modal-footer">
        <div class="col-md-2 fw-500 fs-10 text-center blue-text btn btn-primary" (click)="modal.dismiss('Cross click')"
            style="cursor: pointer;">
            Close
        </div>
    </div>
</ng-template>