import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { ToastrService } from 'ngx-toastr';

import {Location} from '@angular/common';

@Component({
  selector: 'esim',
  templateUrl: './esim.component.html',
  styleUrls: ['./esim.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EsimComponent implements OnInit {
  public showLoader: boolean = false;
  isChecked: boolean = false; 

  
  public menuItems = [
    {
      'icon': 'esim1.png',
      'title': 'Instant Activation',
      'url': '',
    },
    {
      'icon': 'esim2.png',
      'title': 'Easy Top-Up Options',
      'url': '',
    },
    {
      'icon': 'esim3.png',
      'title': 'Seamless Coverage Across Borders',
      'url': '',
    },
    {
      'icon': 'esim4.png',
      'title': 'Secure and Hassle-free setup',
      'url': '',
    },
    {
      'icon': 'esim5.png',
      'title': 'No Hidden Fees',
      'url': '',
    },
    {
      'icon': 'esim6.png',
      'title': 'Hotspot Compatibility',
      'url': '',
    },
    {
      'icon': 'esim7.png',
      'title': 'Flexible Data Plans',
      'url': '',
    },
    {
      'icon': 'esim8.png',
      'title': '24/7 Customer Support',
      'url': '',
    },
    {
      'icon': 'esim9.png',
      'title': 'Support Multiple Devices',
      'url': '',
    },
  ];

 
  public esimItems = [
    {
      'icon': 'spec1.png',
      'title': 'Activation: Automatic, activated when connected to a cellular network.',
      'url': '',
    },
    {
      'icon': 'spec2.png',
      'title': 'Activation Policy: The validity period begins upon eSIM connection to a supported network.',
      'url': '',
    },
    {
      'icon': 'spec3.png',
      'title': 'Installation: Scan a QR code.',
      'url': '',
    },
    {
      'icon': 'spec4.png',
      'title': 'Plan Type: Data Prepaid',
      'url': '',
    },
    {
      'icon': 'spec5.png',
      'title': 'Other: FUP Policy applicable for Unlimited Plans per Day 1GB at Network provided speed and post 1GB it will throttle to 265kbps speed.',
      'url': '',
    },
    {
      'icon': 'spec6.png',
      'title': 'Tethering / Hotspot: Yes depending on local Operator policy',
      'url': '',
    },
    {
      'icon': 'spec7.png',
      'title': 'Technology: eSIM',
      'url': '',
    },
    {
      'icon': 'spec8.png',
      'title': 'Speed: 4G /5G',
      'url': '',
    },
  ];


  public activeTab: number = 0;

  constructor(
    public router: Router,
    public toast: ToastrService,
    private _location: Location
  ) { }

  ngOnInit(): void {
   
  }
  setActiveTab(tabIndex: number) {
    this.activeTab = tabIndex;
  }
  
  proceedToNextPage(pageUrl: string): void {
    this.router.navigate([pageUrl]);
  }
  onCheckboxChange() {
    console.log('Checkbox state:', this.isChecked);
  }

  claimNow() {
    let isStudentPayCard = sessionStorage.getItem(ConstantsKey.IS_STUDENT_PAY_CARD);
    if(this.isChecked) {
      if(isStudentPayCard && isStudentPayCard == 'true') {
        // this.toast.success('Coming soon!');
        this.router.navigate(["/international-esim"]);      
      } else {
        this.toast.error("Sorry! You do not have Student Pay Card");
      }
    } else {
      this.toast.error("Please accept terms and condition");
    }


  }
  goToNewFeatures() {
    this._location.back();
  }
 
}
