import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { MasterDataService } from 'app/shared/services/master-data.service';
import { Constants } from 'app/shared/constant/constants';
import { ISICCardService } from '../../services/isic-card.service';
import { DateUtils } from 'app/shared/utility/date-utils';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: 'link-isic-card',
  templateUrl: './link-isic-card.component.html',
  styleUrls: ['./link-isic-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LinkIsicCardComponent implements OnInit {
  public showLoader: boolean = false;
  showFreePlanCard: boolean = false;
  fullName: string;
  gender: string = '';
  email: string;
  mobileNo: string;
  address: string;
  nationality: string;
  purpose: string;
  vistingCountry:string;
  passportNo:string;
  postalCode: string;
  dob: string;
  city: string;
  public countryListItems: any;
   public genderListItems = Constants.genderListItems;
  public isicData:any;
  public requestStatus;
  public fileList: FileList;
  public photoStatus;
  public isicCardToken;
  public isicCardLink;
  public imageData;

  @ViewChild('eSIMCardModal') eSIMCardModal: any;
  
  constructor(
    public router: Router,
    private modalService: NgbModal,
    public masterDataService: MasterDataService,
    public isicSerivce: ISICCardService,
    public toast: ToastrService,
    public http: HttpClient
  ) {
    this.getCountryList();
  }

  ngOnInit() {
    let localData = sessionStorage.getItem(ConstantsKey.ISIC_STATUS_DATA);
    if(localData) {
      this.isicData = JSON.parse(sessionStorage.getItem(ConstantsKey.ISIC_STATUS_DATA));
      this.requestStatus = this.isicData.isicCardStatus;
      if(this.requestStatus == 'ACTIVE' && this.isicData.photoStatus && this.isicData["photoStatus"] == 'PENDING') {
        this.photoStatus = this.isicData.photoStatus;
      } else if(this.requestStatus == 'ACTIVE' && this.isicData.photoStatus && this.isicData.photoStatus == 'UPLOAD') {
        this.isicCardLink = this.isicData.verticalPageUrl;
        this.isicCardToken = this.isicData.isicCardViewToken;
        this.photoStatus = 'UPLOAD';
        this.fetchISICCardPhoto();
      }
    } else {
      this.requestStatus = 'NOT_REGISTER';
    }

    
    this.requestStatus = this.isicData.isicCardStatus;
    //this.getProfileList();
  }

  getProfileList() {
 
  }

  proceedToNextPage(pageUrl) {
    this.router.navigate([pageUrl]);
  }

  letterOnly(event: KeyboardEvent) {
    var charCode = event.keyCode;
    if (((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8) || charCode == 32)
      return true;
    else return false;
  }

 onSubmit(form: NgForm) {
  if (form.valid) {
    this.registerISICCard();
    // this.openeSIMCardModal();
  } else {

    this.markAllFieldsAsTouched(form);
    console.log('Form is invalid, please fill all required fields!');
  }
}

markAllFieldsAsTouched(form: NgForm) {
  Object.values(form.controls).forEach(control => {
    control.markAsTouched();  
  });
}


openeSIMCardModal() {
  this.modalService.open(this.eSIMCardModal, { 
    size: 'lg', centered: true 
  });
}

  closeModalAndNavigate() {
    this.modalService.dismissAll();
    this.router.navigate(['/new-dashboard']);
  }
  getCountryList() {
    this.masterDataService.getCountryList().subscribe(resp => {
      this.countryListItems = resp.body;
    }, error => {
    });
  }
  validatePassportNumber(passportNo) {
    const regex = /^[A-PR-WYZ][1-9]\d\s?\d{4}[0-9]$/;
    if (!regex.test(passportNo)) {
      return false;
    } else {
      return true;
    }
  }
  restrictSepcialCharacters(event) {
    var charCode = event.keyCode;
    if ((charCode > 96 && charCode < 123) || (charCode > 64 && charCode < 91) || (charCode >= 48 && charCode <= 57) || charCode <= 31)
      return true;
    else return false;
  }

  registerISICCard() {

    let requestObj = {
      "fullName": this.fullName,
      "dob": DateUtils.getNgbDateStructToDate(this.dob),
      "gender": this.gender,
      "email": this.email,
      "address": this.address,
      "city": this.city,
      "postal_code": this.postalCode
    };
    
    console.log("Regiter ISIC Card ", requestObj);
    
    this.isicSerivce.isicRegister(requestObj).subscribe(res => {
      this.openeSIMCardModal();
    }, err => {

    });
  }

  fileUploadPhoto(event) {
    this.fileList = event.target.files;
  }

  uploadPhoto() {
    this.showLoader = true;
    let docType;

    if (this.fileList.length > 0) {
      let file: File = this.fileList[0];
      if (file.type.indexOf("pdf") >= 0 || file.type.indexOf("image") >= 0) {
        const formData = new FormData();
        formData.append('photo', file, file.name);
        this.isicSerivce.uploadPhotoForIsicCard(formData).subscribe((respDoc) => {
            this.showLoader = false;
            if(respDoc["message"] == 'OK') {
              this.toast.success('Photo uploaded successfully!');
            }
        }, (error) => {
            this.showLoader = false;
            this.toast.error(error.error.message);
        });
        this.showLoader = false;
      }
    } else {
      this.showLoader = false;

    }
  }

  fetchISICCardPhoto() {
    let token = "Bearer "+this.isicCardToken;
    const headers = new HttpHeaders({'Authorization': token});
    console.log(headers);
    this.http.get(this.isicCardLink,{headers: headers}).subscribe(res => {
      console.log(res);
    });
  }


}

 

 

