<div class="content-wrapper container-xxl p-0" *ngIf="!showAddBankForm">
    <div *ngIf="!showEligibilityCheck" class="content-body card-body card">
        <!-- <div class="content-header">
            <h5 class="mb-0 text-black fw-700 fs-14  mb-10">Sell Currency Notes</h5>
            <hr class="my-50 hr-color">
        </div> -->
        <!-- <label class="text-danger fs-8 fw-600">Rates will refresh after {{ progressWidth }}
            minutes
        </label>
            <div class="progress">
            <div class="progress-bar bg-danger" role="progressbar" [ngStyle]="{'width.%': progressWidthInPercentage}" aria-valuemin="0"></div>
            </div> -->

        <div class="row" style="padding-top: 8px;">
            <div class="col-md-3"></div>

            <div class="col-md-6">
                <label class="text-danger fs-10 fw-500">Rates will refresh after {{ progressWidth }}
                            minutes
                        </label>
                <div class="progress">
                    <div class="progress-bar bg-danger" role="progressbar" [ngStyle]="{'width.%': progressWidthInPercentage}" aria-valuemin="0"></div>
                </div>


            </div>
        </div>

        <loader *ngIf="showLoader"></loader>
        <div class="row mt-30">
            <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12 card-order">
                <div class="row">
                    <!-- <section class="row mt-20" style="padding:0px 5px 0px 0px;"> -->
                        <div class="col-md-12 mb-1">
                            <!-- <div class="card  bg-light-grey" style="box-shadow : 0 0 0 0!important"> -->
                                <div class="card-header text-black fw-600 fs-11">
                                    Select Currency
                                </div>
                                <div class="row" style="padding:0rem 1.5rem 0.2rem 1.5rem">
                                    <div class="col-md-4 col-12  mb-1 pl-0">
                                        <ng-select [clearable]="false" name="currency" placeholder="Select" [items]="currencyItems" [(ngModel)]="currencySelectedCash" bindLabel="currencyCode" bindValue="currencyCode" class="fs-12" [searchable]="false">
                                            <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                                <img src="assets/images/currencyFlag/{{item.currencyCode}}.png" style="height:18px;width: 18px;"> {{item.currencyCode}}
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                    <div class="col-md-4 col-6 pl-0">
                                        <input type="number" [(ngModel)]="currencyAmountCash" class="form-control fs-11" placeholder="Amount*" aria-label="Amount" style="border-color: black"  (keypress)="numberOnly($event)"
                                        />
                                    </div>
                                    <div class="col-md-4 col-12 mb-1 pl-0 text-right">
                                        <button type="submit" rippleEffect class="btn btn-primary fs-11" style="height: 38px;" (click)="addCash()">Add Cash</button>
                                    </div>
                                </div>
                            <!-- </div> -->
                        </div>
                    <!-- </section> -->
                </div>
                <loader *ngIf="showLoader"></loader>
                <!-- <div class="col-md-12" *ngIf="quotationCurrencyResponsesList!=null">
                <div class="row mb-20" *ngFor="let singleProduct of quotationCurrencyResponsesList; let i = index">
                    <div class="col-md-8 col-8">
                        <span class="card-text fs-11 text-black fw-500">Product : </span>
                        <span class="fs-11 text-black"> {{singleProduct.product}} </span>
                    </div>

                    <div class="col-md-8 col-8">
                        <span class="card-text fs-11 text-black  fw-500">Currency: </span>
                        <span class="fs-11 text-black"> {{singleProduct.currencyCode}}</span>
                    </div>

                    <div class="col-md-8 col-8">
                        <span class="card-text fs-11 text-black  fw-500">Amount: </span>
                        <span class="fs-11 text-black"> {{singleProduct.currencyAmount}} </span>
                    </div>

                    <div class="col-md-8 col-8">
                        <span class="card-text fs-11 text-black  fw-500">IBR : </span>
                        <span class="fs-11 text-black"> ₹ {{singleProduct.rateString | number :
                            '1.2-2'}} </span>
                    </div>

                    <div class="col-md-8 col-8">
                        <span class="card-text fs-11 text-black  fw-500">INR Amount </span>
                        <span class="fs-11 text-black"> ₹ {{ singleProduct.inrEquivalentAmountString }}
                         </span>
                    </div>
                </div>
            </div> -->

                <div class="form-group col-md-12 mt-10" *ngIf="showBankAccounts && !showAddBankForm">
                    <label class="form-label fs-10" for="country">Select Nearest WSFx Branch*</label>
                    <ng-select class="fs-11" name="branch" [(ngModel)]="branchName" [items]="branchAddressList" bindLabel="branchName" bindValue="branchName" [clearable]="false" readonly (change)="changeWSFxBranch($event)" placeholder="Select Nearest WSFx Branch">
                    </ng-select>
                </div>
                <div class="form-group col-md-12 mt-10" *ngIf="showBankAccounts && !showAddBankForm">
                    <label class="form-label fs-10" for="country">Select Bank Account*</label>
                    <ng-select class="fs-11" [(ngModel)]="selectedBankId" [items]="bankAccountItems" bindLabel="showTitle" bindValue="bankId" [clearable]="false " readonly (change)="setSelectedBank($event)" placeholder="Select Bank Account" [searchable]="false">
                    </ng-select>
                </div>

                <div class="col-md-12" *ngIf="showBankDetails && !showAddBankForm">
                    <div class="bg-light-grey fs-10 p-10 text-black">
                        <div class="fw-500">Transfer to :</div>
                        <div>Bank : {{ selectedBankDetails.bankName }}</div>
                        <div>Account No : {{ selectedBankDetails.number }}</div>
                        <div>IFSC : {{ selectedBankDetails.ifscCode }}</div>
                        <div>Branch : {{ selectedBankDetails.branch }}</div>
                    </div>
                </div>

                <div class="col-md-12" *ngIf="showBankAccounts && !showAddBankForm">
                    <div class="fs-10 sky-blue-text fw-600 link" (click)="showAddBankForm=true">
                        Add Bank Account
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-12">
                <div class="row">
                    <div class="col-md-12  mb-5">
                        <h6 class="content-header-title float-left mb-0" style="color: #1866f4 !important">
                            Your Cart Details
                        </h6>
                    </div>

                </div>
                <div>
                <table class="table table-bordered table-responsive mt-20" *ngIf="isCartItemsAdded">
                    <thead>
                        <tr class="table-header">
                            <th>Product</th>
                            <th>Currency</th>
                            <th>FX Amount</th>
                            <th>IBR</th>
                            <th>INR Amount</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody class="fs-10 text-black">
                        <tr *ngFor="let singleTransaction of quotationCurrencyResponsesList; let i = index">
                            <th class="mob-table">Product</th>
                            <td>
                                {{singleTransaction?.product}}
                            </td>
                            <th class="mob-table">Currency</th>
                            <td>
                                {{singleTransaction?.currencyCode}}
                            </td>
                            <th class="mob-table">FX Amount</th>
                            <td>
                                {{singleTransaction?.currencyAmount}}
                            </td>
                            <th class="mob-table">Rate</th>
                            <td>
                                {{singleTransaction.rateString | number : '1.2-2'}}
                            </td>
                            <th class="mob-table">INR Amount</th>
                            <td>
                                {{singleTransaction.inrEquivalentAmountString}}
                            </td>
                            <th class="mob-table">Action</th>
                            <td>
                                <span class="blue-text-edit"><a (click)="openModalForEditCart()">Edit </a></span> | <span class="red-text-delete"><a (click)="removeCartItem(singleTransaction)">Delete </a></span>

                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
                <div class="table-responsive mt-10  amout-breakup-bg" *ngIf="isCartItemsAdded">
                    <table class="table table-condensed table-borderless fs-12 ">
                        <tbody>
                            <tr>
                                <td class="thick-line text-black fw-600" colspan="3"><strong>Amount</strong></td>
                                <td class="no-line text-right">₹ {{quotationResponse?.billableAmountString}}
                                </td>
                            </tr>
                            <tr>
                                <td class="no-line " colspan="3"><strong>Forex Margin</strong></td>
                                <td class="no-line text-right">-₹ {{ quotationResponse?.marginChargesString}}</td>
                            </tr>
                            <tr>
                                <td class="no-line " colspan="3"><strong>Service Charges</strong></td>
                                <td class="no-line text-right">-₹ {{ quotationResponse?.finalChargesString }}</td>
                            </tr>
                            <tr>
                                <td class="no-line " colspan="3"><strong>TCS ({{ quotationResponse?.tcs?.tcsPercentageString }}%)</strong></td>
                                <td class="no-line text-right">-₹ {{ quotationResponse?.tcs?.tcsAmountString }}</td>
                            </tr>

                            <tr class="no-line">
                                <td class="no-line " colspan="3"><strong>GST </strong></td>
                                <td class="no-line text-right"> -₹ {{ quotationResponse?.gstString }}</td>
                            </tr>

                            <tr style="border-top:1px solid #bdbcbc">
                                <td class="no-line text-black fw-800 pt-10" colspan="3"> Total Receivable </td>
                                <td class="no-line text-right text-black fw-800 pt-10"> ₹ {{ quotationResponse?.totalBillableAmountString | number }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-md-12">
                <!-- <label class="text-danger fs-8 fw-600">Rates will refresh after {{ progressWidth }}
                                minutes
                            </label>
                <div class="progress">
                    <div class="progress-bar bg-danger" role="progressbar" [ngStyle]="{'width.%': progressWidthInPercentage}" aria-valuemin="0"></div>
                </div> -->

                <section class="invoice-preview-wrapper" *ngIf="isCartItemsAdded">
                    <!-- <div class="table-responsive mt-15">
                        <table class="table table-condensed table-borderless  fs-12">
                            <tbody>
                                <tr style="border-top:1px solid #f4f4f4">
                                    <td colpan="2"></td>
                                </tr>
                                <tr>
                                    <td class="thick-line text-black fw-600" colspan="3"><strong>Amount</strong></td>
                                    <td class="thick-line text-right text-black fw-600 "> ₹ {{quotationResponse?.billableAmountString}}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="no-line " colspan="3"><strong>Forex Margin</strong></td>
                                    <td class="no-line text-right">-₹ {{ quotationResponse?.marginChargesString}} </td>
                                </tr>
                                <tr>
                                    <td class="no-line " colspan="3"><strong>Service Charges </strong></td>
                                    <td class="no-line text-right">-₹ {{ quotationResponse?.finalChargesString }} </td>
                                </tr>
                                <tr *ngIf="quotationResponse?.tcs?.tcsPercentageString">
                                    <td class="no-line " colspan="3"><strong>TCS ({{ quotationResponse?.tcs?.tcsPercentageString }}%)</strong></td>
                                    <td class="no-line text-right">-₹ {{ quotationResponse?.tcs?.tcsAmountString }} </td>
                                </tr>
                                <tr>
                                    <td class="no-line " colspan="3"><strong>GST</strong></td>
                                    <td class="no-line text-right">-₹ {{ quotationResponse?.gstString }} </td>
                                </tr>

                                <tr style="border-top:1px solid #f4f4f4">
                                    <td class="no-line text-black fw-800 pt-10" colspan="3"> Total Payable </td>
                                    <td class="no-line text-right text-black fw-800 pt-10"> ₹ {{ quotationResponse?.totalBillableAmountString | number }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div> -->

                    <div class="d-flex justify-content-between mt-20">
                        <button (click)="gotoDashboard()" type="button" class="btn btn-primary btn-back fs-12" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle">Back</span>
                                    </button>
                        <button type="submit" class="btn btn-primary btn-next fs-12 web-sell-currency-order" rippleEffect (click)="goToEligibilityCheck()">
                                        <span class="align-middle">Next</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                    </div>
                </section>
            </div>
        </div>
    </div>
    <ng-template let-modal #EligibilityModal>
        <div class="modal-header" style="display:block; ">
            <h5 class="modal-title fw-600" id="myModalLabel1" style="text-align:center; color: #1d2a62;">Eligibility Check</h5>
        </div>
        <div class="modal-body" tabindex="0" ngbAutofocus>

            <h6 class="text-center">Please answer the following question to check your eligibility as per RBI regulations.</h6>
            <hr class="my-50 hr-color">
        </div>
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <div class="fw-600 fs-14 text-center">Are you an Indian Resident ?
                <span class="showButton">
                        <img src="assets/images/icons/info.png"  style="width: 20px;">
                        <div class="openTooltip">
                            <div class="card container-fuild text-left" style="position: absolute;z-index:500; font-size:11px;">
                                <div class="card-body">
                                    <div class="fw-600">"Person resident in India" means: </div>
                                    <ul class="fw-400 pl-2">
                                        <li>a person residing in India for more than one hundred and eighty-two days during the course of the preceding FY but does not include :</li>
                                        <li>a person who has gone out of India or who stays outside India, in either case:</li>
                                        <li>for or on taking up employment outside India, or</li>
                                        <li>for carrying on outside India a business or vocation outside India, or</li>
                                        <li>for any other purpose, in such circumstances as would indicate his intention to stay outside India for an uncertain period;</li>
                                        <li>a person who has come to or stays in India, in either case, otherwise than:</li>
                                        <li>for or on taking up employment in India, or</li>
                                        <li>for carrying on in India a business or vocation in India, or</li>
                                        <li>for any other purpose, in such circumstances as would indicate his intention to stay in India for an uncertain period;</li>
                                    </ul>

                                    <div class="fw-600">Explanation</div>
                                    <div class="fw-400">- The residential status of a person leaving India will be determined as under:if a person leaves India for the purpose of employment, business or for any other purpose that indicates his intention to stay outside India for an uncertain period; then he becomes a person resident outside India from the day he leaves India for such purpose.</div>
                                    <div class="fw-400">- The residential status of a person returning to India will be determined as under:If a person comes to India for the purpose of employment, business or for any other purpose that indicates his intention to stay in India for an uncertain period; then he becomes a person resident in India from the day he comes to India for such purpose. Under FEMA, stay for a period of 182 days is also stated. However, it shall be noted that the residential status of a person in our opinion is primarily determined basis the intention of the person to stay in India.</div>
                                    <ul class="fw-400 pl-2">
                                        <li>any person or body corporate registered or incorporated in India;</li>
                                        <li>an office, branch or agency in India owned or controlled by a person resident outside India;</li>
                                        <li>an office, branch or agency outside India owned or controlled by a person resident in India</li>
                                    </ul>
                                    <div class="fw-400"> "Person resident outside India" means a person who is not a resident of India</div>
                                </div>
                            </div>
                        </div>
                    </span>
            </div>
        </div>

        <div class="modal-body" tabindex="0" ngbAutofocus>


            <div class="row mb-10 d-flex justify-content-center">
                <!-- <div class="col-md-2 fs-12 fw-500">
                </div> -->

                <div class="col-6 fs-12 fw-500 text-right">
                    <input type="radio" name="radios" id="radio" value='Yes' (click)="openModalForPANNo()" /> &nbsp; &nbsp;YES
                </div>
                <div class="col-6 fs-12 fw-500 text-left">
                    <input type="radio" name="radios" id="radio" value='No' (click)="openNotAnIndianResidentPopup()" /> &nbsp; &nbsp;NO
                </div>
                <!-- <div class="col-md-2 fs-12 fw-500">
                </div> -->
            </div>

        </div>
    </ng-template>

</div>
<div class="content-wrapper container-xxl p-0" *ngIf="showAddBankForm">
    <div class="content-body card-body card">
        <add-bank-account-for-remittance (childCallbackForAddBankAccountForm)="parentCallbackForAddBankAccountForm($event)">
        </add-bank-account-for-remittance>
    </div>
</div>
<ng-template let-modal #PANDetailModal>
    <div class="modal-header" style="display:block; ">
        <h5 class="modal-title fw-600" id="myModalLabel1" style="text-align:center; color: #1d2a62;">Customer Details</h5>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <label class="form-label mb-5"><b>Permanent Account Number (PAN)*</b></label>
        <input required type="text" name="panNo" class="form-control fs-12" (keypress)="alphaNumberOnly($event)" (input)="getPanName()" style="border-color:black" placeholder="Enter your PAN" (paste)="onPaste($event)" [(ngModel)]="updateProfileModel.pan"
        />
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <label class="form-label mb-5"><b>Name as per Permanent Account Number*</b></label>
        <input type="text" name="panName" class="form-control fs-12" style="border-color:black" placeholder="Name as per PAN" (paste)="onPaste($event)" [(ngModel)]="updateProfileModel.name" />
    </div>
    <div class="modal-body">
        <div class="custom-control custom-checkbox col-md-12 fs-12">
            <input type="checkbox" [(ngModel)]="isPANCBChecked" id="checkboxForAcceptTermsAndCondition" class="custom-control-input" style="border-top:  1.2px dashed rgb(138 144 150 / 75%) !important">
            <label class="custom-control-label text-black fs-11" for="checkboxForAcceptTermsAndCondition">  I declare that the PAN provided belongs to me.</label>
        </div>
    </div>
    <div class="modal-body">
        <label class="form-label"><b>Date Of Birth*</b></label>
        <div class="input-group">
            <input class="form-control fs-12" (click)="basicDP.toggle()" name="dob" style="border-color:black" placeholder="Select Date of Birth" [maxDate]="dobMaxDate" [(ngModel)]="updateProfileModel.dob" ngbDatepicker #basicDP="ngbDatepicker" [minDate]="{year: 1900, month: 1, day: 1}">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="basicDP.toggle()" type="button" rippleEffect></button>
            </div>
        </div>
    </div>
    <div class="modal-footer">

        <button class="col-md-12  mt-10 btn btn-primary fs-10" rippleEffect (click)="validatePanAPI()">
            Proceed
        </button>
    </div>
</ng-template>


<ng-template let-modal #NotAnIndianResidentPopup>
    <div class="modal-header">
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body text-center" tabindex="0" ngbAutofocus>
        <label class="form-label mb-10"><b>For more support, please give us a call at 8976707222*</b></label>
        <button class="col-md-3 mt-10 btn btn-primary fs-10" rippleEffect (click)="modal.dismiss()">
            Close
        </button>
    </div>
</ng-template>
<ng-template let-modal #EditCardModal>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="row">
            <div class="col-md-6 col-5 mb-1">
                <ng-select [clearable]="false" name="currency" placeholder="Select" [items]="currencyItems" [(ngModel)]="currencySelectedCashEdit" bindLabel="currencyCode" bindValue="currencyCode" class="fs-12" [searchable]="false">
                    <ng-template ng-option-tmp ng-label-tmp let-item="item">
                        <img src="assets/images/currencyFlag/{{item.currencyCode}}.png" style="height:18px;width: 18px;"> {{item.currencyCode}}
                    </ng-template>
                </ng-select>
            </div>
            <div class="col-md-6 col-7 pl-0">
                <input type="number" [(ngModel)]="currencyAmountCashEdit" class="form-control fs-11" placeholder="Amount*" (keypress)="keyPressNumbers($event)" aria-label="Amount" style="border-color: black" 
                />
            </div>
        </div>
    </div>
    <div class="col-md-12  text-center">
        <button class="mb-10 mt-10 btn btn-primary fs-10" rippleEffect (click)="editCash()">
           Update
        </button>
    </div>
</ng-template>

<ng-template #errorModal let-modal>
    <div class="modal-header">
        <div class="modal-title fs-10 text-black text-center" id="myModalLabel1">

        </div>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="text-center red-text-delete">Invalid Details
        </div>
        <div class="text-center">
            {{panVerificationErrMsg}}
        </div>
    </div>

    <div class="col-md-12  text-center">
        <div class=" mb-10 fw-500 fs-10  btn btn-primary" (click)="modal.dismiss('Cross click')" style="cursor: pointer;">
            Close
        </div>
    </div>
</ng-template>