import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ConstantsKey } from "app/shared/constant/constants-keys";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";

import { CryptoUtils } from "app/shared/utility/crypto.utils";
import { EncApiList } from "app/shared/utility/enc_api_list";

@Injectable()
export class BaseProvider {

    public BASE_URL = environment.base_url;
    public BASE_URL_GRID = environment.base_url_grid;
    public API_KEY = environment.api_key;

    public headers = new HttpHeaders();
    public headersUpload = new HttpHeaders();
    public httpClient: HttpClient;

    public error: Error;
    public status: number;
    public errorMessage: string;

    public encApiList: EncApiList = new EncApiList();
    public cryptoUtils: CryptoUtils = new CryptoUtils();

    constructor(public http: HttpClient) {
        this.httpClient = http;
        this.getHeaders();
        this.getHeadersForUpload();
    }

    getHeaders(channel = "consumerswebb2c") {
        this.headers = this.headers.set('Content-Type', 'application/json; charset=utf-8');
        this.headers = this.headers.set('Cache-Control', 'no-cache');
        //this.headers = this.headers.set('CHANNEL', 'consumerswebb2c');
        //this.headers = this.headers.set('CHANNEL', "widget");
        this.headers = this.headers.set('CHANNEL', "globalpayportal");
        let xAuthToken = localStorage.getItem(ConstantsKey.X_AUTH_TOKEN);
        if (xAuthToken) {
            this.headers = this.headers.set('X-AUTH-TOKEN', xAuthToken);
        } else {
            this.headers = this.headers.set('X-AUTH-TOKEN', environment.api_key);
        }
    }

    addHeader(key: string, value: string) {
        this.headers.set(key, value);
    }

    getHeadersForUpload() {
        this.headersUpload = this.headersUpload.set('Cache-Control', 'no-cache');
        let xAuthToken = localStorage.getItem(ConstantsKey.X_AUTH_TOKEN);
        if (xAuthToken) {
            this.headersUpload = this.headersUpload.set('X-AUTH-TOKEN', xAuthToken);
        } else {
            this.headersUpload = this.headersUpload.set('X-AUTH-TOKEN', environment.api_key);
        }
    }

    getJSONfromModel(model) {
        if (model) {
            return JSON.stringify(model);
        }
    }

    checkEndPointMatch(resourceURL) {
        return this.encApiList.encApi.find(apiEndPoint => {
            if (apiEndPoint == resourceURL || resourceURL.includes(apiEndPoint)) {
                console.log("API end point : ", resourceURL);
                return true;
            }
            return false;
        });
    }

    makePostCall(apiEndPoint: string, requestModel: any) {
        this.getHeaders();
        const apiUrl = this.BASE_URL + apiEndPoint;
        if (this.checkEndPointMatch(apiEndPoint)) {
            console.log(">>>>inside encrypted post");
            try {
                requestModel = this.cryptoUtils.reqEncrypt(JSON.stringify(requestModel));
            } catch (err) {
                console.log("Error in encryption!!", err);
            }
        }
        console.log("inside encrypted post not>>>>");

        return this.http
            .post(apiUrl, requestModel, { headers: this.headers, observe: 'response' })
            .pipe(catchError((error) => {
                let errorReason = error.error.reason;
                if (errorReason == "ACCESS_TOKEN_EXPIRED") {
                    window.location.href = "/logout";
                    return;
                }
                return throwError(error);
            }));
    }
    makePostCallWitList(apiEndPoint: string, requestModel: any=[]) {
        this.getHeaders();
        const apiUrl = this.BASE_URL + apiEndPoint;
        if (this.checkEndPointMatch(apiEndPoint)) {
            try {
                requestModel = this.cryptoUtils.reqEncrypt(JSON.stringify(requestModel));
            } catch (err) {
                console.log("Error in encryption!!", err);
            }
        }

        return this.http
            .post(apiUrl, requestModel, { headers: this.headers, observe: 'response' })
            .pipe(catchError((error) => {
                let errorReason = error.error.reason;
                if (errorReason == "ACCESS_TOKEN_EXPIRED") {
                    window.location.href = "/logout";
                    return;
                }
                return throwError(error);
            }));
    }

    makePutCall(apiEndPoint: string, requestModel: any) {
        this.getHeaders();
        const apiUrl = this.BASE_URL + apiEndPoint;
        return this.http
            .put(apiUrl, this.getJSONfromModel(requestModel), { headers: this.headers, observe: 'response' })
            .pipe(catchError((error) => {
                let errorReason = error.error.reason;
                if (errorReason == "ACCESS_TOKEN_EXPIRED") {
                    window.location.href = "/logout";
                    return;
                }
                return throwError(error);
            }));
    }

    makePatchCall(apiEndPoint: string, requestModel: any) {
        if (apiEndPoint.includes("consumers/pax")) {
            this.getHeaders("studentapp");
        } else {
            this.getHeaders();
        }
        const apiUrl = this.BASE_URL + apiEndPoint;
        console.log("patchRequest>>"+this.getJSONfromModel(requestModel));
        if (this.checkEndPointMatch(apiEndPoint)) {
            try {
                requestModel = this.cryptoUtils.reqEncrypt(JSON.stringify(requestModel));
            } catch (err) {
                console.log("Error in encryption!!", err);
            }
        }else{
            requestModel=this.getJSONfromModel(requestModel);
        }
        return this.http
            .patch(apiUrl, requestModel, { headers: this.headers, observe: 'response' })
            .pipe(catchError((error) => {
                let errorReason = error.error.reason;
                if (errorReason == "ACCESS_TOKEN_EXPIRED") {
                    window.location.href = "/logout";
                    return;
                }
                return throwError(error);
            }));
    }

    makeUploadCall(apiEndPoint: string, requestModel: any) {
        this.getHeadersForUpload();
        const apiUrl = this.BASE_URL + apiEndPoint;
        return this.http
            .post(apiUrl, requestModel, { headers: this.headersUpload, observe: 'response' })
            .pipe(catchError((error) => {
                let errorReason = error.error.reason;
                if (errorReason == "ACCESS_TOKEN_EXPIRED") {
                    window.location.href = "/logout";
                    return;
                }
                return throwError(error);
            }));
    }

    makeGetCall(apiEndPoint: string, params?: URLSearchParams) {
        if (apiEndPoint.includes("cartTotalVersusUploadedDocuments")) {
            this.getHeaders("studentapp");
        } else {
            this.getHeaders();
        }
        var apiUrl;
        
        if (apiEndPoint.includes('wsfx/redirectionData')) {
            apiUrl = this.BASE_URL_GRID + apiEndPoint;
        } else {
            apiUrl = this.BASE_URL + apiEndPoint;
        }
        return this.http
            .get(apiUrl, { headers: this.headers, observe: 'response' })
            .pipe(catchError((error) => {
                let errorReason = error.error.reason;
                if (errorReason == "ACCESS_TOKEN_EXPIRED") {
                    window.location.href = "/logout";
                    return;
                }
                return throwError(error);
            }));
    }

    makeGetCallGrid(apiEndPoint: string, params?: URLSearchParams) {
        this.getHeaders();
        const apiUrl = this.BASE_URL_GRID + apiEndPoint;
        return this.http
            .get(apiUrl, { headers: this.headers, observe: 'response' })
            .pipe(catchError((error) => {
                let errorReason = error.error.reason;
                if (errorReason == "ACCESS_TOKEN_EXPIRED") {
                    window.location.href = "/logout";
                    return;
                }
                return throwError(error);
            }));
    }

    makeDeleteCall(apiEndPoint: string) {
        return this.http
            .delete(this.BASE_URL + apiEndPoint, { headers: this.headers, observe: 'response' })
            .pipe(catchError((error) => {
                let errorReason = error.error.reason;
                if (errorReason == "ACCESS_TOKEN_EXPIRED") {
                    window.location.href = "/logout";
                    return;
                }
                return throwError(error);
            }));
    }
}