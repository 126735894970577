<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class=" col-9 col-md-6 mb-20">
                        <div class="d-none d-lg-block">
                        <h6 class=" float-left mb-0 ml-10 fs-18 fw-700" style="color: #1D2A62">International eSIM</h6>
                    </div>
                        <div class="col-12 col-md-6 ">
                            <div class="esim-img">
                                <img src="assets/images/esim/esim-card.png" class="esim-img" >
                        </div>
                        <div class="esim-text">
                            Load Your Student Pay Card with $3,000 or more to become eligible for an International eSIM.
                        </div>
                        <div class="esim-text1">
                            <label>
                              <input type="checkbox" [(ngModel)]="isChecked" /> 
                              I agree to the terms and conditions for claiming the free eSIM offer with WSFX, in partnership with Zetexa. I understand that I need to provide the required details for activation, and this offer is non-transferable and non-refundable. I also consent to my information being used for verification and activation
                            </label>
                          </div>
                          
                        <div class="col-md-12 col-12 mt-20">
                            <button type="submit" rippleEffect class="btn-esim fs-10 w-80" (click)="claimNow()" >
                                Claim Now
                            </button>
                        </div>
                        </div>
                    </div>
                    
                    <div class="esim-border dash-esim">
                        <div class="col-md-12">
                            <ul ngbNav #nav="ngbNav" class="nav nav-pills nav-justified justify-content-center nav-fill mb-0">
                                <li class="nav-item" ngbNavItem>
                                    <a ngbNavLink class="esim-link fs-12" href="" data-toggle="tab" (click)="setActiveTab(0)"
                                        style="border: 1.2px solid #1D2A62; padding: 15px; border-radius: 0;">
                                        Features
                                    </a>
                                    <div *ngIf="activeTab === 0" class="row mt-6 esim-row">
                                        <div class="col-12 col-sm-8 col-md-6 mb-4" *ngFor="let singleItem of menuItems">
                                            <div class="d-flex align-items-center" style="margin-bottom:-50px">
                                                <img src="assets/images/esim/{{singleItem.icon}}" class="mt-2" style="width: 40px; height: 40px;">
                                                <div class="fs-11 fw-600 text-dark pt-2" style="text-align: left;">
                                                    {{ singleItem.title }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="nav-item" ngbNavItem>
                                    <a ngbNavLink class="esim-link fs-12" href="" (click)="setActiveTab(1)"
                                        style="width: 111%; border: 1.2px solid #1D2A62; padding: 15px; margin-left: 0px; border-radius: 0;">
                                        Specifications
                                    </a>
                                    <div *ngIf="activeTab === 1" class="row mt-6 esim-specifications-row">
                                        <div class="col-12 col-sm-8 col-md-6 mb-4" *ngFor="let singleItem of esimItems">
                                            <div class="d-flex align-items-center" style="height: 100%; margin-bottom:-70px">
                                                <img src="assets/images/esim/{{singleItem.icon}}" class="mt-3" style="width: 40px; height: 40px;">
                                                <div class="fs-11 fw-600 text-dark pt-4 pl-2" style="text-align: left;">
                                                    {{ singleItem.title }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-6 text-left ">
                        <button  class="btn btn-esim1" (click)="goToNewFeatures()">
                            Back
                        </button>
                        </div>
                    
                     

                </div>
            </div>
        </div>
    </div>
</div>
                   
                