import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { DateUtils } from "app/shared/utility/date-utils";
import moment from "moment";
import { ToastrService } from "ngx-toastr";
import { CardService } from "../../services/card.service";
import { CryptoUtils } from "app/shared/utility/crypto.utils";
import Swal from 'sweetalert2';
import { MasterDataService } from "app/shared/services/master-data.service";
import { Constants } from "app/shared/constant/constants";
import { ReqGetCurrencyMaster } from "app/shared/models/shared-models";
import { ConstantsKey } from "app/shared/constant/constants-keys";

@Component({
  selector: 'statement',
  templateUrl: './statement.component.html',
  encapsulation: ViewEncapsulation.None
})
export class StatementComponent implements OnInit {

  public maxDate: any;
  public statementList: any;
  public showLoader: any;
  public statementPdfUrl: any;
  public minDate: any;
  public startDate: any;
  public endDate: any;
  public enableEndDatePicker : boolean = false;
  public noDataFound : boolean = true;
  public startingDate : any;
  public valuesSelected :  boolean = false;
  public minDateForEndDate: any;
  public cryptoUtils: CryptoUtils = new CryptoUtils();
  public startDateForFilter: any;
  public endDateForFilter: any;
  public selectedFromDate : any;
  public cartType: any = "STUDENT"
  public reqGetCurrencyMaster: ReqGetCurrencyMaster = new ReqGetCurrencyMaster();
  public currencyItems: any;
  public currencyCode: any = 'All Currencies';
  public currentCard: any;
  public kitId;
  public cardType = 'smartCurrencyCard';
  public isFilterForStudent:boolean = false;


  constructor(
    private cardService: CardService,
    public toast: ToastrService,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    public router:Router,
    public masterDataService: MasterDataService,
    ) { 
      this.maxDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
  }

  ngOnInit() {
    var startDate = new Date();
    let startYear = startDate.getFullYear();
    let startMonth = startDate.getMonth() + 1;
    let startDay = 1;
    // this.startDate = { year: startYear,month: startMonth,day: startDay};
    // console.log(this.startDate);
    // this.endDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
    
    this.currentCard = JSON.parse(sessionStorage.getItem(ConstantsKey.STUDENT_CARD_DATA));
    this.cardType = this.currentCard.cardType;
    
    if(this.currentCard.kitId) {
      this.kitId = this.currentCard.kitId;
    }

    if(this.cardType == 'smartCurrencyCard') {
      this.startDate = { year: startYear,month: startMonth,day: startDay};
      console.log(this.startDate);
      this.endDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
      this.fetchStatementList(false);
    } else if(this.cardType == 'studentPayCard') {
      this.fetchStudentStatment(false);
    }
    console.log(this.currentCard, this.kitId, this.currentCard, this.cardType);
    this.getCurrencies();


    
  }

  onDateSelect(event, sourceDate) {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;;
    let day = event.day <= 9 ? '0' + event.day : event.day;;
    if(sourceDate == 'startDate'){
      this.startDateForFilter = year + "-" + month + "-" + day;
      this.selectedFromDate = this.startDateForFilter;
      this.enableEndDatePicker = true;
      let minDateForEndDate  = this.selectedFromDate;
      this.minDateForEndDate = { year: parseInt(minDateForEndDate.split("-")[0]),month: parseInt(minDateForEndDate.split("-")[1]), day: parseInt(minDateForEndDate.split("-")[2])};
 
    } else if(sourceDate == 'endDate'){
      this.endDateForFilter = year + "-" + month + "-" + day;
    }
  }
  
  getCurrencies() {
    this.reqGetCurrencyMaster.cartType = this.cartType;
    this.reqGetCurrencyMaster.countryCode = "US";
    this.reqGetCurrencyMaster.issuer = "";
    this.reqGetCurrencyMaster.partnerId = Constants.partnerId;
    this.reqGetCurrencyMaster.product = Constants.productsEnum[0];
    this.masterDataService.getCurrenciesList(this.reqGetCurrencyMaster).subscribe(result => {
      let currencies:any = result.body;
      currencies.unshift({"currencyCode": 'All Currencies'})
      if(this.cardType == 'studentPayCard') {
        this.currencyItems = currencies.filter(obj => obj.currencyCode == 'USD');
        this.currencyCode = 'USD';
      } else {
        this.currencyItems = currencies;
        // this.currencyItems = this.allCurrencies;
      }

       
    });
  }

  fetchStatementList(sendEmail) {
    this.showLoader = true;
    this.startDateForFilter = this.startDate.year + "-" + this.startDate.month + "-" + this.startDate.day;
    this.endDateForFilter =  this.endDate.year + "-" + this.endDate.month + "-" + this.endDate.day;
    this.cardService.fetchStatement(this.startDateForFilter, this.endDateForFilter, sendEmail, this.currencyCode).subscribe((resp => {
      // this.showLoader = false;
      // this.noDataFound = false;
      // this.statementList = resp.body['paxStatementSnippets'];
      // this.statementPdfUrl = resp.body['statementPdfUrl'];

      // if(this.statementList.length == 0){
      //   this.noDataFound = true;
      // }
    }), error => {
       this.showLoader = false;
       this.noDataFound = false;


      if (error.status == 200) {
              var resp = JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));
            this.statementList = resp['paxStatementSnippets'];
            this.statementPdfUrl = resp['statementPdfUrl'];

            if(this.statementList.length == 0){
              this.noDataFound = true;
            } else {
              this.noDataFound = false;
            }

            if(sendEmail) {
              Swal.fire({
                title: 'Card statement sent on E-mail successfully',
                imageUrl: 'assets/images/icons/statement-mail.png',
                imageWidth: 50,
                imageHeight: 50,
                animation: false,
                customClass: {
                  icon: 'no-border',
                  confirmButton: 'btn btn-primary btn-sm rounded-pill'
                }
              });              
            }
 
      } else {
            this.toast.error(error.error.message);

      }
    });
  }
  downloadStatement() {
    if (!this.noDataFound) {
      window.location.href = this.statementPdfUrl;
      Swal.fire({
        title: 'Card statement downloaded successfully',
        imageUrl: 'assets/images/icons/statement-download.png',
        imageWidth: 50,
        imageHeight: 50,
        animation: false,
        customClass: {
          icon: 'no-border',
          confirmButton: 'btn btn-primary btn-sm rounded-pill'
        }
      });

    } else {
            this.toast.error('No statement found');
    }
  }

  goToRaiseDispute(singleStatementDetails){
    this.router.navigate(['/raise-dispute-ticket'],{  queryParams: singleStatementDetails });
  }

  showErrorMessage(){
    this.toast.error('Please select from date first');
  }

  sendEmail() {
    if (!this.noDataFound) { 
      if(this.cardType == 'smartCurrencyCard') {
        this.fetchStatementList(true);
      } else if(this.cardType == 'studentPayCard') {
        this.fetchStudentStatment(true);
      }
    } else {
      this.toast.error('No statement found');
    }
  }

  fetchStudentStatment(sendEmail) {
    if(this.isFilterForStudent) {
      this.startDateForFilter = this.startDate.year + "-" + this.startDate.month + "-" + this.startDate.day;
      this.endDateForFilter =  this.endDate.year + "-" + this.endDate.month + "-" + this.endDate.day;
    }
    this.cardService.studentFetchStatement(this.startDateForFilter, this.endDateForFilter, sendEmail,this.kitId, this.currencyCode, this.isFilterForStudent).subscribe(response => {

      var resp = response.body;
      this.statementList = resp['paxStatementSnippets'];
      this.statementPdfUrl = resp['statementPdfUrl'];

      if(this.statementList.length == 0){
        this.noDataFound = true;
      } else {
        this.noDataFound = false;
      }

      if(sendEmail) {
        Swal.fire({
          title: 'Card statement sent on E-mail successfully',
          imageUrl: 'assets/images/icons/statement-mail.png',
          imageWidth: 50,
          imageHeight: 50,
          animation: false,
          customClass: {
            icon: 'no-border',
            confirmButton: 'btn btn-primary btn-sm rounded-pill'
          }
        });              
      }
      
    }, err => {
      this.toast.error(err.error.message);      
    });
  }

  fetchNewList() {
    if(this.startDate && this.startDate != "") {
      if(this.endDate && this.endDate  != "") {
        this.isFilterForStudent = true;
        if(this.cardType == 'smartCurrencyCard') {
          this.fetchStatementList(false);
        } else if(this.cardType == 'studentPayCard') {
          this.fetchStudentStatment(false);
        }          
      } else {
        this.toast.error("Please select To date");        
      }
    } else {
      this.toast.error("Please select From date");
    }
  }

}

