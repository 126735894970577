<style>
    .input-group-text {
        border: 1px solid #d6d6d6;
        height: 39px;
    }
</style>

<loader *ngIf="showLoader"></loader>
<div class="content-wrapper  container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <div class="row mb-20">
                    <div class="col-md-10">
                        <h6 class="content-header-title float-left mb-0" style="color: black">Reset Password</h6>
                    </div>
                </div>
                <div class="row" *ngIf="passwordAlreadySet">
                    <div class="col-md-12">
                        <form [formGroup]="changePasswordForm">

                            <div class="row">
                                <div class="col-md-6 col-12">
                                    <label class="form-label">Old Password *</label>
                                    <div class="input-group form-password-toggle" [ngClass]="{'mb-2': isForgotPassword}">
                                        <input [type]="showOldPassword ? 'text' : 'password'" class="form-control fs-12" [(ngModel)]="passwordChangeModel.oldPassword" placeholder="Enter old password *" formControlName="oldPassword" />
                                        <div class="input-group-append" (click)="showOldPassword = !showOldPassword">
                                            <span class="input-group-text cursor-pointer"><i class="icomoon"
                                                [ngClass]="{'icon-eye-solid': showOldPassword, 'icon-eye-slash-solid': !showOldPassword }"></i></span>
                                        </div>
                                    </div>
                                    <div class="text-success fs-10 mt-5 mb-10 text-right" role="button" (click)="forgotPassord()">
                                        Forgot Password ?
                                    </div>
                                </div>

                                <div class="col-md-6 col-12">
                                    <label class="form-label">New Password *</label>
                                    <div class="input-group form-password-toggle">
                                        <input [type]="showNewPassword ? 'text' : 'password'" class="form-control fs-12" [(ngModel)]="passwordChangeModel.newPassword" placeholder="Enter new password *" formControlName="newPassword" />
                                        <div class="input-group-append" (click)="showNewPassword = !showNewPassword">
                                            <span class="input-group-text cursor-pointer">
                                            <i class="icomoon"
                                                [ngClass]="{ 'icon-eye-solid': showNewPassword, 'icon-eye-slash-solid': !showNewPassword }"></i>
                                        </span>
                                        </div>
                                        <div class="text-danger fs-10 mb-10" *ngIf="!changePasswordForm.get('newPassword').valid && changePasswordForm.get('newPassword').touched">
                                            Password must contain more than 8 characters, 1 upper case letter, and 1 special character
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12 col-12">
                                    <div class=" fs-10 note-bg">
                                        The password should consist of at least 8 characters, including alphabets, numbers, one uppercase letter, one lowercase letter, and one special character, in order to be accepted.
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12 text-right col-12 mt-10 mb-10">
                                    <button type="submit" (click)="passwordChange()" rippleEffect class="btn btn-primary  mt-12 fs-12">
                                    Update & Save
                                </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="row" *ngIf="!passwordAlreadySet && !isForgotPassword">
                    <div class="col-md-12">
                        <form [formGroup]="setPasswordForm">
                            <div class="row">
                                <div class="col-md-6 col-12">
                                    <label class="form-label">New Password *</label>
                                    <div class="input-group form-password-toggle mb-2">
                                        <input [type]="showOldPassword ? 'text' : 'password'" class="form-control fs-12" [(ngModel)]="passwordSetModel.consumerPassword" placeholder="Enter new password *" formControlName="password" />
                                        <div class="input-group-append" (click)="showOldPassword = !showOldPassword">
                                            <span class="input-group-text cursor-pointer"><i class="feather"
                                                [ngClass]="{'icon-eye-solid': showOldPassword, 'icon-eye-slash-solid': !showOldPassword }"></i></span>
                                        </div>
                                    </div>
                                    <div class="text-danger mb-10" *ngIf="!setPasswordForm.get('password').valid && setPasswordForm.get('password').touched">
                                        Password must contain more than 8 characters, 1 upper case letter, and 1 special character
                                    </div>
                                </div>

                                <div class="col-md-6 col-12">
                                    <label class="form-label">Confirm Password *</label>
                                    <div class="input-group form-password-toggle mb-2">
                                        <input [type]="showNewPassword ? 'text' : 'password'" class="form-control fs-12" [(ngModel)]="passwordSetModel.confirmConsumerPassword" placeholder="Enter new password *" formControlName="confirmPassword" />
                                        <div class="input-group-append" (click)="showNewPassword = !showNewPassword">
                                            <span class="input-group-text cursor-pointer">
                                            <i class="icomoon"
                                                [ngClass]="{ 'icon-eye-solid': showNewPassword, 'icon-eye-slash-solid': !showNewPassword }"></i>
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12 col-12">
                                    <div class=" fs-10 note-bg">
                                        Password should consists of at least 8 characters including alphabets, numbers, one uppercase, one lowercase and one special character to be accepted.
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12 text-right col-12 mt-10 mb-10">
                                    <button type="submit" (click)="setNewPassword()" rippleEffect class="btn btn-primary  mt-12 fs-12">
                                    Submit
                                </button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>





                














                <div class="row" *ngIf="isForgotPassword">
                    <div class="col-md-12">
                        <form [formGroup]="forgotPasswordForm">

                            <div class="row" *ngIf="isForgotPassword">
                                <div class="col-md-6 col-12">
                                    <label class="form-label">Mobile No *</label>
                                    <div class="input-group form-password-toggle">
                                        <input type="number" class="form-control fs-12" placeholder="Mobile No *" name="mobileNo" maxlength="10" (keypress)="numberOnly($event)" formControlName="mobileNo"  />
                                    </div>
                                    <span *ngIf="(forgotPasswordFormSubmit || sendOTPClick) && forgotPassFormControl.mobileNo.errors" class="invalid-form">
                                        <small class="form-text text-danger" *ngIf="forgotPassFormControl.mobileNo.errors.required">Please enter phone number!</small>
                                        <small class="form-text text-danger" *ngIf="forgotPassFormControl.mobileNo.errors.pattern">Please enter 10 digit phone number!</small>
                                    </span>
                                    <a class="resendOtp text-right ng-star-inserted float-right fs-9" *ngIf="!showTimer && sendOTPButtonVisible" (click)="forgotPassSendOTP()">Send OTP</a>
            
                                    <div *ngIf="showTimer" class="text-right float-right fs-9">{{displayTimer}}</div>
                                    <a class="resendOtp text-right ng-star-inserted float-right fs-9" *ngIf="resendOTPBtnActive" (click)="forgotPassSendOTP()"> Resend OTP</a>                                    
                                </div>

                                <div class="col-md-6 col-12">
                                    <label class="form-label">OTP *</label>
                                    <div class="input-group form-password-toggle">
                                        <input type="text" class="form-control fs-12" placeholder="Enter OTP *" name="forgotPassOTP" formControlName="forgotPassOTP" />
                                    </div>
                                    <span *ngIf="forgotPasswordFormSubmit && forgotPassFormControl.forgotPassOTP.errors" class="invalid-form">
                                        <small class="form-text text-danger" *ngIf="forgotPassFormControl.forgotPassOTP.errors.required">Please enter OTP!</small>
                                        <small class="form-text text-danger" *ngIf="forgotPassFormControl.forgotPassOTP.errors.pattern">Please enter 6 digit phone number!</small>
                                    </span>
                                </div>
                            </div>



                            <div class="row">
                                <div class="col-md-6 col-12">
                                    <label class="form-label">New Password *</label>
                                    <div class="input-group form-password-toggle">
                                        <input [type]="showOldPassword ? 'text' : 'password'" class="form-control fs-12" placeholder="Enter new password *" formControlName="password" />
                                        <div class="input-group-append" (click)="showOldPassword = !showOldPassword">
                                            <span class="input-group-text cursor-pointer"><i class="feather" [ngClass]="{'icon-eye-solid': showOldPassword, 'icon-eye-slash-solid': !showOldPassword }"></i></span>
                                        </div>  
                                    </div>
                                    <span *ngIf="forgotPasswordFormSubmit && forgotPassFormControl.password.errors" class="invalid-form">
                                        <small class="form-text text-danger" *ngIf="forgotPassFormControl.password.errors.required">Please enter password!</small>
                                        <small class="form-text text-danger" *ngIf="forgotPassFormControl.password.errors.pattern">Password should consists of at least 8 characters including alphabets, numbers, one uppercase, one lowercase and one special character to be accepted.</small>
                                    </span>
                                </div>

                                <div class="col-md-6 col-12">
                                    <label class="form-label">Confirm Password *</label>
                                    <div class="input-group form-password-toggle">
                                        <input [type]="showNewPassword ? 'text' : 'password'" class="form-control fs-12" placeholder="Enter new password *" formControlName="confirmPassword" />
                                        <div class="input-group-append" (click)="showNewPassword = !showNewPassword">
                                            <span class="input-group-text cursor-pointer">
                                            <i class="icomoon"
                                                [ngClass]="{ 'icon-eye-solid': showNewPassword, 'icon-eye-slash-solid': !showNewPassword }"></i>
                                        </span>
                                        </div>
                                    </div>
                                    <span *ngIf="forgotPasswordFormSubmit && forgotPassFormControl.confirmPassword.errors" class="invalid-form">
                                        <small class="form-text text-danger" *ngIf="forgotPassFormControl.confirmPassword.errors.required">Please enter Confirm password!</small>
                                    </span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12 col-12 mt-10">
                                    <div class=" fs-10 note-bg">
                                        Password should consists of at least 8 characters including alphabets, numbers, one uppercase, one lowercase and one special character to be accepted.
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12 text-right col-12 mt-10 mb-10">
                                    <button type="submit" (click)="setNewPassByOTP()" rippleEffect class="btn btn-primary  mt-12 fs-12">
                                    Submit
                                </button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>




            </div>
        </div>
    </div>
</div>