<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6 mb-20">
                        <h6 class=" float-left mb-0 ml-10 fs-18 fw-700" style="color: #1D2A62">International eSIM</h6>
                    </div>
                </div>
                <div class="col-md-12 mb-20 text-start" style="color: black;">
                    <p><b>You're just one step closer to getting your eSIM!</b></p>  
                </div>
                <form #form="ngForm" (ngSubmit)="onSubmit(form)">
                    <div class="row">
                        
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 form-group">
                            <label class="form-label text-black fs-11">First Name*</label>
                            <input type="text" name="name" class="form-control fs-12" placeholder="First Name*" [(ngModel)]="firstName" required (keypress)="letterOnly($event)">
                            <span *ngIf="form.submitted && !form.controls['name']?.valid" class="invalid-form">
                                <small class="form-text text-danger">This field is required!</small>
                            </span>
                        </div>
                
                      
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 form-group">
                            <label class="form-label text-black fs-11">Last Name*</label>
                            <input type="text" name="name" class="form-control fs-12" placeholder="Last Name*" [(ngModel)]="lastName" required (keypress)="letterOnly($event)">
                            <span *ngIf="form.submitted && !form.controls['name']?.valid" class="invalid-form">
                                <small class="form-text text-danger">This field is required!</small>
                            </span>
                        </div>
                       
                
                  
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 form-group">
                            <label class="form-label text-black fs-11">Email ID*</label>
                            <input type="email" name="email" class="form-control fs-12" placeholder="Enter Email ID*" [(ngModel)]="email" required>
                            <span *ngIf="form.submitted && !form.controls['email']?.valid" class="invalid-form">
                                <small class="form-text text-danger">This field is required!</small>
                            </span>
                        </div>
                
                      
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 form-group">
                            <label class="form-label text-black fs-11">Mobile No*</label>
                            <input type="text" name="mobile_no" class="form-control fs-12" placeholder="Enter Mobile No*" [(ngModel)]="mobileNo" required maxlength="10" pattern="^[1-9][0-9]*$" oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';">
                            <span *ngIf="form.submitted && !form.controls['mobile_no']?.valid" class="invalid-form">
                                <small class="form-text text-danger">This field is required!</small>
                            </span>
                        </div>
                    </div>
                
                    <div class="row">
                   
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                            <label class="form-label text-black fs-11">Address*</label>
                            <input type="text" name="address" class="form-control fs-12" placeholder="Enter Address*" [(ngModel)]="address" required>
                            <span *ngIf="form.submitted && !form.controls['address']?.valid" class="invalid-form">
                                <small class="form-text text-danger">This field is required!</small>
                            </span>
                        </div>
                
                    
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 form-group">
                            <label class="form-label text-black fs-11">State</label>
                            <input type="text" name="state" class="form-control fs-12" placeholder="State" [(ngModel)]="state" required>
                            <span *ngIf="form.submitted && !form.controls['state']?.valid" class="invalid-form">
                                <small class="form-text text-danger">This field is required!</small>
                            </span>
                        </div>
                
                     
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 form-group">
                            <label class="form-label text-black fs-11">City</label>
                            <input type="text" name="city" class="form-control fs-12" placeholder="City" [(ngModel)]="city" required>
                            <span *ngIf="form.submitted && !form.controls['city']?.valid" class="invalid-form">
                                <small class="form-text text-danger">This field is required!</small>
                            </span>
                        </div>
                    </div>
                
                    <!-- <div class="row">
                     
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 form-group">
                            <label class="form-label text-black fs-11">Type of SIM</label>
                            <select name="sim_type" class="form-control fs-12" [(ngModel)]="simType" required>
                                <option value="" disabled selected>Select</option>
                                <option value="physical">Physical SIM</option>
                                <option value="esim">eSIM</option>
                            </select>
                            <span *ngIf="form.submitted && !form.controls['sim_type']?.valid" class="invalid-form">
                                <small class="form-text text-danger">This field is required!</small>
                            </span>
                        </div>
                        
                
                     
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 form-group">
                            <label class="form-label text-black fs-11">Air Ticket</label>
                            <input type="text" name="air_ticket" class="form-control fs-12" placeholder="" [(ngModel)]="airTicket" required>
                            <span *ngIf="form.submitted && !form.controls['air_ticket']?.valid" class="invalid-form">
                                <small class="form-text text-danger">This field is required!</small>
                            </span>
                        </div>
                
                        
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 form-group">
                            <label class="form-label text-black fs-11">Visa</label>
                            <input type="text" name="visa" class="form-control fs-12" placeholder="" [(ngModel)]="visa" required>
                            <span *ngIf="form.submitted && !form.controls['visa']?.valid" class="invalid-form">
                                <small class="form-text text-danger">This field is required!</small>
                            </span>
                        </div>
                
                       
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 form-group">
                            <label class="form-label text-black fs-11">Pan Card</label>
                            <input type="text" name="pan_card" class="form-control fs-12" placeholder="" [(ngModel)]="panCard" required>
                            <span *ngIf="form.submitted && !form.controls['pan_card']?.valid" class="invalid-form">
                                <small class="form-text text-danger">This field is required!</small>
                            </span>
                        </div>
                    </div> -->
                
                    <div class="row mt-20">
                        <div class="col-md-12 col-9 text-right">
                         
                            <button type="submit" rippleEffect class="btn btn-primary fs-12" >
                                Submit
                              </button>
                        </div>
                    </div>
                   
                </form>
                
            </div>
        </div>
    </div>  
   
   
    
    
      <ng-template #eSIMCardModal let-modal>
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <div class="success-img" *ngIf="!isError" style="width: 100%; text-align: center;">
                <img src="assets/images/esim/success.png" class="success-img" >
            </div>
            <p class="link-text" *ngIf="!isError">Hurray</p>
            <p class="link1-text" *ngIf="!isError">
                Your eSIM order has been successfully placed. Activation instructions will be sent to your registered email from <span style="text-decoration: underline;">Orders@zetexa.com</span>
            </p>
            <div class="link1-text mt-20" *ngIf="isError">{{ errorMsg }}</div>
            <div class="col-md-12 mt-20">
                <button type="button" rippleEffect class="btn-linkcard1 fs-10 w-80" (click)="closeModalAndNavigate()">Ok</button>
            </div>
        </div>
      </ng-template>
      
      
    