import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { PasswordChangeModel, PasswordSetModel } from '../models/security-setting.model';
import * as shajs from 'sha.js';
import { UpdateProfileService } from 'app/modules/update-profile/services/update-profile.service';
import { ToastrService } from 'ngx-toastr';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SmartFxLandingService } from 'app/modules/smartfx-landing/services/smartffx-landing-services';
import { PasswordResetRequest } from './../../../modules/smartfx-landing/models/smartFxLandingModels';

@Component({
  selector: 'security-setting',
  templateUrl: './security-setting.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [SmartFxLandingService]
})
export class SecuritySettingComponent implements OnInit {

  public showOldPassword: boolean = false;
  public showNewPassword: boolean = false;
  public showLoader: boolean = false;
  public passwordChangeModel: PasswordChangeModel = new PasswordChangeModel();
  public passwordSetModel: PasswordSetModel = new PasswordSetModel();

  public consumerDetails: any;
  public passwordAlreadySet: any;
  passwordNotEqual: boolean;
  public isForgotPassword: boolean = false;
  public mobileNo;
  public forgotPassOTP;
  public forgotPasswordForm: FormGroup;
  public forgotPasswordFormSubmit: boolean = false;  
  public displayTimer: any;
  public showTimer: boolean = false;
  public showResendButton: boolean = false;
  public sendOTPButtonVisible: boolean = true;
  public intervalTimer;
  public resendOTPBtnActive: boolean = false;
  public sendOTPClick: boolean = false;


  constructor(
    private updateProfileService: UpdateProfileService,
    public toast: ToastrService, private fb: FormBuilder,
    public _router: Router,
        public smartFxLandingService: SmartFxLandingService,
  ) { }

  ngOnInit() {
    var consumerDataResp = localStorage.getItem(ConstantsKey.OTP_LOGIN_RESPONSE);
    this.consumerDetails = JSON.parse(consumerDataResp);

    if (this.consumerDetails?.passwordSet) {
      this.passwordAlreadySet = true;
    } else {
      this.passwordAlreadySet = false;
    }


    this.forgotPasswordForm = this.fb.group({
      mobileNo: new FormControl('',[Validators.required, Validators.pattern(/^\d{10}$/) ]),
      forgotPassOTP: new FormControl('',[Validators.required, Validators.pattern(/^\d{6}$/) ]),
      password: new FormControl('',[Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)]),
      confirmPassword: new FormControl('',[Validators.required]),
    });


  }


  setPasswordForm = this.fb.group({
    password: [
      null,
      [
        Validators.required,
        Validators.pattern(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
        ),
        Validators.minLength(8),
      ],
    ],
    confirmPassword: [Validators.required]
  });

  changePasswordForm = this.fb.group({
    oldPassword: [Validators.required],
    newPassword: [
      null,
      [
        Validators.required,
        Validators.pattern(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
        ),
        Validators.minLength(8),
      ],
    ],
  });

  passwordChange() {
    if (this.changePasswordForm.valid){
      if (this.updatePasswordValidation()) {
        this.showLoader = true;
        var passwordChangeModelLocal: PasswordChangeModel = new PasswordChangeModel();
        // passwordChangeModelLocal.oldPassword = shajs('sha256').update(this.passwordChangeModel.oldPassword).digest('hex') + '@1';
        // passwordChangeModelLocal.newPassword = shajs('sha256').update(this.passwordChangeModel.newPassword).digest('hex') + '@1';
    
        passwordChangeModelLocal.oldPassword = this.passwordChangeModel.oldPassword;
        passwordChangeModelLocal.newPassword = this.passwordChangeModel.newPassword;
        this.updateProfileService.passwordChange(passwordChangeModelLocal).subscribe((respData) => {
          this.showLoader = false;
          this.toast.success('Yay! Your password has been updated successfully!');
          this._router.navigate(['/login']);
        }, error => {

              this.showLoader = false;
              if(error.status==200||error.status==201){
                this.toast.success('Yay! Your password has been updated successfully!');
                this._router.navigate(['/login']);
              }else{
                this.toast.error(error.error.message);
              }
        });
        }
    }
  }

  updatePasswordValidation() {
    if (!this.validatePasswordPatter(this.passwordChangeModel.newPassword)) {
      this.toast.error("Please try again with another combination!");
      return false;
    }

    if (!this.passwordChangeModel.oldPassword) {
      this.toast.error("Please enter old password!");
      return false;
    }
    return true;
  }

  setNewPassword() {
    if (this.setPasswordValidation()) {
    this.showLoader = true;
    var passwordSetRequestModelLocal: PasswordSetModel = new PasswordSetModel();
   // passwordSetRequestModelLocal.consumerPassword = shajs('sha256').update(this.passwordSetModel.consumerPassword).digest('hex') + '@1';
    passwordSetRequestModelLocal.consumerPassword = this.passwordSetModel.consumerPassword;
    this.updateProfileService.passwordNewSet(passwordSetRequestModelLocal).subscribe((respData) => {
      this.showLoader = false;

      this.consumerDetails.passwordSet = true;
      localStorage.setItem(ConstantsKey.OTP_LOGIN_RESPONSE, JSON.stringify(this.consumerDetails));

      this.toast.success('Yay! Your password has been set successfully!');
    }, error => {
      this.showLoader = false;
      if (error.status == 200 || error.status == 201) {
                this.consumerDetails.passwordSet = true;
                localStorage.setItem(ConstantsKey.OTP_LOGIN_RESPONSE, JSON.stringify(this.consumerDetails));
                this.toast.success('Yay! Your password has been set successfully!');
          }else{
                this.toast.error(error.error.message);
          }
    });
    }
  }

  setPasswordValidation() {
    if (this.passwordSetModel.consumerPassword != this.passwordSetModel.confirmConsumerPassword) {
      this.toast.error("New password and confirm password does not match!!!");
      return false;
    }

    // if (!this.validatePasswordPatter(this.passwordSetModel.consumerPassword)) {
    //   this.toast.error("Please try again with another combination!");
    //   return false;
    // }

    return true;
  }

  validatePasswordPatter(passwordString) {
    var passwordPattern = /^[a-zA-Z0-9*@#$]{8,15}$/;
    if (passwordPattern.test(passwordString)) {
      return true;
    } else {
      return false;
    }
  }

  forgotPassord() {
    this.passwordAlreadySet = false;
    this.isForgotPassword = true;
  }

  numberOnly(e) {
    var charCode = e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57))
        return false;
    return true;
  } 
  
  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get('password').value !== c.get('confirmPassword').value) {
        return {invalid: true};
    }
  }

  setNewPassByOTP() {
    console.log("===================================== ", this.forgotPasswordForm);

    this.forgotPasswordFormSubmit = true;
    if(this.forgotPasswordForm.valid) {
      if(this.forgotPassFormControl.password.value === this.forgotPassFormControl.confirmPassword.value) {
        let passwordResetRequest = new PasswordResetRequest();
        passwordResetRequest.id = this.forgotPassFormControl.mobileNo.value;
        passwordResetRequest.otp = this.forgotPassFormControl.forgotPassOTP.value;
        passwordResetRequest.authType = "MOBILE";
        passwordResetRequest.newPassword = this.forgotPassFormControl.password.value;

        this.showLoader = true;
        this.smartFxLandingService.passwordResetPatch(passwordResetRequest).subscribe(result => {
          this.showLoader = false;
          this.toast.success("Yay! Your password has been successfully updated!");
          this.forgotPasswordForm.reset();
        }, error => {
          this.showLoader = false;
          this.toast.error(error.error.message);
        });
      } else {
        this.toast.error("New password and confirm password does not match!!!");
      }
    }
  }

  get forgotPassFormControl() {
    return this.forgotPasswordForm.controls;
  }


  timer(minute) {
    let seconds: number = minute * 30;
    let textSec: any = "0";
    let statSec: number = 30;

    const prefix = minute < 10 ? "0" : "";

    if(this.intervalTimer) {
      clearInterval(this.intervalTimer);
    }

    this.intervalTimer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 29;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.displayTimer = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        console.log("finished");
        this.showTimer = false;
        this.showResendButton = true; // Show Resend OTP button
        this.resendOTPBtnActive = true;
        clearInterval(this.intervalTimer);
      }
    }, 1000);
  }

  forgotPassSendOTP() {
    this.sendOTPClick = true;
    if(this.forgotPassFormControl.mobileNo.valid) {
        this.showLoader = true;
        let passwordResetRequest = new PasswordResetRequest();
        passwordResetRequest.id = this.forgotPassFormControl.mobileNo.value;
        this.smartFxLandingService.passwordReset(passwordResetRequest).subscribe(result => {
          this.showLoader = false;
          this.timer(1);
          this.showTimer = true;
          this.resendOTPBtnActive = false; 
          this.sendOTPButtonVisible = false; 
          this.toast.success('OTP has been sent successfully.');
        }, error => {
          this.showLoader = false;
          this.toast.error(error.error.message);
        });
    }
  }


}