import { Component, ElementRef, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core'
import { CustomerValidationRequestModel, LinkCardMobileRequestModel, LinkCardRequest02Model } from '../../models/card.model';
import { CardService } from '../../services/card.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UpdateProfileService } from 'app/modules/update-profile/services/update-profile.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateUtils } from 'app/shared/utility/date-utils';

@Component({
  selector: 'link-student-card',
  templateUrl: './link-esim-card.component.html',
  styleUrls: ['./link-esim-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LinkEsimCardComponent implements OnInit {

  public processType: string = "verification";
  public modalReference: any;
  public kitNumber = '';
  public dateOfBirth = '';
  public consumerNumber:string = '';
  public consumerEmail:string = '';
  public otpField1;
  public otpField2;
  public otpField3;
  public otpField4;
  public otpField5;
  public otpField6 ;
  public maskMobile;
  public maskEmail;
  public mobileOtp;
  public emailOtp;
  public otpSendMessage;
  public optNum1;
  public optNum2;
  public optNum3;
  public optNum4;
  public optNum5;
  public optNum6;
  public otpOption = "";
  public displayTimer: any;
  public showTimer: boolean = false;
  public showResendButton: boolean = false;
  public resendOTPBtnActive: boolean = false;  
  public intervalTimer;

  @ViewChild('verifiationCardLinkModal') verifiationCardLinkModal: TemplateRef<any>;

  constructor(public modalService: NgbModal, public cardService: CardService, public toast: ToastrService, private router: Router) { }

  ngOnInit() {
    
  }

 

  
 

  formateDateToRequired(dateObj) {
    var requiredDate = dateObj.year + "-" + this.pad(dateObj.month) + "-" + this.pad(dateObj.day);
    return requiredDate;
  }

  pad(n) { return n < 10 ? '0' + n : n }
  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  openModalForCardLink() {

    if(this.kitNumber && this.kitNumber != "") {
      if(this.dateOfBirth && this.dateOfBirth != "") {
        let dob = DateUtils.getNgbDateStructToDate(this.dateOfBirth);

        let requestObj = {
          "kit": this.kitNumber,
          "dob": dob
        }
        this.cardService.verifyKit(requestObj).subscribe(res => {
          console.log("VERIFCATION OF CARD KIT ", res);
          let resObj = res.body;
  
          if(resObj["kitAndDobValid"] == true) {
            this.maskEmail = resObj["email"];
            this.maskMobile = resObj["mobile"];
            this.processType = "VERIFICATION_OPTION";
            this.modalReference = this.modalService.open(this.verifiationCardLinkModal, { centered: true, size: 'sm' })
          } else if(resObj["kitAndDobValid"] == false) {
            this.toast.error(resObj["message"]);
          }
        }, err => {
            this.toast.error(err.error.message);
        });
      } else {
        this.toast.error("Please enter Date of Birth");
      }

    } else {
      this.toast.error("Please enter kit No");
    }

    // this.modalReference = this.modalService.open(this.verifiationCardLinkModal, { centered: true, size: 'sm' })

  }

  sendOtpForLinkCard() {

    if(this.otpOption && this.otpOption != '') {

      let requestObj = {
        "mobile": this.otpOption == 'mobile',
        "email": this.otpOption == 'email',
        "kit": this.kitNumber
      };

      console.log("SEND OTP LINK CARD ", requestObj);

      this.cardService.sendOtpForLinkCard(requestObj).subscribe(res => {
        this.toast.success(res.body.toString());      
      }, err => {

        if(err.status == 200) {
          this.processType = "VERIFY_OTP";
          console.log("VERIFY OTP : ", err);
          this.otpSendMessage = err.error.text;

          // this.showSend = false;
          this.showTimer = true;
          this.timer(0.5);
          this.toast.success(err.error.text);     
          // Swal.fire(" Success", "OTP has been sent successfully", "success");

        } else {
          this.toast.error(err.error.message);
        }



      });
    } else {
      this.toast.error("Please OTP verfication option");    
    }
  }

  verifyOtpForLinkCard() {
    
    if(this.optNum1 && this.optNum1.length == 1 && this.optNum2 && this.optNum2.length == 1 && this.optNum3 && this.optNum3.length == 1 && this.optNum4 && this.optNum4.length == 1  && this.optNum5 && this.optNum5.length == 1 && this.optNum6 && this.optNum6.length == 1 ) {
      let otpNumber = this.optNum1 + this.optNum2 + this.optNum3 + this.optNum4 + this.optNum5 + this.optNum6;

      console.log("OTP NUMBER ", otpNumber);
      let requestObj = {
        "verificationType":"MOBILE",
        "otp": otpNumber,
        "kit": this.kitNumber
      };
      this.cardService.verifyOtpForLinkCard(requestObj).subscribe(res => {
        

      }, err => {

        if(err.status == 200) {
          this.closeLinkModal();
          console.log("============================", err)
          this.toast.success(err.error.text);
          this.router.navigate(['/my-card']);        
        } else {
          this.toast.error(err.error.message);
        }


      });

    } else {
      this.toast.error("Please enter OTP");
    }


  }

  closeLinkModal() {
    this.modalReference.close();
  }

  /* gotoNextField(nextElement: HTMLElement) {
    console.log("NEXT ELEMENT == ", nextElement);
    nextElement.focus();
  }

  onInputChange(e, prev, next) {
    const target = e.target;
    const val = target.value;

    if (isNaN(val)) {
        target.value = "";
        return;
    }

    if (val != "") {  
        const next = target.nextElementSibling;
        if (next) {
            next.focus();
        }
    }
  }

  keyUpEvent(e, prev, next) {
    const target = e.target;
    const key = e.key.toLowerCase();
    if (key == "backspace" || key == "delete") {
      target.value = "";
      const prev = target.previousElementSibling;
      if (prev) {
          prev.focus();
      }
      // return;
  }

  } */


  otpInputChange(e, index, next) {

    let inputValue = e.target.value.trim();
    
    if (!/^\d$/.test(inputValue)) {
      e.target.value = "";
      return;
    }

    if (inputValue && index < 6) {
      next.focus();
    }

    if (index === 5 && inputValue) {
      next.focus();
    }

  }

  otpKeyDownChange(e, index, prev) {
    if (!e.target.value && e.key === "Backspace" && index > 1) {
      prev.focus();
    }
  }

  
  numberOnly(e) {
    var charCode = e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57))
        return false;
    return true;
  }  

  timer(minute) {
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 30;

    const prefix = minute < 10 ? "0" : "";

    if(this.intervalTimer) {
      clearInterval(this.intervalTimer);
    }

    this.intervalTimer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 29;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.displayTimer = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        console.log("finished");
        this.showTimer = false;
        this.showResendButton = true; // Show Resend OTP button
        clearInterval(this.intervalTimer);
      }
    }, 1000);
  }

}
