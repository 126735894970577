import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { DateUtils } from 'app/shared/utility/date-utils';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { ReqGetCurrencyMaster, SingleRateResItem } from 'app/shared/models/shared-models';
import { Constants } from 'app/shared/constant/constants';
import { NewDashboardPurposeList } from 'app/shared/constant/new-dashboard-menu';
import { MasterDataService } from 'app/shared/services/master-data.service';
import { QuotationCurrencyItem } from 'app/modules/dashboard/models/quotation.models';
import { UpdateProfileService } from 'app/modules/update-profile/services/update-profile.service';
import { CardService } from 'app/modules/card/services/card.service';
import { CardBlockModel, CartItemCreateRequestList, ManageCardUpdateModel, OTPRequestModel, QuotationCurrenciesRequestModel, QuotationRequestModel, RemittanceStep01Model, ReplaceCardModel, SetNewPinModel } from 'app/modules/card/models/card.model';
import { UpdateProfileModel } from 'app/modules/update-profile/models/update-profile.model';
import { OrderService } from 'app/modules/order/services/order.service';
import { CryptoUtils } from 'app/shared/utility/crypto.utils';

@Component({
  selector: 'buy-forex-landing',
  templateUrl: './buy-forex-landing.component.html',
  styleUrls: ['../../card/view/card.component.scss', './buy-forex-landing.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'ecommerce-application' }
})
export class BuyForexLandingComponent implements OnInit {

  public showLoader: boolean = false;
  public showLoaderForCardDetails: boolean = false;

  public consumerData: any;
  public showBuyCard: boolean = false;

  public quotationCurrencyItem: QuotationCurrencyItem = new QuotationCurrencyItem();
  public reqGetCurrencyMaster: ReqGetCurrencyMaster = new ReqGetCurrencyMaster();


  public selectedPurposeItem: any;
  public panName: string;
  public currencyItems: any;
  public vcipStatusDetails: any;
  public cryptoUtils: CryptoUtils = new CryptoUtils();
  public rateCalculatorResponse: any;
  public vcipMessage: any;
  public currencyAmount;
  public currencySelectedObj: any;
  public vcipAccepted: any;
  public currencySelected: any;
  public quotationResponse: any;
  public quotationSnapshotId: any;
  public consumerDetail: any;
  public tcsPaymentSource = 'OWN';
  public consumerPanSelf: string;
  public quotationCurrencyResponsesList = [];
  public cartCurrenciesList = new Array<QuotationCurrenciesRequestModel>();
  @ViewChild('errorModal') errorModal: TemplateRef<any>;

  public showButtonOnVCIP: boolean = false;
  public newPinShow: boolean = false;
  public confirmPinShow: boolean = false;
  public proceedWithoutVCIPCompletion = 'false';
  public showPricing: boolean = false;
  public showCommentInput: boolean = false;
  public showBuyForexForm: boolean = false;
  public activeBuyForexCard: boolean = false;

  public singleRateResItem: SingleRateResItem = new SingleRateResItem();
  public remittanceStep01: RemittanceStep01Model = new RemittanceStep01Model();
  public quotationRequest = new QuotationRequestModel();
  public quotationRequestModel: QuotationRequestModel;
  public isVcipComplete: boolean = false;
  public cardLinkedAlready: boolean = false;
  public isOrderWithoutVcipComplete: boolean = false;
  public isPANAvailable: boolean = false;

  @ViewChild('vcipCompleteOrNotModal') vcipCompleteOrNotModal: TemplateRef<any>;
  @ViewChild('vcipModalNewUser') vcipModalNewUser: TemplateRef<any>;
  @ViewChild('PANDetailModal') PANDetailModal: TemplateRef<any>;
  @ViewChild('AmountLimitModal') AmountLimitModal: TemplateRef<any>;

  public modalReference: any;
  public updateProfileModel = new UpdateProfileModel;
  public ssoResponseData: any;
  public orderListItems : any;
  public paxId: any;
  vkycTimingText: string;
  public ngbDatepicker: NgbDateStruct;
  showErrorModal: boolean;
  dobMaxDate: any;
  isPANCBChecked: boolean=false;
  panVerificationErrMsg: string;
  isVCIPNow: boolean = false;
  constructor(
    private cardService: CardService,
    private masterDataService: MasterDataService,
    private router: Router,
    public updateProfileService: UpdateProfileService,
    public toast: ToastrService,
    private orderService: OrderService, 
    public modalService: NgbModal
  ) {
    this.currencySelected = "USD";
    this.dobMaxDate = { year: new Date().getFullYear() - 13, month: new Date().getMonth() + 1, day: new Date().getDate() }

  }

  ngOnInit() {
    this.vkycTimingText= Constants.vkycTimingText;
    if (localStorage.getItem(ConstantsKey.SSO_RESP)) {
      this.ssoResponseData = JSON.parse(localStorage.getItem(ConstantsKey.SSO_RESP));
      this.currencyAmount = this.ssoResponseData['requiredCurrencyCodeAmount'];
      this.currencySelected = this.ssoResponseData['requiredCurrencyCode'];
      console.log('SSO resp');

      // this.getRateRange();
    }

    this.getCardDetails();
    this.getLocalData();
    //this.getRateRange();
    this.getCurrencies();
    this.convertAmount();
    this.getProfileDetails();
    this.selectedPurposeItem = NewDashboardPurposeList.getPurposeObjectByRemittanceType('STUDENT');
    console.log(this.selectedPurposeItem);
  }
  alphaNumberOnly (e) {  // Accept only alpha numerics, not special characters 
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
        return true;
    }
    e.preventDefault();
    return false;
  }
  getOrderList(){
    this.orderService.getListOrderShortSnippets().subscribe((resp=>{
      this.orderListItems = resp.body;
      if (this.orderListItems.length > 0) {
        this.isOrderWithoutVcipComplete=true;
        this.openModalForVCIP();
      }
    }),error=>{
    });
  }
  
  getLocalData() {
    var consumerDataLocal = localStorage.getItem(ConstantsKey.CONSUMER_DATA);
    this.consumerData = JSON.parse(consumerDataLocal);
    console.log("this.consumerData ", this.consumerData);
  }

  getCardDetails() {
    this.showLoaderForCardDetails = true;

    this.cardService.getCardDetails().subscribe((respCardData => {
      var responseBody = JSON.parse(JSON.stringify(respCardData.body));
      if (responseBody.length > 0) {
        this.router.navigate(['/my-card']);
        this.showLoaderForCardDetails = false;
      } else {
        this.router.navigate(['/buy-forex-landing']);
        this.showLoaderForCardDetails = false;
        this.showBuyCard = true;
      }
    }), error => {
      this.router.navigate(['/buy-forex-landing']);
      this.showLoaderForCardDetails = false;
      this.showBuyCard = true;

      // this.router.navigate(['/new-dashboard'])
      //   .then(() => {
      //     window.location.reload();
      //   });
      // this.toast.error(error.error.message);
    });;
  }

  getVCIPStatus() {
    this.showLoader = true;
    this.updateProfileService.getVCIPStaus().subscribe((resp => {
      this.showLoader = false;
      this.vcipStatusDetails = resp.body;
      if (this.vcipStatusDetails.vcipStatus == 'ACCEPTED') {
        this.isVcipComplete=true;
      }else {
        this.getOrderList();
      }
      localStorage.setItem(ConstantsKey.IS_VCIP_COMPLETE, this.isVcipComplete.toString());

    }), error => {
      this.showLoader = false;
    });
  }

  getRateRange() {
    this.masterDataService.getCurrentRateListCard().subscribe(resp => {
      this.singleRateResItem = JSON.parse(JSON.stringify(resp.body));
      this.currencyItems = this.singleRateResItem[0].rates;
      console.log('SINGLE RATE >> ', this.currencyItems);
      if (this.ssoResponseData) {
        if (this.currencyAmount) {
          this.callQuotation();
        }
      }
    });
  }

  getCurrencies() {
      this.reqGetCurrencyMaster.cartType = "CARD_PURCHASE"
   // this.reqGetCurrencyMaster.cartType = PurposeList.purpose[0].remittanceType;
    this.reqGetCurrencyMaster.countryCode = "US";
    this.reqGetCurrencyMaster.issuer = "";
    this.reqGetCurrencyMaster.partnerId = Constants.partnerId;
    this.reqGetCurrencyMaster.product = Constants.productsEnum[0];

    this.masterDataService.getCurrenciesList(this.reqGetCurrencyMaster).subscribe(result => {
      this.currencyItems = result.body;
       if (this.ssoResponseData) {
        if (this.currencyAmount) {
          this.callQuotation();
        }
      }
      this.removeCurrency('SEK'); 
      this.removeCurrency('DKK');
      this.removeCurrency('INR'); 
      this.removeCurrency('NOK');

    });
    
  }
   removeCurrency(doc){
   this.currencyItems.forEach( (item, index) => {
     if(item['currencyCode'] === doc) this.currencyItems.splice(index,1);
   });
}
  convertAmount() {
    if (this.currencyAmount) {
      this.masterDataService.rateCalculatorConvertor(this.currencyAmount, this.currencySelected, Constants.sellTypes[0],Constants.productsEnum[0]).subscribe(resp => {
        let rateResponse = resp.body;
            this.rateCalculatorResponse = rateResponse["cardRateResponse"];
            var cardRateCalculatorObject={
                "requiredCurrencyCodeAmount":this.currencyAmount,
                "requiredCurrencyCode":this.currencySelected,
            }
            this.showErrorModal = false;
            localStorage.setItem(ConstantsKey.SSO_RESP,JSON.stringify(cardRateCalculatorObject));
              console.log('this.rateCalculatorResponse_+_+', this.rateCalculatorResponse);
      }, err => {
        console.log(err);
          this.showErrorModal = true;
           Swal.fire({
              title:"Dear Customer", 
              html: err.error.message, 
              imageUrl: "assets/images/icons/info_icon.png",
              imageWidth: 80,
              imageHeight: 80,
              focusConfirm: false,
              confirmButtonColor: '#1D2A62'
            });
          //this.modalReference = this.modalService.open(this.AmountLimitModal, { centered: true, size: 'sm' });
        
      });
    } else {
      this.rateCalculatorResponse = {};
    }
  }


  showHidePricingRange(showPricing: boolean) {
    this.showPricing = showPricing;
  }

  getCurrencyFields() {
    // this.currencyAmount = '';
    // this.showPricing = false;
    this.convertAmount();
  }

  callQuotation() {
    this.showLoader = true;
    //this.currencySelectedObj = this.getSelectedCurrencyObj(this.currencySelected);
    if (this.currencySelected) {
      if (this.currencyAmount && this.currencyAmount >= 0) {
        let orderRequestList = [];
        let singleRequestCurrency: CartItemCreateRequestList = new CartItemCreateRequestList();
        singleRequestCurrency.currencyAmount = this.currencyAmount;
        singleRequestCurrency.currencyCode = this.currencySelected;
        singleRequestCurrency.product = "FOREXCARD";
       // singleRequestCurrency.bidRate = +JSON.stringify(this.currencySelectedObj.minSalePriceInINR)

        singleRequestCurrency.purpose = (this.remittanceStep01.purpose) ? this.remittanceStep01.purpose : 'UNIVERSITY_FEES';
        orderRequestList.push(singleRequestCurrency);

        this.quotationRequest.sellType = Constants.sellTypes[0];
        this.quotationRequest.partnerId = Constants.partnerId;
        this.quotationRequest.nostroChargeType = "REMITTER"
        this.quotationRequest.quotationCurrencies = orderRequestList;

        this.quotationRequest.pan = this.consumerPanSelf;
        this.quotationRequest.tcsPaymentSource = this.tcsPaymentSource;

        if (this.quotationResponse) {
          this.quotationRequest.quotationSnapshotId = this.quotationResponse.quotationSnapshotId;
        }

        this.cardService.getQuotationEncrypted(this.quotationRequest).subscribe((respQuote) => {
          this.showLoader = false;

          this.quotationResponse = respQuote.body;
          this.quotationCurrencyResponsesList = this.quotationResponse['quotationCurrencyResponses'];
          this.quotationSnapshotId = this.quotationResponse.quotationSnapshotId;
        }, error => {
          this.showLoader = false;
          this.showPricing = false;
          if (error.status==200 ) {
                this.quotationResponse = JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));
                this.quotationCurrencyResponsesList = this.quotationResponse['quotationCurrencyResponses'];
                this.quotationSnapshotId = this.quotationResponse.quotationSnapshotId;
          } else {
                this.toast.error(error.error.message);

          }
        });

        this.showPricing = true;
      } else {
        this.showLoader = false;
        this.toast.error("Please fill in the currency amount.");
      }
    } else {
      this.showLoader = false;
      this.toast.error("Please fill in the currency");
    }
  }

  getSelectedCurrencyObj(currencyCode: string) {
    // var returnCurrencyObj: any;
    // this.currencyItems.forEach(singleCurreny => {
    //   if (singleCurreny.currencyCode == currencyCode) {
    //     returnCurrencyObj = singleCurreny;
    //   }
    // });
    // return returnCurrencyObj;
    return this.currencySelected;
  }


  async getProfileDetails() {
    this.showLoader = true;
    var resp = await this.updateProfileService.getProfileListAll().toPromise();

    this.showLoader = false;
    this.consumerDetail = resp.body;
    this.paxId = this.consumerDetail.paxResponse.id;
    this.isVcipComplete = this.consumerDetail?.vcipKyc;
    if (this.consumerDetail?.paxResponse?.consumer?.vcipSkipAllowed) {
      this.isVcipComplete = true;
    } else if (this.consumerDetail?.paxResponse?.consumer?.offlineonboarding == '1') {
      this.isVcipComplete = true;
    }
    if(this.consumerDetail?.paxResponse!=null && this.consumerDetail?.paxResponse?.pan){
      this.isPANAvailable=true;
    }
    if (!this.isVcipComplete ) {
      this.getVCIPStatus();
    }
    console.log("this.consumerDetail >>", this.consumerDetail);

    return true;
  }

   
  
  async proceedToBuySelectedCard() {
    this.proceedWithoutVCIPCompletion = sessionStorage.getItem('PROCEED-WITHOUT-VCIP-CARD');
    if ((!this.isVcipComplete && this.proceedWithoutVCIPCompletion == null)) {
      this.openModalForVCIPNewUser();
    } else {
      if (this.currencyAmount) {
       if(!this.showErrorModal) {
        this.quotationRequestModel = new QuotationRequestModel();
        this.quotationRequestModel.sellType = Constants.sellTypes[0];
        this.quotationRequestModel.partnerId = Constants.partnerId;
        let quotationCurrencies = new QuotationCurrenciesRequestModel();
        quotationCurrencies.currencyAmount = this.currencyAmount;
        quotationCurrencies.currencyCode = this.currencySelected;
        quotationCurrencies.product = Constants.productsEnum[0];
        quotationCurrencies.purpose = 'PRIVATE_VISIT';
        this.cartCurrenciesList.push(quotationCurrencies);
        localStorage.setItem('cartCurrenciesList', JSON.stringify(this.cartCurrenciesList));
        sessionStorage.setItem(ConstantsKey.IS_FROM_BUYNEWCARD, 'true');
        sessionStorage.setItem(ConstantsKey.IS_FROM_REMITTANCE_FLOW, 'false');
        sessionStorage.setItem(ConstantsKey.IS_FROM_RELOAD, 'false');
        this.router.navigate(['/newcard']);
        } else {
          this.modalReference = this.modalService.open(this.AmountLimitModal, { centered: true, size: 'sm' });
        }
      } else {
        this.toast.error('Please enter amount');
      }
    }
  }

  onMobileClick(event: any) {
    if (window.innerWidth <= 768) { // Adjust the width threshold as needed
      this.proceedToBuySelectedCard();
    }
  }

  showBuyForex() {
      this.showBuyForexForm = true;
      this.activeBuyForexCard = true;
  }

  checkVCIPComplete() {
    if ((!this.isVcipComplete && !this.proceedWithoutVCIPCompletion) && !this.cardLinkedAlready) {
      this.openModalForVCIP();
    }
  }

  openModalForVCIP() {
    this.modalReference = this.modalService.open(this.vcipCompleteOrNotModal, { size:'xl',centered: true });
  }
  openModalForVCIPNewUser() {
    this.modalReference = this.modalService.open(this.vcipModalNewUser, {centered: true });
  }


  initiateVcip() {
    this.modalReference.close();
    this.updateProfileService.getVCIPRedirectionURL().subscribe((resp => {
      this.showLoader = false;
      var vcipResponse = JSON.parse(JSON.stringify(resp.body));
      let vcipUrl = vcipResponse.mobileAutologinUrl;
      window.open(vcipUrl, '_blank');
    }), error => {
      this.showLoader = false;
      this.toast.error(JSON.stringify(error.error.message));
    });
  }

  proceedWithourVCIP() {
    this.modalReference.close();
    this.isVCIPNow = false;
    if(this.isOrderWithoutVcipComplete){
      sessionStorage.setItem('PROCEED-WITHOUT-VCIP-CARD', 'true');
    }else{
      if(this.isPANAvailable){
        sessionStorage.setItem('PROCEED-WITHOUT-VCIP-CARD', 'true');

      }else{
        this.openModalForPANNo();

      }
    }
  }

  openModalForPANNo() {
    this.modalReference.close();
    this.modalReference = this.modalService.open(this.PANDetailModal, { centered: true, size: 'sm' });
  }
  validatePanDetails(){
    if(!(this.updateProfileModel.pan!=null )){
      this.toast.error('Please enter your PAN');
      return false;
    }
    if(!this.validatePANNo(this.updateProfileModel.pan)) {
      this.toast.error('Please enter valid PAN');
      return false;      
    }
    if(!(this.updateProfileModel.name!=null )){
      this.toast.error('Please enter name as per PAN');
      return false;
    } 
    if(!this.isPANCBChecked){
      this.toast.error('Please check the declaration of PAN');
      return false;
    }
    if(!(this.updateProfileModel.dob!=null )){
      this.toast.error('Please select your Date of Birth ');
      return false;
    }
    
    
      return true;
  }
  openErrorModal(){
    this.modalReference = this.modalService.open(this.errorModal, { centered: true, size: 'sm' });

  }
  validatePanAPI(){
   
    if(this.validatePanDetails()) {   
    this.showLoader = true;
    let dateofBirth = DateUtils.getNgbDateStructToDatev2(this.updateProfileModel.dob);

    this.updateProfileService.validatePAN(this.updateProfileModel.pan,this.updateProfileModel.name,dateofBirth).subscribe(async resp => {
      console.log(resp.body);
      var panResponse = JSON.parse(JSON.stringify(resp.body));
      this.showLoader = false;
      if(panResponse.panStatus=='E' && panResponse.fullName!="" && panResponse.dob!=""){
        this.updatePAN();
      }else if(panResponse.panStatus!='E'){
        this.panVerificationErrMsg="Please enter valid PAN";
        this.openErrorModal();
      }else if(panResponse.fullName==""){
        this.panVerificationErrMsg="Please enter Name as per PAN";
        this.openErrorModal();
      }
      else if(panResponse.dob==""){
        this.panVerificationErrMsg="Please enter valid Date of Birth";
        this.openErrorModal();
      }
    }, error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    }); 
  }
  }
  getPanName() {

    this.updateProfileModel.pan = this.updateProfileModel.pan.toUpperCase();
    if (this.updateProfileModel.pan && this.updateProfileModel.pan.length == 10 && this.validatePANNo(this.updateProfileModel.pan)) {
      this.showLoader = true;
      this.updateProfileService.getPANName(this.updateProfileModel.pan).subscribe(async resp => {
        console.log(resp.body);
        var panResponse = JSON.parse(JSON.stringify(resp.body));
        this.showLoader = false;
        if(panResponse.panStatus == 'E') {
          if (panResponse.fullName && panResponse.fullName != 'null' && panResponse.fullName != '') {
            this.updateProfileModel.name = panResponse.fullName;
          } else {
            this.updateProfileModel.name = '';
          }
  
          if(panResponse.dob && panResponse.dob != 'null' && panResponse.dob != '') {
            this.updateProfileModel.dob = DateUtils.ngbDateStructDateConverter(panResponse.dob);
          }
        } else if(panResponse.flatPan.status == "Record Not Found in ITD Database/Invalid PAN") {
          this.updateProfileModel.name = '';
          this.updateProfileModel.dob = ''; 
          this.toast.error("Invalid Pan");
        } /* else {
          this.toast.error(panResponse.flatPan.status);
        } */

      }, error => {
        this.showLoader = false;
        this.updateProfileModel.name = '';
        // this.updateProfileModel.dob = '';
        this.toast.error(error.error.message);
      });
    }
  }

  updatePAN() {
    this.showLoader = true;
    this.updateProfileModel.mobile = this.consumerDetail.mobile;
    this.updateProfileModel.email = this.consumerDetail.email;
    let dateofBirth = DateUtils.getNgbDateStructToDate(this.updateProfileModel.dob);
    this.updateProfileModel.dob=dateofBirth;

    this.updateProfileService.updateProfileDetails(this.paxId, this.updateProfileModel).subscribe(async resp => {
      this.showLoader = false;
      console.log(resp.body);
      sessionStorage.setItem('PROCEED-WITHOUT-VCIP-CARD', 'true');
      this.modalReference.close();
      //this.proceedToViewCart();
    }, error => {
      this.showLoader = false;
      if (error.status == 200) {
        sessionStorage.setItem('PROCEED-WITHOUT-VCIP-CARD', 'true');
        this.showLoader = false;
        this.modalReference.close();
        if(this.isVCIPNow) {
          this.initiateVcip();
        }        
        //this.proceedToViewCart();
      } else {
        this.toast.error('Something went wrong ! Please try again !');
      }
    });
  }

  proceedToViewCart() {
    if (this.showBuyForexForm) {
     // this.currencySelectedObj = this.getSelectedCurrencyObj(this.currencySelected);
      if (this.currencyAmount) {
        this.quotationRequestModel = new QuotationRequestModel();
        this.quotationRequestModel.sellType = Constants.sellTypes[0];
        this.quotationRequestModel.partnerId = Constants.partnerId;

        let quotationCurrencies = new QuotationCurrenciesRequestModel();
        quotationCurrencies.bidRate = +JSON.stringify(this.currencySelectedObj.minSalePriceInINR);
        quotationCurrencies.currencyAmount = this.currencyAmount;
        quotationCurrencies.currencyCode = this.currencySelected;
        quotationCurrencies.product = Constants.productsEnum[0];
        quotationCurrencies.purpose = 'PRIVATE_VISIT';

        this.cartCurrenciesList.push(quotationCurrencies);
        localStorage.setItem('cartCurrenciesList', JSON.stringify(this.cartCurrenciesList));
        sessionStorage.setItem(ConstantsKey.IS_FROM_BUYNEWCARD, 'true');
        sessionStorage.setItem(ConstantsKey.IS_FROM_REMITTANCE_FLOW, 'false');
        sessionStorage.setItem(ConstantsKey.IS_FROM_RELOAD, 'false');
        this.router.navigate(['/view-cart']);
      } else {
        this.toast.error('Please enter amount');
      }
    } else {
      this.toast.error('Please select Buy New Card Section !');
    }
  }
  VCIPNow() {
    this.isVCIPNow = true;
    this.openModalForPANNo();
  }

  validatePANNo(panNo) {
    const regex = /^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/;
    if (!regex.test(panNo)) {
      return false;
    } else {
      return true;
    }
  }
  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
} 

