import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreCommonModule } from '@core/common.module';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SmartFxLandingComponent } from './views/smartfx-landing.component';
import { LoginService } from '../onboarding/services/login.service';
import { LoaderRoutingModule } from 'app/shared/utility/utility-routing.module';

const routes = [
  {
    path: 'smartfx-landing',
    component: SmartFxLandingComponent,
    data: { animation: 'SmartFxLandingComponent' },
    canActivate: [AuthGuard]
  }
  // {
  //   path: '',
  //   loadChildren: () => import('app/modules/smartfx-landing/smartfx-landing.module').then(m => m.SmartFxRoutingModule)
  // },
];

@NgModule({
  declarations: [
    SmartFxLandingComponent
  ],
  imports: [RouterModule.forChild(routes),
    CoreCommonModule,
    CardSnippetModule,
    CoreDirectivesModule,
    NgSelectModule,
    PerfectScrollbarModule,
    LoaderRoutingModule,

  ],
  exports: [
    SmartFxLandingComponent, PerfectScrollbarModule,
  ],
  providers: [LoginService]
})
export class SmartFxRoutingModule { }
