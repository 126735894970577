import { Component, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { CoreConfigService } from '@core/services/config.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { LoginModel, PasswordResetPatchRequest, PasswordResetRequest, RateDropdownModel, SignUpRequestModel } from '../models/smartFxLandingModels';
import { SmartFxLandingService } from '../services/smartffx-landing-services';
import * as shajs from "sha.js";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { RequestLoginModel } from 'app/modules/onboarding/models/login.models';
import { LoginService } from 'app/modules/onboarding/services/login.service';
import { CardService } from 'app/modules/card/services/card.service';


@Component({
  selector: 'smartfx-landing',
  templateUrl: './smartfx-landing.component.html',
  styleUrls: ['./smartfx-landing.component.scss'],
  providers: [SmartFxLandingService]
})
export class SmartFxLandingComponent implements OnInit {
  private _unsubscribeAll: Subject<any>;
  public currencyRatesAll: any;
  public currencyRates: any;
  public usdCurrentRate: any;
  public rateDropdownModel = new RateDropdownModel();
  public captchaInput = '';
  public captchaReceived;
  public loginRequest = new LoginModel();
  public currentUser: any;
  public captcha: any;
  public signUpRequest = new SignUpRequestModel();
  public showSend = true;
  public showInvalidPassword: boolean = false;
  public signupForm: FormGroup;
  public referral: string;
  public tncChecked: boolean = false;
  public policyChecked = false;
  public modalReferenceForForgotPwd: any;
  public modalReferenceForLogin: any;
  public modalReferenceForRegister: any;
  public loading = false;
  public submitted = false;
  public showLoader: boolean = false;
  public loginForm: FormGroup;
  public error = '';
  public requestLoginModel = new RequestLoginModel();
  public passwordTextType: boolean;
  public hide: boolean = true;
  public hidePassword: boolean = true;
  public showFormSection: string = "Forgot-PASSWORD";
  public passwordResetPatchRequest = new PasswordResetPatchRequest();
  public passwordResetRequest = new PasswordResetRequest();
  public modalReferenceForResetPwd: any;
  public resendOTPBtnActive: boolean = false;
  public sendOTPButtonVisible: boolean = true;
  public errorText: string = '';
  public displayTimer: any;
  public showTimer: boolean = false;
  public showResendButton: boolean = false;

  constructor(
    private loginService: LoginService,
    public toast: ToastrService,
    public router: Router,
    private modalService: NgbModal,
    public smartFxLandingService: SmartFxLandingService,
    public _formBuilder: FormBuilder,
    private _coreConfigService: CoreConfigService,
    public dialogRef: NgbModal,
    public cardService: CardService
  ) {
    this._unsubscribeAll = new Subject();
    this._coreConfigService.config = {
      layout: {
        navbar: { hidden: true },
        menu: { hidden: true },
        footer: { hidden: true },
        customizer: false,
        enableLocalStorage: false
      }
    };

    this.signupForm = this._formBuilder.group({
      //fullName: ['', [Validators.required]],
      phoneno: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]],
      otp: ['', [Validators.required]],
      referral: new FormControl(),
      tncCheckBox: new FormControl(),
      policyCheckBox: new FormControl()
    });
  }

  ngOnInit() {
    this.loginForm = this._formBuilder.group({
      id: ['', [Validators.required]],
      otp: ['', Validators.required]
    });
    this.getCurrencyRate();
    this.rateDropdownModel.currencyCode = "USD";
    this.getCaptcha();
    localStorage.clear();
  }

  openModalRegister(registerModal) {
    if (this.modalReferenceForLogin) {
      this.modalReferenceForLogin.close();
    }
    this.modalReferenceForRegister = this.modalService.open(registerModal, { centered: true });
  }

  openModalLogin(loginModal) {
    if (this.modalReferenceForRegister) {
      this.modalReferenceForRegister.close();
    }
    this.modalReferenceForLogin = this.modalService.open(loginModal, { centered: true });
  }

  openModalForgotPwd(forgotPwdModal) {
    this.modalReferenceForForgotPwd = this.modalService.open(forgotPwdModal, { centered: true });
  }

  closePopupForSignUp() {
    this.modalReferenceForRegister.close();
  }

  closePopupForLogin() {
    this.modalReferenceForLogin.close();
  }

  closePopupForForgotPwd() {
    this.modalReferenceForForgotPwd.close();
  }

  openModalForResetPassword(validData, resetPwdModal) {
    if (validData == true) {
      this.modalReferenceForForgotPwd.close();
      this.modalReferenceForResetPwd = this.modalService.open(resetPwdModal, { centered: true });
    }
  }

  closePopupForResetPwd() {
    this.modalReferenceForResetPwd.close();
  }

  getCaptcha() {
    this.smartFxLandingService.getCaptcha().subscribe((result) => {
      var captchaRes = result.body;
      this.captcha = JSON.stringify(captchaRes["key"]);
      this.captchaReceived = captchaRes;
    });
  }

  getCurrencyRate() {
    this.smartFxLandingService.getProductCurrentRate("REMITTANCE").subscribe((result) => {
      var currentResult = result.body;
      this.currencyRatesAll = currentResult[0]["rates"];
      this.currencyRates = currentResult[0]["rates"].slice(0, 1);
      this.usdCurrentRate = currentResult[0]["rates"][0]["minSalePriceInINR"];
      localStorage.setItem('usdCurrentRate', this.usdCurrentRate);
    });
  }

  getOTPForLogin() {
    this.showLoader = true;
    this.loginService.getMobileOTPLogin(this.requestLoginModel.id).subscribe((resp => {
      this.showLoader = false;
      this.timer(1);
      this.showTimer = true;
      this.toast.success("OTP sent to registered Mobile Number and Email Id");
    }), error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }


  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }


  onPaste(e) {
    e.preventDefault();
    return false;
  }

  get f() {
    return this.loginForm.controls;
  }

  get func() { return this.signupForm.controls; }

  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  loginWithOTPSubmit() {
    this.submitted = true;
    this.showLoader = true;
    if (this.loginForm.invalid) {
      this.showLoader = false;
      return;
    } else {
      this.error = '';
      this.requestLoginModel.type = "MOBILE";
      if (this.requestLoginModel.otp.toString().length == 6) {
        this.loginService.loginWithOtp(this.requestLoginModel).subscribe(resp => {
          this.showLoader = true;
          var respBody = JSON.parse(JSON.stringify(resp.body));
          this.modalReferenceForLogin.close();
          localStorage.setItem(ConstantsKey.X_AUTH_TOKEN, resp.headers.get('X-AUTH-TOKEN'));
          localStorage.setItem(ConstantsKey.CONSUMER_ID, respBody.consumer.id);
          localStorage.setItem(ConstantsKey.CONSUMER_DATA, JSON.stringify(respBody.consumer));
          localStorage.setItem(ConstantsKey.OTP_LOGIN_RESPONSE, JSON.stringify(respBody));
          console.log("ConstantsKey.CONSUMER_DATA", JSON.stringify(respBody.consumer));

          localStorage.setItem("validCardCurrencyList",JSON.stringify(respBody["validCardCurrencyDetails"]));

          // localStorage.setItem(ConstantsKey.AGENT_CODE, "WSFx-agent");
          // localStorage.setItem(ConstantsKey.AGENT_KEY, "kjkjafhkhds-agkjag-099");
          this.checkForCards("LOGIN");
          /* var cardList = [];
          cardList = respBody["consumerCardResponseList"];
          if (cardList.length > 0) {
            this.router.navigate(["/my-card"]);
          } else {
            this.router.navigate(["/new-dashboard"]);
          } */

        }, error => {
          this.showLoader = false;
          this.toast.error(error.error.message);
          this.error = error.error.message;
        });
      } else {
        this.showLoader = false;
        this.toast.error('OTP must be 6 characters long');
      }
    }
  }

  loginPass(validData) {
    if (validData) {
      this.showLoader = true;
      var captchaToValidate = shajs("sha256").update(this.captchaInput).digest("hex");
      var captchaReceived = this.captchaReceived["value"];
      if (captchaToValidate == captchaReceived) {
        let password =
          shajs("sha256").update(this.loginRequest.token).digest("hex") +
          "@1";
        this.loginRequest.token = password;
        this.loginRequest.type = "MOBILE";
        this.loginRequest.passwordType = "PASSWORD";
        this.smartFxLandingService.login(this.loginRequest).subscribe((result) => {
          this.currentUser = result.body;
          localStorage.setItem(
            "validCardCurrencyList",
            JSON.stringify(this.currentUser["validCardCurrencyDetails"])
          );
          localStorage.setItem(ConstantsKey.X_AUTH_TOKEN, result.headers.get('X-AUTH-TOKEN'));
          localStorage.setItem(ConstantsKey.CONSUMER_ID, this.currentUser['consumer']['id']);
          localStorage.setItem(ConstantsKey.CONSUMER_DATA, JSON.stringify(this.currentUser['consumer']));
          localStorage.setItem(ConstantsKey.OTP_LOGIN_RESPONSE, JSON.stringify(this.currentUser));

          console.log("this.currentUser", JSON.stringify(result));

          //var cardList = [];
          //cardList = this.currentUser["consumerCardResponseList"];
          localStorage.setItem(
            "consumerID",
            this.currentUser["consumer"]["id"]
          );
          /* if (cardList.length > 0) {
            localStorage.setItem("isCardLinked", "true");
          } else {
            localStorage.setItem("isCardLinked", "false");
          } */
          localStorage.setItem(
            "passwordSet",
            this.currentUser["passwordSet"]
          );
          localStorage.setItem("cartCount", "0");
          localStorage.setItem(
            "kycStatus",
            this.currentUser["kysStatus"]
          );
          localStorage.setItem(
            "mobile",
            this.currentUser["consumer"]["mobile"]
          );
          localStorage.setItem(
            "rateExpiryInSeconds",
            this.currentUser["rateExpiryInSeconds"]
          );
          localStorage.setItem(
            "rateExpiryInSecondsInitial",
            this.currentUser["rateExpiryInSeconds"]
          );
          if (this.currentUser["consumer"]["dob"]) {
            localStorage.setItem(
              "dob",
              this.currentUser["consumer"]["dob"]
            );
          }
          var shortConfigList = this.currentUser["shortConfigs"];
          localStorage.setItem("appConfig", JSON.stringify(shortConfigList));
          for (var i = 0; i < shortConfigList.length; i++) {
            if (shortConfigList[i]['key'] == 'VCIP') {
              if (shortConfigList[i]['value'] == 'true') {
                localStorage.setItem('showVCIP', 'true');
              } else {
                localStorage.setItem('showVCIP', 'false');
              }
            }
          }

          this.checkForCards("LOGIN");

          /* if (cardList.length > 0) {
            this.router.navigate(["/my-card"]);
          } else {
            this.router.navigate(["/new-dashboard"]);
          } */

        }, (error) => {
          this.showLoader = false
          this.toast.error(error.error.message);
        });
      } else {
        this.showLoader = false
        Swal.fire("Error", "Invalid Captcha!", "error");
      }
    }
  }
  alphaNumberOnly(e) {  // Accept only alpha numerics, not special characters 
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }

  onlyAlpha(event) {
    var k;
    k = event.keyCode; //         k = event.keyCode;  (Both can be used)
    // return (k >= 65 && k <= 120) || k == 32;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32)
  }

  otpForMobile(mobile) {
    if (mobile != null) {
      var mobile = mobile;
      this.smartFxLandingService.otpForLogin(mobile).subscribe(
        (result) => {
          this.showSend = false;
          Swal.fire(" Success", "OTP has been sent successfully", "success");
          this.currentUser = result.body;
        },
        (error) => {
          this.showInvalidPassword = true;
        }
      );
    } else {
      Swal.fire(" error", "Please enter your phone number", "error");
    }
  }

  otpForSignUp(mobile) {
    if (mobile != null) {
      var mobile = mobile;
      this.smartFxLandingService.getMobileOTPLoginForSignup(mobile).subscribe(
        (result) => {
          this.showSend = false;
          this.showTimer = true;
          this.timer(1);
          Swal.fire(" Success", "OTP has been sent successfully", "success");
          this.currentUser = result.body;
        },
        (error) => {
          this.showInvalidPassword = true;
        }
      );
    } else {
      Swal.fire(" error", "Please enter your phone number", "error");
    }
  }

  otpResend(mobile) {
    this.signUpRequest.otp = null;
    if (mobile != null) {
      var mobile = mobile;
      this.smartFxLandingService.otpForLogin(mobile).subscribe(
        (result) => {
          this.showSend = false;
          Swal.fire(" Success", "OTP resend successfully", "success");
          this.currentUser = result.body;
        },
        (error) => {
          this.showInvalidPassword = true;
        }
      );
    } else {
      Swal.fire(" error", "Please enter your phone number", "error");
    }
  }

  getCheckedValue(checkBoxValue) {
    if (checkBoxValue) {
      this.errorText = '';
    } else {
      this.errorText = "Please accept the terms and conditions to proceed!";
    }
  }

  signUp() {
    this.submitted = true;
    if (this.tncChecked == true) {
      this.errorText = '';
      if (this.signupForm.invalid) {
        return;
      }
      this.showLoader = true;
      this.signUpRequest.referral = this.referral;
      this.smartFxLandingService.otpSignUp(this.signUpRequest).subscribe(
        (result) => {
          this.showLoader = false;
          this.currentUser = result.body;
          this.modalReferenceForRegister.close();
          // this.router.navigate(["/student"]);
          //this.router.navigate(["/new-dashboard"]);
          localStorage.setItem("validCardCurrencyList", JSON.stringify(this.currentUser["validCardCurrencyDetails"]));
          localStorage.setItem("X-AUTH-TOKEN", result.headers.get("X-AUTH-TOKEN"));
          localStorage.setItem(ConstantsKey.CONSUMER_ID, this.currentUser['consumer']['id']);

          var shortConfigList = this.currentUser["shortConfigs"];
          localStorage.setItem("appConfig", JSON.stringify(shortConfigList));
          for (var i = 0; i < shortConfigList.length; i++) {
            if (shortConfigList[i]['key'] == 'VCIP') {
              if (shortConfigList[i]['value'] == 'true') {
                localStorage.setItem('showVCIP', 'true');
              } else {
                localStorage.setItem('showVCIP', 'false');
              }
            }
          }
          //var cardList = [];
          //cardList = this.currentUser["consumerCardResponseList"];
          localStorage.setItem("consumerID", this.currentUser["consumer"]["id"]);
          this.checkForCards("SIGNPUP");

          /* if (cardList.length > 0) {
            localStorage.setItem("isCardLinked", "true");
          } else {
            localStorage.setItem("isCardLinked", "false");
          } */
          localStorage.setItem("passwordSet", this.currentUser["passwordSet"]);
          localStorage.setItem("cartCount", "0");
          localStorage.setItem("kycStatus", this.currentUser["kysStatus"]);
          localStorage.setItem("mobile", this.currentUser["consumer"]["mobile"]);
          localStorage.setItem("rateExpiryInSeconds", this.currentUser["rateExpiryInSeconds"]);
          localStorage.setItem("rateExpiryInSecondsInitial", this.currentUser["rateExpiryInSeconds"]);
          if (
            this.currentUser["consumer"]["dob"]
          ) {
            localStorage.setItem("dob", this.currentUser["consumer"]["dob"]);
          }
          Swal.fire(
            "Success ", "Yay! You have successfully registered!", "success"
          );

        },
        (error) => {
          this.showLoader = false;
          this.toast.error(error.error.message);
        }
      );
    } else {
      this.errorText = "Please accept the terms and conditions to proceed!";
      console.log(this.errorText);
    }
  }

  showPassword() {
    this.hide = !this.hide;
  }

  showSetPassword() {
    this.hidePassword = !this.hidePassword;
  }

  getFormSection(formSectionType) {
    this.showFormSection = formSectionType;
  }

  createNewPass(validData) {
    if (validData) {
      // let password = shajs('sha256').update(this.passwordResetPatchRequest.newPassword).digest('hex') + '@1';
      // this.passwordResetPatchRequest.newPassword = password;
      this.passwordResetPatchRequest.otp = this.passwordResetRequest.otp;
      this.passwordResetPatchRequest.id = this.passwordResetRequest.id;
      this.passwordResetPatchRequest.authType = "MOBILE";
      this.smartFxLandingService.passwordResetPatch(this.passwordResetPatchRequest).subscribe(result => {
        this.showFormSection = 'LOGINOTP';
        Swal.fire("Success", "Yay! Your password has been successfully updated!", "success");
        this.modalReferenceForResetPwd.close();
      }, error => {
        this.toast.error(error.error.message);
      });
    }
  }





  forgotPassSendOTP() {
    this.smartFxLandingService.passwordReset(this.passwordResetRequest).subscribe(result => {
      this.timer(1);
      this.showTimer = true;
      this.resendOTPBtnActive = false; 
      this.sendOTPButtonVisible = false; 
      Swal.fire('Success', 'OTP has been sent successfully.', 'success');
    }, error => {
      this.toast.error('please enter valid mobile number');
    });
  }



  forgotPassResendOTP() {
    this.passwordResetRequest.otp = null;
    this.smartFxLandingService.passwordReset(this.passwordResetRequest).subscribe(result => {
      // this.timer(1);
      // this.showTimer = true;
      Swal.fire('Success',
        'Otp resend successfully',
        'success');
    }, error => {
      this.toast.error(error.error.message);
    })
  }

  timer(minute) {
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = minute < 10 ? "0" : "";

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.displayTimer = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        console.log("finished");
        this.showTimer = false;
        this.resendOTPBtnActive = true; // Show Resend OTP button
        clearInterval(timer);
      }
    }, 1000);
  }

  checkForCards(ACTION) {
    this.cardService.getAllCardDetail().subscribe(resp => {
      let cardList:any = resp.body;
      this.showLoader = false;
      if(ACTION == 'LOGIN') {
        if(cardList.length > 0) { 
          localStorage.setItem("isCardLinked", "true");
          this.router.navigate(["/my-card"]);              
        } else {
          localStorage.setItem("isCardLinked", "false");
          this.router.navigate(["/new-dashboard"]);
        }
      } else if(ACTION == 'SIGNPUP') {
        if (cardList.length > 0) {
          localStorage.setItem("isCardLinked", "true");
        } else {
          localStorage.setItem("isCardLinked", "false");
        }
        this.router.navigate(["/new-dashboard"]);
      }

    }, err => {
      this.showLoader = false;            
    });    
  }


}
