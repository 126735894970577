import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core'
import { CardBlockModel, CartItemCreateRequestList, CustomerValidationRequestModel, ManageCardUpdateModel, OTPRequestModel, QuotationCurrenciesRequestModel, QuotationRequestModel, RemittanceStep01Model, ReplaceCardModel, SetNewPinModel } from '../../models/card.model';
import { CardService } from '../../services/card.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { DateUtils } from 'app/shared/utility/date-utils';
import { ReqGetCurrencyMaster, SingleRateResItem } from 'app/shared/models/shared-models';
import { QuotationCurrencyItem } from 'app/modules/dashboard/models/quotation.models';
import { MasterDataService } from 'app/shared/services/master-data.service';
import { UpdateProfileService } from 'app/modules/update-profile/services/update-profile.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewDashboardPurposeList } from 'app/shared/constant/new-dashboard-menu';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { Constants } from 'app/shared/constant/constants';

@Component({
  selector: 'manage-card',
  styleUrls: [
    '../../../../../@core/scss/base/pages/app-ecommerce.scss',
    '../card.component.scss',
    './manage-card.component.scss'],
  templateUrl: './manage-card.component.html',
  encapsulation: ViewEncapsulation.None,
  host: { class: 'ecommerce-application' }
})
export class ManageCardComponent implements OnInit {

  public cardDetails: any;
  public isDataLoaded: boolean = false;
  public showLoader: boolean = false;
  public showLoaderForCardDetails: boolean = false;
  public recentTransactionList: any;
  public showManageCardSection: boolean = true;
  public kitId: any;
  public cardLockFlag: any;
  public inrWalletStatus: any;
  public consumerData: any;
  public showBuyCard: boolean = false;

  public manageCardUpdateModel = new ManageCardUpdateModel();
  public setPinRequest = new SetNewPinModel();
  public oTPRequestModel = new OTPRequestModel();
  public reqGetCurrencyMaster: ReqGetCurrencyMaster = new ReqGetCurrencyMaster();
  public cardBlockModel = new CardBlockModel();
  public quotationCurrencyItem: QuotationCurrencyItem = new QuotationCurrencyItem();

  public otpReceived: boolean = false;
  public setPINDobMaxDate: any;
  public setPINMinDate: any;
  public selectedPurposeItem: any;
  public modalReference: any;
  public currencyItems: any;
  public walletOTP: any;
  disableCardModalMsg: any;
  public replacingReason: string;
  public replaceCardOthersComment: string;
  public vcipStatusDetails: any;
  public vcipMessage: any;
  public currencyAmount;
  public currencySelectedObj: any;
  public vcipAccepted: any;
  public currencySelected: any;
  public quotationResponse: any;
  public quotationSnapshotId: any;
  public consumerDetail: any;

  public consumerPanSelf: string;
  public tcsPaymentSource = 'OWN';
  public quotationCurrencyResponsesList = [];
  public cartCurrenciesList = new Array<QuotationCurrenciesRequestModel>();

  public showButtonOnVCIP: boolean = false;
  public newPinShow: boolean = false;
  public confirmPinShow: boolean = false;
  public showPricing: boolean = false;
  public showCommentInput: boolean = false;
  public showBuyForexForm: boolean = false;
  public activeBuyForexCard: boolean = false;

  public singleRateResItem: SingleRateResItem = new SingleRateResItem();
  public remittanceStep01: RemittanceStep01Model = new RemittanceStep01Model();
  public quotationRequest = new QuotationRequestModel();
  public quotationRequestModel: QuotationRequestModel;
  public disableCardFunctionality: boolean = false;
  public isCorporateCustomer: boolean = false;
  public isManageCardLock: boolean= false; 

  @ViewChild('disableCardModal') disableCardModal: TemplateRef<any>;
  @ViewChild('disableINRWallet') disableINRWallet: TemplateRef<any>;

  public manageCardListItems = [
    { 'operation': 'ATM', 'title': 'ATM', 'icon': 'assets/images/manage-card/atm-transactions.png', 'studentIcon': 'assets/images/smartfx-portal-assets/buy-forex/manage-card/ATM-transactions.png', 'status': true },
    { 'operation': 'ECOM', 'title': 'Online', 'icon': 'assets/images/manage-card/Online-transactions.png', 'studentIcon': 'assets/images/smartfx-portal-assets/buy-forex/manage-card/online-transactions.png','status': true },
    { 'operation': 'INTERNATIONAL', 'title': 'International', 'icon': 'assets/images/manage-card/International-Transactions.png', 'studentIcon': 'assets/images/smartfx-portal-assets/buy-forex/manage-card/international-transactions.png','status': true },
    { 'operation': 'POS', 'title': 'Physical', 'icon': 'assets/images/manage-card/swipe-transactions.png', 'studentIcon': 'assets/images/smartfx-portal-assets/buy-forex/manage-card/physical-swipe-transactions.png','status': true },
    { 'operation': 'CONTACTLESS', 'title': 'Contactless', 'icon': 'assets/images/manage-card/contactless-transactions.png', 'studentIcon': 'assets/images/smartfx-portal-assets/buy-forex/manage-card/contacless-transactions.png','status': false },
  ];

  public cardType: string = '';
  public studentCardData: any;


  constructor(
    private cardService: CardService,
    private masterDataService: MasterDataService,
    private router: Router,
    public updateProfileService: UpdateProfileService,
    public toast: ToastrService,
    public modalService: NgbModal
  ) {
    this.currencySelected = "USD";

    this.setPINMinDate = { year: 1900, month: 1, day: 1 };
    this.setPINDobMaxDate = { year: new Date().getFullYear() - 13, month: new Date().getMonth() + 1, day: new Date().getDate() }
  }

  ngOnInit() {
    this.disableCardModalMsg = "Your card is temporarily Locked. Please Unlock your card to proceed!";
    this.getLocalData();
    this.getCardDetails();
    // this.getCurrencies();
    this.getRateRange();
    this.getVCIPStatus();
    this.getProfileDetails();

    this.selectedPurposeItem = NewDashboardPurposeList.getPurposeObjectByRemittanceType('STUDENT');
    console.log(this.selectedPurposeItem);
  }

  getLocalData() {
    var consumerDataLocal = localStorage.getItem(ConstantsKey.CONSUMER_DATA);
    this.consumerData = JSON.parse(consumerDataLocal);

    this.cardType = sessionStorage.getItem(ConstantsKey.SELECTED_CART_TYPE);

    console.log("this.consumerData ", this.consumerData);
  }

  getCardDetails() {
    this.showLoaderForCardDetails = true;

    if(this.cardType == 'smartCurrencyCard') {
      this.cardService.getCardDetails().subscribe((respCardData => {
        var responseBody = JSON.parse(JSON.stringify(respCardData.body));
        console.log(responseBody);
  
        this.isDataLoaded = true;
  
        if (responseBody.length > 0) {
          this.cardDetails = responseBody[0];
          if (responseBody[0].cardLockFlag == 'L') {
            this.disableCardFunctionality = true;
          } else {
            this.disableCardFunctionality = false;
          }
          this.showLoaderForCardDetails = false;
          this.recentTransactionList = this.cardDetails.paxStatementSnippets;
  
          this.kitId = this.cardDetails['kitId'];
          this.cardLockFlag = this.cardDetails['cardLockFlag'];
          this.inrWalletStatus = this.cardDetails['inrWalletActive'];
          sessionStorage.setItem(ConstantsKey.MANAGE_CARD_STATUS, this.cardLockFlag);
  
          this.getCardStatus();
        } else {
          // this.router.navigate(['/dashboard'])
          this.showBuyCard = true;
        }
      }), error => {
        this.showLoaderForCardDetails = false;
        this.router.navigate(['/home'])
          .then(() => {
            window.location.reload();
          });
        this.toast.error(error.error.message);
      });
    } else if(this.cardType == 'studentPayCard') {
      this.studentCardData = JSON.parse(sessionStorage.getItem(ConstantsKey.STUDENT_CARD_DATA));
      this.kitId = this.studentCardData.kitId;
      console.log(this.studentCardData);
      if(this.studentCardData.status == 'Activated') {
        this.cardLockFlag = 'UL';
      } else if(this.studentCardData.status == 'Locked') {
        this.cardLockFlag = 'L';
      }
      sessionStorage.setItem(ConstantsKey.MANAGE_CARD_STATUS, this.cardLockFlag);
      this.getCardStatus();
      console.log("MANAGE PAGE studetn card data : ", this.studentCardData);
    }

  }

  getCardStatus() {
    this.showLoader = true;
    if(this.cardType == 'smartCurrencyCard') {
      this.cardService.getManageCardOptions(this.kitId).subscribe(respCardOption => {

        var settingList = JSON.parse(JSON.stringify(respCardOption.body));
        this.showLoader = false;
  
        settingList.forEach((value, index) => {
          if (value.operation == 'ATM') {
            this.manageCardListItems[0]['status'] = (value.cardSwitch == 'UNBLOCK') ? true : false;
          }
  
          if (value.operation == 'ECOM') {
            this.manageCardListItems[1]['status'] = (value.cardSwitch == 'UNBLOCK') ? true : false;
          }
          
          if (value.operation == 'INTERNATIONAL') {
            this.manageCardListItems[2]['status'] = (value.cardSwitch == 'UNBLOCK') ? true : false;
          }
  
          if (value.operation == 'POS') {
            this.manageCardListItems[3]['status'] = (value.cardSwitch == 'UNBLOCK') ? true : false;
          }
  
          if (value.operation == 'CONTACTLESS') {
            this.manageCardListItems[4]['status'] = (value.cardSwitch == 'UNBLOCK') ? true : false;
          }
        });
      }, error => {
        this.showLoader = false;
        this.toast.error(error.error.message);
      });
    } else if(this.cardType == 'studentPayCard') {
      this.cardService.getStudentPayCardManageCardOption(this.kitId).subscribe(respCardOption => {

        var settingList = JSON.parse(JSON.stringify(respCardOption.body));
        this.showLoader = false;
  
        settingList.forEach((value, index) => {
          if (value.type == 'ATM') {
            this.manageCardListItems[0]['status'] = (value.enabled) ? true : false;
          }
  
          if (value.type == 'E-com') {
            this.manageCardListItems[1]['status'] = (value.enabled) ? true : false;
          }
  
          if (value.type == 'INTERNATIONAL') {
            this.manageCardListItems[2]['status'] = (value.enabled) ? true : false;
          }
  
          if (value.type == 'POS') {
            this.manageCardListItems[3]['status'] = (value.enabled) ? true : false;
          }
  
          if (value.type == 'ContactLess') {
            this.manageCardListItems[4]['status'] = (value.enabled) ? true : false;
          }
        });
        /* this.manageCardListItems = this.manageCardListItems.filter(obj => {
          return obj.operation != 'INTERNATIONAL' 
        }); */

      }, error => {
        this.showLoader = false;
        this.toast.error(error.error.message);
      });
    }

  }

  goToPrevious() {
    this.router.navigate(['/my-card']);
  }

  gotoTansferBalance(selectedCurrencyDetails) {
    if (!this.disableCardFunctionality) {
      this.router.navigate(['/transfer-balance'], { queryParams: selectedCurrencyDetails });
    } else {
      this.showCardBlockPopup();
    }
  }

  navigateAddBalanceToCard(singleCurrency) {
    if (!this.disableCardFunctionality) {
      sessionStorage.clear();
      localStorage.removeItem('cartCurrenciesList')
      console.log("singleCurrency add balance >", singleCurrency);
      localStorage.setItem(ConstantsKey.CARD_ADD_BALANCE_CURRENCY, JSON.stringify(singleCurrency));
      sessionStorage.setItem(ConstantsKey.IS_FROM_RELOAD, 'true');
      sessionStorage.setItem(ConstantsKey.IS_FROM_BUYNEWCARD, 'false');
      sessionStorage.setItem(ConstantsKey.IS_FROM_REMITTANCE_FLOW, 'false');
      this.router.navigate(['/view-cart']);
    } else {
      this.showCardBlockPopup();
    }

  }

  changeCardOptionStatus(selectedCard, index) {
    this.showLoader = true;
    if (this.cardLockFlag == 'UL') {
      if(this.cardType == 'smartCurrencyCard') {
        this.manageCardUpdateModel.cardManageOperations = selectedCard.operation;
        this.manageCardUpdateModel.cardSwitch = (!selectedCard.status) ? 'UNBLOCK' : 'BLOCK';
        this.manageCardUpdateModel.comment = 'USER UPDATE';
        this.manageCardUpdateModel.kitId = this.kitId;

        this.cardService.manageCardUpdate(this.manageCardUpdateModel).subscribe(resp => {
          this.showLoader = false;
          this.getCardStatus();
          this.toast.success(selectedCard.title + " " + (this.manageCardUpdateModel.cardSwitch).toLowerCase() + " successfully!");
        }, error => {
          this.showLoader = false;
          this.toast.error(error.error.message);
        });
      } else if(this.cardType == 'studentPayCard') {

        let requestUpdateCardOption = {};
        requestUpdateCardOption["kit"] =  this.kitId;
        let operTitle = "";
        if(selectedCard.operation == 'ATM') {
          operTitle = 'ATM';
        } else if(selectedCard.operation == 'ECOM') {
          operTitle = 'E-com';
        } else if(selectedCard.operation == 'POS') {
          operTitle = 'POS';
        } else if(selectedCard.operation == 'CONTACTLESS') {
          operTitle = 'ContactLess';
        }
        requestUpdateCardOption["preferenceType"] = (operTitle != '') ? operTitle : selectedCard.operation;
        requestUpdateCardOption["enabled"] = (!selectedCard.status);
        let preferanceStatus = (!selectedCard.status) ? 'UNBLOCK' : 'BLOCK';

        this.cardService.updateStudentPayCardManageCardOption(requestUpdateCardOption).subscribe(resp => {
          this.showLoader = false;
          // this.manageCardListItems[index]["status"] = !selectedCard.status;
          this.getCardStatus();
          this.toast.success(selectedCard.title + " " + (preferanceStatus).toLowerCase() + " successfully!");
        }, error => {
          this.manageCardListItems[index]["status"] = selectedCard.status;
          this.manageCardListItems = JSON.parse(JSON.stringify(this.manageCardListItems));
          this.showLoader = false;
          this.toast.error(error.error.message);
        });
      } 
  } else {
    this.manageCardListItems[index]['status'] = selectedCard.status;
    this.manageCardListItems = JSON.parse(JSON.stringify(this.manageCardListItems));
    this.showCardBlockPopup();
  }


  }

  setPin() {
    if (this.cardLockFlag == 'UL') {
      if (this.setUpPinValidation()) {

        if(this.cardType == 'smartCurrencyCard') {
          this.showLoader = true;
          const setpinModel = new SetNewPinModel();
          setpinModel.newPin = btoa(this.setPinRequest['newPin']);
          //setpinModel.newPinConfirm = this.setPinRequest['newPinConfirm'];
          setpinModel.kitNo = this.kitId;
          setpinModel.expDate = this.setPinRequest['expDate'];
          setpinModel.dob = DateUtils.getNgbDateStructToCardPinDate(this.setPinRequest['dob']);
          setpinModel.otp = this.setPinRequest['otp'];
  
          return this.cardService.changeCardPin(setpinModel).subscribe(data => {
            this.showLoader = false;
            this.toast.success('Card PIN is set successfully');
          }, error => {
            this.showLoader = false;
            if (error.status == 200) {
              this.toast.success('Card PIN is set successfully');
  
            } else {
              this.toast.error(error.error.message);
  
            }
          });
        } else if(this.cardType == 'studentPayCard') {
          const setpinModel = new SetNewPinModel();
          setpinModel.newPin = btoa(this.setPinRequest['newPin']);
          setpinModel["kit"] = this.kitId;
  
          return this.cardService.changeStudentCardPin(setpinModel).subscribe(data => {
            this.showLoader = false;
            this.toast.success('Card PIN is set successfully');
          }, error => {
            this.showLoader = false;
            if (error.status == 200) {
              this.toast.success('Card PIN is set successfully');
  
            } else {
              this.toast.error(error.error.message);
  
            }
          });

        }




      }
    } else {
      this.showCardBlockPopup();
    }
  }
  showCardBlockPopup() {
    this.modalReference = this.modalService.open(this.disableCardModal, { centered: true });
  }

  setUpPinValidation() {
    if (this.setPinRequest['newPin'] == null) {
      this.toast.error('Please enter new pin');
      return false;
    }

    if (this.setPinRequest['newPin'].length != 4) {
      this.toast.error('Please enter 4 digit pin');
      return false;
    }    

    if (this.setPinRequest['newPinConfirm'] == null) {
      this.toast.error('Please enter confirm new pin');
      return false;
    }

    if (this.setPinRequest['newPinConfirm'].length != 4) {
      this.toast.error('Please enter 4 digit confirm pin');
      return false;
    }    

    if (this.cardType == 'smartCurrencyCard' && this.setPinRequest['expDate'] == null) {
      this.toast.error('Please enter card expiry date');
      return false;
    }

    if (this.cardType == 'smartCurrencyCard' && this.setPinRequest['dob'] == null) {
      this.toast.error('Please enter date of birth!');
      return false;
    }

    if (this.cardType == 'smartCurrencyCard' && this.setPinRequest['otp'] == null) {
      this.toast.error('Please enter the OTP received');
      return false;
    }

    if (this.setPinRequest['newPin'] != this.setPinRequest['newPinConfirm']) {
      this.toast.error('New pin & confirm pin does not match.');
      return false;
    }

    return true;
  }

  sendOTP() {
    this.oTPRequestModel.authType = 'MOBILE';
    this.oTPRequestModel.id = this.consumerData?.mobile;
    this.oTPRequestModel.otpRequestReason = 'SET_CARD_PIN_OTP';

    this.cardService.getOTP(this.oTPRequestModel).subscribe(respOtp => {
      var responseBody = respOtp.body;
      this.otpReceived = true;
      this.toast.success('OTP sent successfully.');
    });
  }

  setReplacingReason(replacingReason: string) {
    this.replacingReason = replacingReason;
    if (replacingReason == 'Other') {
      this.showCommentInput = true;
    } else {
      this.showCommentInput = false;
    }
  }

  replacingYourCard() {
    const cardReplaceModel = new ReplaceCardModel();
    // if (this.cardLockFlag == 'UL') {
    if (this.replacingReason.length > 0) {
      if (this.replacingReason == 'Other') {
        if (this.replaceCardOthersComment && this.replaceCardOthersComment.length > 0) {
          this.replacingReason = this.replaceCardOthersComment;
          cardReplaceModel.reason = this.replacingReason;
          cardReplaceModel.kitId = this.kitId;

          this.cardService.replaceCard(cardReplaceModel).subscribe(respData => {
            console.log(respData);
            this.toast.success('Card replacement request has been placed.');
            this.replaceCardOthersComment = null;
            this.showCommentInput = false;
          });
        } else {
          this.toast.error('Kindly provide the reason.');
        }
      } else {
        cardReplaceModel.reason = this.replacingReason;
        cardReplaceModel.kitId = this.kitId;
        this.cardService.replaceCard(cardReplaceModel).subscribe((respData) => {
          this.toast.success('Card Replacement request has been placed.');
        });
      }
    } else {
      this.toast.error('Kindly provide the reason.');
    }
  // }else{
  // this.showCardBlockPopup();
  // }
  }

  openModalForActiveINRWallet(activeINRWalletModal) {
    if (!this.disableCardFunctionality) {
      // this.modalReference = this.modalService.open(activeINRWalletModal, { centered: true });
      // this.getOTPForINRWallet();
      this.showDisableINRWalletPopup();

    } else {
      this.showCardBlockPopup();
    }
  }

  getOTPForINRWallet() {
    this.cardService.getINRWalletOTP().subscribe((resp => {
      this.toast.success('OTP has been sent successfully!');
    }), error => {
      this.toast.error(error.error.message);
    });
  }



  validateOTP() {
    this.cardService.validateActiveINRWalletOTP(this.walletOTP).subscribe((resp => {
      this.getCardDetails();
    }), error => {
      this.toast.error(error.error.message);
    });
  }

  cashoutToINR() {
    this.router.navigate(['/cashout-to-inr-wallet']);
  }

  temporaryBlockCard(cardSwitch) {
    if(this.cardType == 'smartCurrencyCard') {
      this.cardBlockModel.cardManageOperations = 'BLOCK';
      this.cardBlockModel.cardSwitch = cardSwitch;
      this.cardBlockModel.kitId = this.kitId;
      this.cardBlockModel.comment = 'USER ' + cardSwitch;
  
      this.cardService.temporaryBlockCard(this.cardBlockModel).subscribe(data => {
        var msgDisplay;
        if (cardSwitch == 'BLOCK') {
          msgDisplay = ' Your card is locked successfully!';
        } else {
          msgDisplay = ' Your card is unlocked successfully!';
        }
        this.toast.success(msgDisplay);
        this.getCardDetails();
      });
    } else if(this.cardType == 'studentPayCard') {

      let cardBlockModel = {};
      cardBlockModel["kit"] = this.kitId;
      cardBlockModel["status"] = (cardSwitch == 'BLOCK' ? 'LOCKED' : 'UNLOCKED');

      this.cardService.studentPayLockUnlockAndBlockCard(cardBlockModel).subscribe(data => {
        var msgDisplay;
        if (cardSwitch == 'BLOCK') {
          msgDisplay = ' Your card is locked successfully!';
        } else {
          msgDisplay = ' Your card is unlocked successfully!';
        }
        this.toast.success(msgDisplay);
        // this.getCardDetails(); -- call status which give udpate for student pay card
      }, err => {
        console.log(err.status);
        var msgDisplay;
        if (cardSwitch == 'BLOCK') {
          msgDisplay = ' Your card is locked successfully!';
        } else {
          msgDisplay = ' Your card is unlocked successfully!';
        }
        
        if(err.status == "200") {
          this.toast.success(msgDisplay);
          this.getAllCardDetails();
        } else {
          this.toast.error("Something went wrong!!!");
        }
      });
    }

  }


  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }


  getVCIPStatus() {
    this.showLoader = true;
    this.updateProfileService.getVCIPStaus().subscribe((resp => {
      this.showLoader = false;
      this.vcipStatusDetails = resp.body;
      this.vcipMessage = this.vcipStatusDetails.message;
      this.showButtonOnVCIP = this.vcipStatusDetails.showBtnOnStatus;
      if (this.vcipStatusDetails.vcipStatus == 'ACCEPTED') {
        this.vcipAccepted = true;
      } else {
        this.vcipAccepted = false;
      }
    }), error => {
      this.showLoader = false;
    });
  }

  getRateRange() {
    this.masterDataService.getCurrentRateListCard().subscribe(resp => {
      this.singleRateResItem = JSON.parse(JSON.stringify(resp.body));
      this.currencyItems = this.singleRateResItem[0].rates;
      console.log('SINGLE RATE >> ', this.singleRateResItem);

    });
  }


  showHidePricingRange(showPricing: boolean) {
    this.showPricing = showPricing;
  }

  getCurrencyFields() {
    this.currencyAmount = '';
    this.showPricing = false;
  }

  callQuotation() {
    this.showLoader = true;
    this.currencySelectedObj = this.getSelectedCurrencyObj(this.currencySelected);
    if (this.currencySelected) {
      if (this.currencyAmount && this.currencyAmount >= 0) {
        let orderRequestList = [];
        let singleRequestCurrency: CartItemCreateRequestList = new CartItemCreateRequestList();
        singleRequestCurrency.currencyAmount = this.currencyAmount;
        singleRequestCurrency.currencyCode = this.currencySelectedObj.currencyCode;
        singleRequestCurrency.product = "FOREXCARD";
        singleRequestCurrency.bidRate = +JSON.stringify(this.currencySelectedObj.minSalePriceInINR)

        singleRequestCurrency.purpose = (this.remittanceStep01.purpose) ? this.remittanceStep01.purpose : 'UNIVERSITY_FEES';
        orderRequestList.push(singleRequestCurrency);

        this.quotationRequest.sellType = Constants.sellTypes[0];
        this.quotationRequest.partnerId = Constants.partnerId;
        this.quotationRequest.nostroChargeType = "REMITTER"
        this.quotationRequest.quotationCurrencies = orderRequestList;

        this.quotationRequest.pan = this.consumerPanSelf;
        this.quotationRequest.tcsPaymentSource = this.tcsPaymentSource;

        if (this.quotationResponse) {
          this.quotationRequest.quotationSnapshotId = this.quotationResponse.quotationSnapshotId;
        }

        this.cardService.getQuotation(this.quotationRequest).subscribe((respQuote) => {
          this.showLoader = false;

          this.quotationResponse = respQuote.body;
          this.quotationCurrencyResponsesList = this.quotationResponse['quotationCurrencyResponses'];
          this.quotationSnapshotId = this.quotationResponse.quotationSnapshotId;
        }, error => {
          this.showLoader = false;
          this.showPricing = false;
          this.toast.error(error.error.message);
        });

        this.showPricing = true;
      }
      else {
        this.showLoader = false;
        this.toast.error("Please fill in the currency amount.");
      }
    }
    else {
      this.showLoader = false;
      this.toast.error("Please fill in the currency");
    }
  }

  getSelectedCurrencyObj(currencyCode: string) {
    var returnCurrencyObj: any;
    this.currencyItems.forEach(singleCurreny => {
      if (singleCurreny.currencyCode == currencyCode) {
        returnCurrencyObj = singleCurreny;
      }
    });
    return returnCurrencyObj;
  }


  async getProfileDetails() {
    this.showLoader = true;
    var resp = await this.updateProfileService.getProfileListAll().toPromise();

    this.showLoader = false;
    this.consumerDetail = resp.body;
    this.isCorporateCustomer = this.consumerDetail['corporateCustomer'];
    console.log("this.consumerDetail >>", this.consumerDetail);

    return true;
  }

  async proceedToBuySelectedCard() {

    var vcipKycStatus = this.consumerDetail?.vcipKyc;
    if (this.consumerDetail?.paxResponse?.consumer?.vcipSkipAllowed) {
      vcipKycStatus = true;
    } else if (this.consumerDetail?.paxResponse?.consumer?.offlineonboarding == '1') {
      vcipKycStatus = true;
    }
    if (vcipKycStatus) {
      this.currencySelectedObj = this.getSelectedCurrencyObj(this.currencySelected);

      this.quotationRequestModel = new QuotationRequestModel();
      this.quotationRequestModel.sellType = Constants.sellTypes[0];
      this.quotationRequestModel.partnerId = Constants.partnerId;

      let quotationCurrencies = new QuotationCurrenciesRequestModel();
      quotationCurrencies.bidRate = +JSON.stringify(this.currencySelectedObj.minSalePriceInINR);
      quotationCurrencies.currencyAmount = this.currencyAmount;
      quotationCurrencies.currencyCode = this.currencySelectedObj.currencyCode;
      quotationCurrencies.product = Constants.productsEnum[0];
      quotationCurrencies.purpose = 'PRIVATE_VISIT';

      this.cartCurrenciesList.push(quotationCurrencies);
      localStorage.setItem('cartCurrenciesList', JSON.stringify(this.cartCurrenciesList));
      sessionStorage.setItem(ConstantsKey.IS_FROM_BUYNEWCARD, 'true');
      sessionStorage.setItem(ConstantsKey.IS_FROM_REMITTANCE_FLOW, 'false');
      sessionStorage.setItem(ConstantsKey.IS_FROM_RELOAD, 'false');
      this.router.navigate(['/view-cart']);
    } else {
      this.toast.error('Kindly complete your profile and VCIP!');
    }
  }

  showBuyForex() {
    this.showBuyForexForm = true;
    this.activeBuyForexCard = true;
  }


  
  showDisableINRWalletPopup() {
    this.modalReference = this.modalService.open(this.disableINRWallet, { centered: true });
  }

  goToStateMentPage() {
    if (!this.disableCardFunctionality) {
      this.router.navigate(['/statement']);
    } else {
      this.showCardBlockPopup();
    }
  }

  goToCashout() {
    if (!this.disableCardFunctionality) {
      this.router.navigate(['/cashout']);
    } else {
      this.showCardBlockPopup();
    }
  }

  goToManageCard() {
    if (!this.disableCardFunctionality) {
      this.router.navigate(['/manage-card']);
    } else {
      this.showCardBlockPopup();
    }
  }

  bottomFunction() {
    this.showManageCardSection = true;
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })

    // window.scrollTo(0, document.body.scrollHeight);
    //window.scrollBy(0, 50);
  }
  scrollToTopFunction() {
    window.scrollTo({ top: 0, behavior: 'smooth' })

    // window.scrollTo(0, document.body.scrollHeight);
    //window.scrollBy(0, 50);
  }


  navChanged(tabName) {
    if (tabName) {
      if (!this.disableCardFunctionality) {

      } else {
        this.showCardBlockPopup();
      }
    }
  }



  getAllCardDetails() {
    this.cardService.getAllCardDetail().subscribe(resp => {
      let cardData:any = resp.body;
      cardData = cardData.filter(data => data.cardVendor == 'WSFXSTUDENTSCC');
      console.log("ALL CARD STATUS ----------------------------------------------------------- ");
      console.log(cardData[0]['status'])
      if(cardData[0]['status'] == 'Activated') {
        this.cardLockFlag = 'UL';
      } else if(cardData[0]['status'] == 'Locked') {
        this.cardLockFlag = 'L';
      }
      sessionStorage.setItem(ConstantsKey.MANAGE_CARD_STATUS, this.cardLockFlag);
     },
    errObj =>  {
      this.toast.error(errObj.error.message);
    });
  }

}
