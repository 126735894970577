import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreCommonModule } from '@core/common.module';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { LoaderRoutingModule } from 'app/shared/utility/utility-routing.module';
import { ISICCardService } from './services/isic-card.service';
import { IsicCardApplicationComponent } from './view/application/isic-card-application.component';
import { IsicCardComponent } from './view/isic-card-details/isic-card.component';
import { LinkIsicCardComponent } from './view/link-isic-card/link-isic-card.component';

const routes = [
  {
    path: 'isicCard',
    component: IsicCardComponent,
    data: { animation: 'IsicCardComponent' },
    canActivate : [AuthGuard]

  } ,
  {
    path: 'isicCardApplication',
    component: IsicCardApplicationComponent,
    data: { animation: 'IsicCardApplicationComponent' },
    canActivate : [AuthGuard]
  } ,
  {
    path: 'link-isic-card',
    component: LinkIsicCardComponent,
    data: { animation: 'LinkIsicCardComponent' },
    canActivate : [AuthGuard]
  } ,
];

@NgModule({
    declarations: [ IsicCardComponent, IsicCardApplicationComponent, LinkIsicCardComponent
    ],
    imports: [RouterModule.forChild(routes), 
      CoreCommonModule,
      CardSnippetModule,
      FormsModule,
      CoreDirectivesModule,
      NgSelectModule,
      LoaderRoutingModule,
      NgbModule,
      
    ],
    exports: [ 
        IsicCardComponent, IsicCardApplicationComponent, LinkIsicCardComponent
    ],
    providers : [ISICCardService]
})
export class IsicCardRoutingModule {}
