import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core'
import { Constants } from 'app/shared/constant/constants';
import { PurposeList } from 'app/shared/constant/purpose-list';
import { ReqGetCurrencyMaster, SingleRateResItem } from 'app/shared/models/shared-models';
import { MasterDataService } from 'app/shared/services/master-data.service';
import { RemittanceService } from 'app/modules/remittance/services/remittance.service';
import { ToastrService } from 'ngx-toastr';
import { StepperDataModel } from 'app/modules/remittance/models/stepperdata.model';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { ActivatedRoute, Router } from '@angular/router';
import { CardService } from 'app/modules/card/services/card.service';
import { UpdateProfileService } from 'app/modules/update-profile/services/update-profile.service';
import { QuotationCurrencyItem, RequestQuotationModel } from 'app/modules/dashboard/models/quotation.models';
import { NewDashboardPurposeList } from 'app/shared/constant/new-dashboard-menu';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UpdateProfileModel } from 'app/modules/update-profile/models/update-profile.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateUtils } from 'app/shared/utility/date-utils';
import { CryptoUtils } from 'app/shared/utility/crypto.utils';
import { SsoService } from 'app/sso/services/sso.service';
import Swal from 'sweetalert2';
declare const gtag: Function;

@Component({
  selector: 'sso-send-money-dashboard',
  templateUrl: './sso-send-money-dashboard.component.html',
  styleUrls: ['./sso-send-money-dashboard.component.scss',
    '../../../../../@core/scss/base/pages/app-ecommerce.scss',
    '../../../../../@core/scss/angular/libs/ratings.component.scss',
    '../../../../../@core/scss/angular/libs/select.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
  providers: [ SsoService],
  host: { class: 'ecommerce-application' }
})

export class SSOSendMoneyDashboardComponent implements OnInit {
  public reqGetCurrencyMaster: ReqGetCurrencyMaster = new ReqGetCurrencyMaster();
  public showLoader: boolean = false;
  public showLoaderForGetCardDetails: boolean = false;
  public isPANCBChecked: boolean = false;
  public ngbDatepicker: NgbDateStruct;
  public currencyCode: any;
  public currencyItems: any;
  public studentRemittance: any;
  public quotationCurrencyItem: QuotationCurrencyItem = new QuotationCurrencyItem();
  public singleRateResItem: SingleRateResItem = new SingleRateResItem();
  public requestQuotationModel: RequestQuotationModel = new RequestQuotationModel();
  public quotationResponse: any;
  public cryptoUtils: CryptoUtils = new CryptoUtils();
  public purposeListRemittance: any = [];
  public selectedPurposeItem: any;

  @ViewChild('selectedTab') selectedTab;
  public cartType: any;
  public stepperDataModel: StepperDataModel = new StepperDataModel();
  public showCardDetailsBox: boolean = false;
  public consumerDetail: any;
  public activeTab = 'SEND_MONEY';
  public deletedItem: any;

  public signature: any;
  public isProfileComplete: boolean = false;
  public isPANAvailable: boolean = false;

  public isVcipComplete: boolean = false;
  public resGetVcipStatus: any;
  public activeState = '';
  public configData: any;

  public menuItem;
  public mainMenuShow: boolean = true;
  public product: any;
  public ssoResponseData: any;
  @ViewChild('vcipCompleteOrNotModal') vcipCompleteOrNotModal: TemplateRef<any>;
  @ViewChild('PANDetailModal') PANDetailModal: TemplateRef<any>;
  @ViewChild('AmountLimitModal') AmountLimitModal: TemplateRef<any>;
  @ViewChild('errorModal') errorModal: TemplateRef<any>;
  public modalReference: any;
  public proceedWithoutVCIPCompletion = 'false';
  public productTypeList = [
    "FOREX CARD",
    "CURRENCY NOTES",
  ];
  public selectedProductType: String = "FOREX CARD";
  @Output() childCallback = new EventEmitter<string>();
  public cardLinkedAlready: boolean = false;
  public personalRemittance: any;
  public updateProfileModel = new UpdateProfileModel;
  public paxId: any;
  public formGroup: FormGroup;

  public inrRate = 0.0;
  public remittanceTotalAmount = 0.0;
  public forexcardTotalAmount = 0.0;
  public rateCalculatorResponse: any;
  public rateCalculatorAllResponse: any;
  showErrorModal: boolean;
  currencyCodeAmount: any;
  dobMaxDate: any;
  public parseID: any;
  panName: string;
  panVerificationErrMsg: string;
  isVCIPNow: boolean = false;
  constructor(
    private masterDataService: MasterDataService,
    public remittanceService: RemittanceService,
    private modalService: NgbModal,
    public toast: ToastrService,
    public router: Router,
    private cardService: CardService,
    public sosService: SsoService,
    public route: ActivatedRoute,
    public updateProfileService: UpdateProfileService,
    public formBuilder: FormBuilder
  ) {
    this.activeTab = 'SEND_MONEY';

    this.selectedPurposeItem = NewDashboardPurposeList.getPurposeObjectByRemittanceType('STUDENT');
    console.log(this.selectedPurposeItem);
    this.dobMaxDate = { year: new Date().getFullYear() - 13, month: new Date().getMonth() + 1, day: new Date().getDate() }

  }

  ngOnInit() {
    // this.quotationCurrencyItem.currencyCode = "USD";
    // this.quotationCurrencyItem.currencyAmount = "2000";
    this.menuItem = NewDashboardPurposeList.getDashboardMenu();
    console.log(this.menuItem);
    this.product = localStorage.getItem(ConstantsKey.PRODUCT);

    this.getCardDetails();
    this.getProfileDetails();
    this.getMenuItemFromConfig();
    this.getCurrencies();
    this.getMenu();
    this.convertAmount();

    if (localStorage.getItem(ConstantsKey.SSO_RESP)) {
      this.ssoResponseData = JSON.parse(localStorage.getItem(ConstantsKey.SSO_RESP));
      this.quotationCurrencyItem.currencyAmount = this.ssoResponseData['requiredCurrencyCodeAmount'];
      this.quotationCurrencyItem.currencyCode = this.ssoResponseData['requiredCurrencyCode'];
      console.log('SSO resp');

      this.getRateRange();
      this.convertAmount();
    }
     this.route.queryParams.subscribe(async params => {
      var getBase64Data = params['data'];
      this.parseID = atob(getBase64Data);
      this.sosService.getDataForSso(this.parseID).subscribe(resp => {
        var responseJSON = resp.body;
        console.log(JSON.stringify(responseJSON));

        this.product = responseJSON['product'];
        this.quotationCurrencyItem.currencyCode = responseJSON['requiredCurrencyCode'];
        this.quotationCurrencyItem.currencyAmount = responseJSON['requiredCurrencyCodeAmount'];
        localStorage.setItem(ConstantsKey.X_AUTH_TOKEN, responseJSON['xAuthToken']);
        localStorage.setItem(ConstantsKey.SSO_RESP,JSON.stringify(responseJSON));
        localStorage.setItem(ConstantsKey.PRODUCT, this.product);
        this.sosService.getConsumerProfileData().subscribe(resp => {
          this.showLoader = true;
          var respBody = JSON.parse(JSON.stringify(resp.body));
         localStorage.setItem(ConstantsKey.CONSUMER_ID, respBody.id);
         localStorage.setItem(ConstantsKey.PAXID, respBody.paxResponse.id);
         localStorage.setItem(ConstantsKey.CONSUMER_DATA, JSON.stringify(respBody));
         if(respBody?.paxResponse?.pan){
          localStorage.setItem(ConstantsKey.CONSUMER_PAN, respBody?.paxResponse?.pan);
        }
          
          console.log(respBody);
        }, error => {
          this.showLoader = false;
          //this.error = error.error.message;
        });
      }, error => {
        this.toast.error(error.error);
      });
    });

    localStorage.removeItem(ConstantsKey.PRODUCT);
    console.log(this.currencyItems);
    this.formGroup = this.formBuilder.group({
      cb: [false, Validators.requiredTrue],
      panNo: [Validators]
    });
  }
  alphaNumberOnly (e) {  // Accept only alpha numerics, not special characters 
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
        return true;
    }
    e.preventDefault();
    return false;
  }
  getMenuItemFromConfig() {
    let menuItemsLocal = [];
    // let data = sessionStorage.getItem('CONFIG_DATA');
    // this.configData = JSON.parse(data);

    this.configData={
      "remittanceFlowEnable": true,
      "personalFlowEnable": true,
      "cardFlowEnable": true,
      "insuranceEnable": true,
      "dashboardEnable": true,
      "myAccountMenuEnable": true,
      "myAccountSubMenuEnable": true,
      "relationshipEnable": true,
      "beneficiaryEnable": true,
      "bankAccountEnable": true,
      "ordersEnable": true,
      "pendingOrdersEnable": true,
      "rewardsEnable": true,
      "offersEnable": true,
      "branchNetworkEnable": true,
      "currencyConverterEnable": true,
      "resetPasswordEnable": true,
      "ratesEnable": true,
      "setRateAlertEnable": true,
      "supportEnable": true,
      "addressEnable": true,
      "faqEnable": true,
      "tutorialEnable": true,
      "paymentModeNBEnable": true,
      "paymentModeNEFTEnable": true,
      "partialPaymentEnable": true,
      "openGICAccountEnable": true,
      "blockedAccountEnable": true,
      "zolveCreditCardEnable": true,
      "isicCardEnable": true
  };
    this.menuItem.forEach(singleMenu => {
      if (singleMenu.key == 'TRAVELINSURANCE' && this.configData?.insuranceEnable) {
        menuItemsLocal.push(singleMenu);
      }
      if (singleMenu.key == 'FOREXCARD' && this.configData?.cardFlowEnable) {
        menuItemsLocal.push(singleMenu);
      }
      if (singleMenu.key == 'STUDENT' && this.configData?.remittanceFlowEnable) {
        menuItemsLocal.push(singleMenu);
      }
      if (singleMenu.key == 'OTHERS' && this.configData?.personalFlowEnable) {
        menuItemsLocal.push(singleMenu);
      }
      if (singleMenu.key == 'OPENGICACCOUNT' && this.configData?.openGICAccountEnable) {
        menuItemsLocal.push(singleMenu);
      }
      if (singleMenu.key == 'BLOCKEDACCOUNT' && this.configData?.blockedAccountEnable) {
        menuItemsLocal.push(singleMenu);
      }
      if (singleMenu.key == 'ZOLVEDCREDITCARD' && this.configData?.zolveCreditCardEnable) {
        menuItemsLocal.push(singleMenu);
      }
      if (singleMenu.key == 'ISICCARD' && this.configData?.isicCardEnable) {
        menuItemsLocal.push(singleMenu);
      }

    });
    this.menuItem = menuItemsLocal;
  }



  checkCardLink() {
    var requestModel = {
      silentValidation: "true"
    }
    this.cardService.linkCard(requestModel).subscribe((result => {
      localStorage.setItem(ConstantsKey.LINK_CARD_NAVIGATED, "true");
      if (result.body == null) {
        this.router.navigate(['/send-money-dashboard']);
      } else {
        var data = result.body
        if (data['msg'] != null && data['msg'] == 'true') {
          this.router.navigate(['/link-card']);
        } else {
          this.router.navigate(['/send-money-dashboard']);
        }
      }
    }), error => {
      this.router.navigate(['/send-money-dashboard']);
    });
  }

  getActiveTab(active) {
    this.activeTab = active;
    if (this.activeTab == 'SEND_MONEY') {
      if (this.menuItem.length == 3) {
        this.menuItem.splice(0, 0, this.deletedItem[0]);
      }
    } else if (this.activeTab == 'BUY_FOREX') {
      if (this.menuItem.length == 4) {
        this.deletedItem = this.menuItem.splice(this.menuItem.findIndex(item => item.key === 'STUDENT'), 1);
        console.log(this.deletedItem);
      }
    }
    this.convertAmount();
  }


  getCurrencies() {
    this.showLoader = true;
    this.reqGetCurrencyMaster.cartType = this.selectedPurposeItem.remittanceType;
    this.reqGetCurrencyMaster.countryCode = "US";
    this.reqGetCurrencyMaster.issuer = "";
    this.reqGetCurrencyMaster.partnerId = Constants.partnerId;
    this.reqGetCurrencyMaster.product = Constants.productsEnum[2];

    this.masterDataService.getCurrenciesList(this.reqGetCurrencyMaster).subscribe(result => {
      this.currencyItems = result.body;
      Constants.currenciesListGlobal = result.body;

    });
  }

  showMainMenuSection() {
    this.mainMenuShow = true;
  }

  getMenu() {
    this.studentRemittance = NewDashboardPurposeList.getStudentSubMenu();
    this.personalRemittance = NewDashboardPurposeList.getPersonalSubMenu();
  }

  openModalForVCIP() {
    this.modalReference = this.modalService.open(this.vcipCompleteOrNotModal, { centered: true });
  }

  getRateRange() {
    let productEnum: any;
    if (this.product == 'SEND-MONEY') {
      productEnum = Constants.productsEnum[2]
    } else if (this.product == 'BUY-FOREX') {
      productEnum = Constants.productsEnum[0];
    } else {
      productEnum = Constants.productsEnum[1];
    }
    this.showLoader = true;
    this.masterDataService.getRateRange(
      productEnum,
      this.quotationCurrencyItem.currencyCode,
      Constants.sellTypes[0]
    ).subscribe(resp => {
      this.showLoader = false;
      this.singleRateResItem = JSON.parse(JSON.stringify(resp.body));
      console.log(this.singleRateResItem);

    }, error => {
      this.showLoader = false;
    });
  }


  convertAmount() {
    let sellType;
    if (this.activeTab == 'SEND_MONEY') {
      sellType = Constants.sellTypes[0];
    } else if (this.activeTab == 'BUY_FOREX') {
      sellType = Constants.sellTypes[0];
    } else {
      sellType = Constants.sellTypes[1];
    }

    let currencyAmount = this.quotationCurrencyItem.currencyAmount;
    let currencyCode = this.quotationCurrencyItem.currencyCode;

    if (currencyAmount) {
      this.masterDataService.rateCalculatorConvertor(currencyAmount, currencyCode, sellType, Constants.productsEnum[2]).subscribe(resp => {
        let rateResponse = resp.body;
        if (this.activeTab == 'SEND_MONEY') {
          this.rateCalculatorResponse = rateResponse["remittanceRateResponse"];
        } else if (this.activeTab == 'BUY_FOREX') {
          if (this.selectedProductType == this.productTypeList[0]) {
            this.rateCalculatorResponse = rateResponse["cardRateResponse"];
          } else {
            this.rateCalculatorResponse = rateResponse["cashRateResponse"];
          }
          this.rateCalculatorAllResponse = rateResponse;
        } else {
          if (this.selectedProductType == this.productTypeList[0]) {
            this.rateCalculatorResponse = rateResponse["cardRateResponse"];
          } else {
            this.rateCalculatorResponse = rateResponse["cashRateResponse"];
          }
          this.rateCalculatorAllResponse = rateResponse;
        }
        console.log("Rate calculator");
        console.log(this.rateCalculatorResponse);
      }, err => {
        console.log(err);
      });
    } else {
      this.rateCalculatorResponse = {};
    }


  }

  enterCurrencyAmount() {
    let sellType;
    if (this.activeTab == 'SEND_MONEY') {
      sellType = Constants.sellTypes[0];
    } else if (this.activeTab == 'BUY_FOREX') {
      sellType = Constants.sellTypes[0];
    } else {
      sellType = Constants.sellTypes[1];
    }

    let currencyAmount = this.quotationCurrencyItem.currencyAmount;
    let currencyCode = this.quotationCurrencyItem.currencyCode;

    if (currencyAmount) {
      this.masterDataService.rateCalculatorConvertor(currencyAmount, currencyCode, sellType,Constants.productsEnum[2]).subscribe(resp => {
        let rateResponse = resp.body;
        this.showErrorModal = false;
        if (this.activeTab == 'SEND_MONEY') {
          this.rateCalculatorResponse = rateResponse["remittanceRateResponse"];
        } else if (this.activeTab == 'BUY_FOREX') {
          if (this.selectedProductType == this.productTypeList[0]) {
            this.rateCalculatorResponse = rateResponse["cardRateResponse"];
            //sessionStorage.setItem(ConstantsKey.SS_CARD_RATE_CALCULATOR, JSON.stringify(this.rateCalculatorResponse));

          } else {
            this.rateCalculatorResponse = rateResponse["cashRateResponse"];
          }
          this.rateCalculatorAllResponse = rateResponse;
        } else {
          if (this.selectedProductType == this.productTypeList[0]) {
            this.rateCalculatorResponse = rateResponse["cardRateResponse"];
          } else {
            this.rateCalculatorResponse = rateResponse["cashRateResponse"];
          }
          this.rateCalculatorAllResponse = rateResponse;
        }
        console.log("Rate calculator");
        console.log(this.rateCalculatorResponse);
      }, err => {
        console.log(err);
        if(err.error.message == 'Buying more than 25000$ or equivalent is not allowed for consumer.') {
          this.showErrorModal = true;
              Swal.fire({
              title:"Dear Customer", 
              html: err.error.message, 
              imageUrl: "assets/images/icons/info_icon.png",
              imageWidth: 80,
              imageHeight: 80,
              focusConfirm: false,
              confirmButtonColor: '#1D2A62'
            });
         // this.modalReference = this.modalService.open(this.AmountLimitModal, { centered: true, size: 'sm' });
        }
      });
    } else {
      this.rateCalculatorResponse = {};
    }
  }

  getQuotationRequestBody() {
    let listQuotationCurrencies: Array<QuotationCurrencyItem> = [];
    /// prepare quotation request
    this.quotationCurrencyItem.product = Constants.productsEnum[2];
    this.quotationCurrencyItem.bidRate = this.rateCalculatorResponse["inrRate"];//this.singleRateResItem.minimumRateInINR;
    this.quotationCurrencyItem.purpose = this.selectedPurposeItem?.purposeCode;
    // this.quotationCurrencyItem.purpose = (this.selectedPurposeItem?.purposeCode)
    //   ? this.selectedPurposeItem.purposeCode
    //   : "UNIVERSITY_FEES";

    listQuotationCurrencies.push(this.quotationCurrencyItem);

    this.requestQuotationModel.sellType = Constants.sellTypes[0];
    this.requestQuotationModel.partnerId = Constants.partnerId;
    this.requestQuotationModel.nostroChargeType = 'REMITTER';
    this.requestQuotationModel.quotationCurrencies = listQuotationCurrencies;
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  proceedToStepper() {
    if(!this.showErrorModal) {
    if (this.selectedPurposeItem.length >= 0) {
      // this.toast.error('Please select purpose !');
      this.toast.error('Oops! It looks like you forgot to choose the purpose. Please pick one to get started.');
    } else if (this.selectedPurposeItem.key == 'GIC') {
      if (this.quotationCurrencyItem.currencyCode != 'CAD') {
        this.toast.error('Please select currency as CAD to proceed with GIC!');
      } else {
        this.navigateToNext();
      }
    } else if(this.selectedPurposeItem.key == 'BlockAccount'){
      if (this.quotationCurrencyItem.currencyCode != 'EUR') {
        this.toast.error('Please select currency as EUR to proceed with Blocked Account!');
      } else {
        this.navigateToNext();
      }
    } else {
      this.navigateToNext();
    }
    } else {
      this.modalReference = this.modalService.open(this.AmountLimitModal, { centered: true, size: 'sm' });
    }
  }

  navigateToNext(){
    if (this.selectedPurposeItem && this.quotationCurrencyItem.currencyAmount) {
      this.showLoader = true;

      this.cartType = this.selectedPurposeItem.remittanceType;

      this.remittanceService.cartPrepare(this.cartType).subscribe(result => {
        this.getQuotationRequestBody();
        this.remittanceService.getQuotationEncrypted(this.requestQuotationModel).subscribe(resp => {

        }, error => {

          this.showLoader = false;
          if (error.status==200) {
             ///store active order in session storage
          this.stepperDataModel.purposeItem = this.selectedPurposeItem;
          this.stepperDataModel.quotationRequest = this.requestQuotationModel;
          this.stepperDataModel.quotationResponse = JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));
          sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER, JSON.stringify(this.stepperDataModel));
          this.showLoader = false;

          if (this.stepperDataModel.purposeItem.remittanceType == "OTHERS") {
            this.router.navigate(['/stepper3step'])
          } else {
            this.router.navigate(['/stepper'])
          }
          sessionStorage.setItem(ConstantsKey.IS_FROM_REMITTANCE_FLOW, 'true');
          sessionStorage.setItem(ConstantsKey.IS_FROM_RELOAD, 'false');
          sessionStorage.setItem(ConstantsKey.IS_FROM_BUYNEWCARD, 'false');
          } else {
                this.toast.error(JSON.stringify(error.error.message));
          }
        });
      }, error => {
        this.toast.error(error.error.message);
      });
    } else {
      var errorMessage = 'Please enter amount';
      if (!this.selectedPurposeItem) {
        errorMessage = "Please select purpose";
      }
      this.toast.error(errorMessage);
    }
  }

  navigateToNextPage(pageUrl) {
    if (pageUrl) {
      this.router.navigate([pageUrl]);
    }
  }

  getCardDetails() {
    this.showLoaderForGetCardDetails = true;
    this.cardService.getCardDetails().subscribe((respCardData => {
      var responseBody = JSON.parse(JSON.stringify(respCardData.body));
      if (responseBody.length > 0) {
        this.cardLinkedAlready = true;
        this.showCardDetailsBox = false;
        this.showLoaderForGetCardDetails = false;
      } else {
        this.cardLinkedAlready = false;
        // if (localStorage.getItem(ConstantsKey.LINK_CARD_NAVIGATED) != "true") {
        //   this.checkCardLink();
        // }
        this.showCardDetailsBox = true;
        this.showLoaderForGetCardDetails = false;
      }

    }), error => {
      this.cardLinkedAlready = false;
      this.showLoaderForGetCardDetails = false;
      this.showCardDetailsBox = true;
      if (localStorage.getItem(ConstantsKey.LINK_CARD_NAVIGATED) != "true") {
        this.checkCardLink();
      }
      //this.toast.error(error.error.message);
    });;
  }

  getProfileDetails() {
    this.updateProfileService.getProfileListAll().subscribe(resp => {
      this.showLoader = false;
      this.consumerDetail = resp.body;
      var respBody = JSON.parse(JSON.stringify(resp.body));
      localStorage.setItem(ConstantsKey.CONSUMER_DATA, JSON.stringify(respBody));

      this.paxId = this.consumerDetail.paxResponse.id;
      this.isProfileComplete = this.consumerDetail.paxResponse?.profileComplete;
      this.isVcipComplete = this.consumerDetail?.vcipKyc;
      if (this.consumerDetail?.paxResponse?.consumer?.vcipSkipAllowed) {
        this.isVcipComplete = true;
      } else if (this.consumerDetail?.paxResponse?.consumer?.offlineonboarding == '1') {
        this.isVcipComplete = true;
      }
      if (!this.isVcipComplete) {
        this.getVCIPStatus();
      } else {
        localStorage.setItem(ConstantsKey.IS_VCIP_COMPLETE, this.isVcipComplete.toString());
      }
      if(this.consumerDetail?.paxResponse!=null && this.consumerDetail?.paxResponse?.pan){
        this.isPANAvailable=true;
      }
      this.signature = this.consumerDetail.signature;

      if (this.signature == undefined) {
        this.isProfileComplete = false;
      }

      localStorage.setItem(ConstantsKey.CONSUMER_SIGNATURE, this.signature);
    }, error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }
  async getVCIPStatus() {
    this.updateProfileService.getVCIPStaus().subscribe((resp => {
      this.showLoader = false;
      this.resGetVcipStatus = resp.body;
      if (this.resGetVcipStatus.vcipStatus == 'ACCEPTED') {
        this.isVcipComplete = true;
      }
      localStorage.setItem(ConstantsKey.IS_VCIP_COMPLETE, this.isVcipComplete.toString());

    }), error => {
      this.toast.error(error.error.message);
    });
    return true;
  }

  initiateVcip() {
    this.modalReference.close();
    this.updateProfileService.getVCIPRedirectionURL().subscribe((resp => {
      this.showLoader = false;
      var vcipResponse = JSON.parse(JSON.stringify(resp.body));
      let vcipUrl = vcipResponse.mobileAutologinUrl;
      window.open(vcipUrl, '_blank');
    }), error => {
      this.showLoader = false;
      this.toast.error(JSON.stringify(error.error.message));
    });
  }

  proceedWithourVCIP() {
    this.modalReference.close();
    // this.proceedWithoutVCIPCompletion = true;
    if(this.isPANAvailable){
      sessionStorage.setItem('PROCEED-WITHOUT-VCIP', 'true');
    }else{
      this.openModalForPANNo();
    }
  }

  showToastMesage() {
    this.toast.error('Please select the product');
  }

  openModalForPANNo() {
    this.modalReference.close();
    this.modalReference = this.modalService.open(this.PANDetailModal, { centered: true, size: 'sm' });
  }
  validatePanDetails(){
    if(!(this.updateProfileModel.pan!=null )){
      this.toast.error('Please enter your PAN');
      return false;
    }
    if(!this.validatePANNo(this.updateProfileModel.pan)) {
      this.toast.error('Please enter valid PAN');
      return false;      
    }
    if(!(this.updateProfileModel.name!=null )){
      this.toast.error('Please enter name as per PAN');
      return false;
    } 
    if(!this.isPANCBChecked){
      this.toast.error('Please check the declaration of PAN');
      return false;
    }
    if(!(this.updateProfileModel.dob!=null )){
      this.toast.error('Please select your Date of Birth ');
      return false;
    }
    
    
      return true;
  }
  openErrorModal(){
    this.modalReference = this.modalService.open(this.errorModal, { centered: true, size: 'sm' });

  }
  validatePanAPI(){

    if(this.validatePanDetails()) {
    this.showLoader = true;
    let dateofBirth = DateUtils.getNgbDateStructToDatev2(this.updateProfileModel.dob);

    this.updateProfileService.validatePAN(this.updateProfileModel.pan,this.updateProfileModel.name,dateofBirth).subscribe(async resp => {
      console.log(resp.body);
      var panResponse = JSON.parse(JSON.stringify(resp.body));
      this.showLoader = false;
      if(panResponse.panStatus=='E' && panResponse.fullName!="" && panResponse.dob!=""){
        this.updatePAN();
      }else if(panResponse.panStatus!='E'){
        this.panVerificationErrMsg="Please enter valid PAN";
        this.openErrorModal();
      }else if(panResponse.fullName==""){
        this.panVerificationErrMsg="Please enter Name as per PAN";
        this.openErrorModal();
      }
      else if(panResponse.dob==""){
        this.panVerificationErrMsg="Please enter valid Date of Birth";
        this.openErrorModal();
      }
    }, error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    }); 
  }
  }
  getPanName() {

    this.updateProfileModel.pan = this.updateProfileModel.pan.toUpperCase();
    if (this.updateProfileModel.pan && this.updateProfileModel.pan.length == 10 && this.validatePANNo(this.updateProfileModel.pan)) {
      this.showLoader = true;
      this.updateProfileService.getPANName(this.updateProfileModel.pan).subscribe(async resp => {
        console.log(resp.body);
        var panResponse = JSON.parse(JSON.stringify(resp.body));
        this.showLoader = false;
        if(panResponse.panStatus == 'E') {
          if (panResponse.fullName && panResponse.fullName != 'null' && panResponse.fullName != '') {
            this.updateProfileModel.name = panResponse.fullName;
          } else {
            this.updateProfileModel.name = '';
          }
  
          if(panResponse.dob && panResponse.dob != 'null' && panResponse.dob != '') {
            this.updateProfileModel.dob = DateUtils.ngbDateStructDateConverter(panResponse.dob);
          }
        } else if(panResponse.flatPan.status == "Record Not Found in ITD Database/Invalid PAN") {
          this.updateProfileModel.name = '';
          this.updateProfileModel.dob = ''; 
          this.toast.error("Invalid Pan");
        } /* else {
          this.toast.error(panResponse.flatPan.status);
        } */

      }, error => {
        this.showLoader = false;
        this.updateProfileModel.name = '';
        // this.updateProfileModel.dob = '';
        this.toast.error(error.error.message);
      });
    }
  }

  updatePAN() {
    this.showLoader = true;
    // var consumerDataLocal = localStorage.getItem(ConstantsKey.CONSUMER_DATA);
    // var consumerData = JSON.parse(consumerDataLocal);
    this.updateProfileModel.mobile = this.consumerDetail.mobile;
    this.updateProfileModel.email = this.consumerDetail.email;
   let dateofBirth = DateUtils.getNgbDateStructToDate(this.updateProfileModel.dob);
    this.updateProfileModel.dob=dateofBirth;
    console.log("pax model>>>> ", JSON.stringify(this.updateProfileModel));
    this.updateProfileService.updateProfileDetails(this.paxId, this.updateProfileModel).subscribe(async resp => {
      this.showLoader = false;
      sessionStorage.setItem('PROCEED-WITHOUT-VCIP', 'true');
      this.modalReference.close();
    }, error => {
      this.showLoader = false;
      if (error.status == 200) {
        sessionStorage.setItem('PROCEED-WITHOUT-VCIP', 'true');
        this.modalReference.close();
        if(this.isVCIPNow) {
          this.initiateVcip();
        }        
        this.proceedToStepper();
      } else {
        this.modalReference.close();

        this.toast.error('Something went wrong ! Please try again !');
      }
    });
  }



  getActiveSectionTitle(singleMenu) {
    console.log(singleMenu);
    if(this.quotationCurrencyItem.currencyAmount < 25000) {
    sessionStorage.setItem('SELECTED_REMITTANCE_TYPE', singleMenu.remittanceType);
    this.proceedWithoutVCIPCompletion = sessionStorage.getItem('PROCEED-WITHOUT-VCIP');
    this.selectedPurposeItem = singleMenu;
    if ((!this.isVcipComplete && this.proceedWithoutVCIPCompletion == null) && !this.cardLinkedAlready) {
      this.openModalForVCIP();
    } else {
      this.proceedToStepper();
    }
  } else {
    this.modalReference = this.modalService.open(this.AmountLimitModal, { centered: true, size: 'sm' });
  }
  }
  
  VCIPNow() {
    this.isVCIPNow = true;
    this.openModalForPANNo();
  }

  validatePANNo(panNo) {
    const regex = /^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/;
    if (!regex.test(panNo)) {
      return false;
    } else {
      return true;
    }
  }  
}
