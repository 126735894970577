import { Component, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreCommonModule } from '@core/common.module';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { BuyNewCardComponent } from './view/buy-card/buy-new-card/buy-new-card.component';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ViewCartComponent } from './view/buy-card/view-cart/view-cart.component';
import { CardStepperComponent } from './view/buy-card/card-stepper/card-stepper.component';
import { CardStepperFooterComponent } from './view/buy-card/card-stepper-footer/card-stepper-footer.component';
import { TripInformationComponent } from './view/buy-card/1-trip-information/trip-information.component';
import { TravellerDetailsComponent } from './view/buy-card/2-traveller-details/traveller-details.component';
import { KYCComponent } from './view/buy-card/3-document/document.component';
import { A2FormComponent } from './view/buy-card/4-a2-form/a2-form.component';
import { PaymentComponent } from './view/buy-card/5-payment/payment.component';
import { LinkExistingCardComponent } from './view/link-existing-card/link-existing-card.component';
import { OrderSuccessComponent } from './view/order-success/order-success.component';
import { CardService } from './services/card.service';
import { ManageCardComponent } from './view/manage-card/manage-card.component';
import { MyCardComponent } from './view/card-dashboard/my-card.component';
import { ValidateCardDetailsComponent } from './view/validate-card-details/validate-card-details';
import { LoaderRoutingModule } from 'app/shared/utility/utility-routing.module';
import { CashoutComponent } from './view/cashout/cashout.component';
import { TransferBalanceComponent } from './view/transfer-balance/transfer-balance.component';
import { NouisliderModule } from 'ng2-nouislider';
import { SetDailyLimitComponent } from './view/manage-card/set-daily-limit/set-daily-limit.component';
import { CommonModule, DatePipe } from '@angular/common';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { AddBankAccountForCardComponent } from './view/buy-card/add-bank-account-card/add-bank-account-card.component';
import { UpdateProfileService } from '../update-profile/services/update-profile.service';
import { StatementComponent } from './view/statement/statement.component';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { RaiseDisputeTicketComponent } from './view/raise-dispute-ticket/raise-dispute-ticket.component';
import { PassportDataFormComponent } from './view/passport-data-form/passport-data-form.component';
import { RemittanceRoutingModule } from '../remittance/remittance-routing.module';
import { TransferToINRWalletComponent } from './view/transfer-to-inr-wallet/transfer-to-inr-wallet.component';
import { AddAddressCardComponent } from './view/buy-card/add-address-card/add-address-card.component';
import { EncashmentComponent } from './view/encashment/encashment.component';
import { LinkCardComponent } from './view/link-card/link-card.component';
import { ActivateINRWallet } from './view/activate-inr-wallet/activate-inr-wallet.component';
import { MyFeaturesComponent } from './view/features/my-features.component';
import { MyFaqsComponent} from './view/faqs/my-faqs.component';
import { NewCardComponent} from './view/newcard/newcard.component';
import { NewFeaturesComponent} from './view/newfeatures/newfeatures.component';
import { EsimComponent} from './view/esim/esim.component';
import { LinkEsimCardComponent} from './view/link-esim-card/link-esim-card.component';
import { InternationalEsimComponent} from './view/international-esim/international-esim.component';
import { LinkInternationalCardComponent} from './view/link-international-card/link-international-card.component';
import { LoungeAccessComponent} from './view/lounge-access/lounge-access.component';
import { StudentFaqComponent } from './view/student-faq/student-faq.component';




const routes = [
  {
    path: 'buy-new-card',
    component: BuyNewCardComponent,
    data: { animation: 'BuyNewCardComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'view-cart',
    component: ViewCartComponent,
    data: { animation: 'ViewCartComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'card-stepper',
    component: CardStepperComponent,
    data: { animation: 'CardStepperComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'link-existing-card',
    component: LinkExistingCardComponent,
    data: { animation: 'LinkExistingCardComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'order-success',
    component: OrderSuccessComponent,
    data: { animation: 'OrderSuccessComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'my-card',
    component: MyCardComponent,
    data: { animation: 'MyCardComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'activate-inr-wallet',
    component: ActivateINRWallet,
    data: { animation: 'ActivateINRWallet' },
    canActivate: [AuthGuard]
  },
  {
    path: 'manage-card',
    component: ManageCardComponent,
    data: { animation: 'MyCardComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'validate-card-details',
    component: ValidateCardDetailsComponent,
    data: { animation: 'ValidateCardDetailsComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'cashout',
    component: CashoutComponent,
    data: { animation: 'CashoutComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'transfer-balance',
    component: TransferBalanceComponent,
    data: { animation: 'TransferBalanceComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'set-daily-limit',
    component: SetDailyLimitComponent,
    data: { animation: 'SetDailyLimitComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'add-bank-account-for-card',
    component: AddBankAccountForCardComponent,
    data: { animation: 'AddBankAccountForCardComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'statement',
    component: StatementComponent,
    data: { animation: 'StatementComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'raise-dispute-ticket',
    component: RaiseDisputeTicketComponent,
    data: { animation: 'RaiseDisputeTicketComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'passport-data-form',
    component: PassportDataFormComponent,
    data: { animation: 'PassportDataFormComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'cashout-to-inr-wallet',
    component: TransferToINRWalletComponent,
    data: { animation: 'TransferToINRWalletComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'encashment',
    component: EncashmentComponent,
    data: { animation: 'EncashmentComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'link-card',
    component: LinkCardComponent,
    data: { animation: 'LinkCardComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'my-features',
    component: MyFeaturesComponent,
    data: { animation: 'MyFeaturesComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'my-faqs',
    component: MyFaqsComponent,
    data: { animation: 'MyFaqsComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'newcard',
    component: NewCardComponent,
    data: { animation: 'NewCardComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'newfeatures',
    component: NewFeaturesComponent,
    data: { animation: 'NewFeaturesComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'esim',
    component: EsimComponent,
    data: { animation: 'EsimComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'link-student-card',
    component: LinkEsimCardComponent,
    data: { animation: 'LinkEsimCardComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'international-esim',
    component: InternationalEsimComponent,
    data: { animation: 'InternationalEsimComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'link-international-card',
    component: LinkInternationalCardComponent,
    data: { animation: 'LinkInternationalCardComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'lounge-access',
    component: LoungeAccessComponent,
    data: { animation: 'LoungeAccessComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'student-card-faq',
    component: StudentFaqComponent,
    canActivate: [AuthGuard]
  }
 


 
];

@NgModule({
  declarations: [
    BuyNewCardComponent,
    ViewCartComponent,
    CardStepperComponent,
    TripInformationComponent,
    TravellerDetailsComponent,
    KYCComponent,
    A2FormComponent,
    PaymentComponent,
    CardStepperFooterComponent,
    LinkExistingCardComponent,
    OrderSuccessComponent,
    MyCardComponent,
    ActivateINRWallet,
    ManageCardComponent,
    ValidateCardDetailsComponent,
    CashoutComponent,
    TransferBalanceComponent,
    SetDailyLimitComponent,
    AddBankAccountForCardComponent,
    StatementComponent,
    RaiseDisputeTicketComponent,
    PassportDataFormComponent,
    TransferToINRWalletComponent,
    AddAddressCardComponent,
    EncashmentComponent,
    MyFeaturesComponent,
    MyFaqsComponent,
    NewCardComponent,
    NewFeaturesComponent,
    EsimComponent,
    LinkEsimCardComponent,
    InternationalEsimComponent,
    LinkInternationalCardComponent,
    LoungeAccessComponent,
    StudentFaqComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    CoreCommonModule,
    FormsModule,
    NgSelectModule,
    NgbTooltipModule,
    NgbModule,
    LoaderRoutingModule,
    CommonModule,
    CardSnippetModule,
    NouisliderModule
  ],
  exports: [
    BuyNewCardComponent,
    ViewCartComponent,
    CardStepperComponent,
    TripInformationComponent,
    TravellerDetailsComponent,
    KYCComponent,
    A2FormComponent,
    PaymentComponent,
    CardStepperFooterComponent,
    LinkExistingCardComponent,
    OrderSuccessComponent,
    MyCardComponent,
    ActivateINRWallet,
    ValidateCardDetailsComponent,
    CashoutComponent,
    TransferBalanceComponent,
    SetDailyLimitComponent,
    AddBankAccountForCardComponent,
    StatementComponent,
    RaiseDisputeTicketComponent,
    PassportDataFormComponent,
    AddAddressCardComponent,
    EncashmentComponent,
    MyFeaturesComponent,
    MyFaqsComponent,
    NewCardComponent,
    NewFeaturesComponent,
    EsimComponent,
    LinkEsimCardComponent,
    InternationalEsimComponent,
    LinkInternationalCardComponent,
    LoungeAccessComponent,
  
  ],
  providers: [CardService, UpdateProfileService, DatePipe]
})
export class CardRoutingModule { }
