<!--<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section class="app-ecommerce-details">
            <div class="card">
                <div class="row my-2 text-center">
                    <div class="col-12 col-md-12 mb-2 mb-md-0">
                        <h4 class="fs-18 fw-600 mb-20" style="color: black"> Free ISIC Card </h4>
                        <div class="d-flex col-md-4 offset-md-4">
                            <img src="assets/images/icons/CardFront.png" class="img-fluid product-img" style="margin:auto;display: block;" />
                        </div>
                    </div>
                    <div class="col-12 col-md-10 offset-md-1">

                        <p class="card-text fs-11 mt-20" style="color: black">
                            WSFx Gloal Pay provides you with an annual membership of a complimentary virtual <span class="fw-600">ISIC card</span> in association with <span class="fw-600">International Student Identity Card (ISIC)</span> when you transact with
                            WSFx Global Pay.With this highly beneficial card, you can save money and make the most of your study abroad experience.
                        </p>

                        <p class="card-text fs-11" style="color: black">
                            Get 1,50,000+ discounts on food, travel, accommodation, entertainment and more in 125+ countries. Transact with us today to receive your complimentary Virtual ISIC card on behalf of WSFx Global Pay.
                        </p>
                        <p class="card-text fs-11" style="color: black">
                            For Terms and Conditions and other details visit- <a style="color: #3CC9EA;" href="https://www.isic.org/" target="_blank">https://www.isic.org</a>
                        </p>


                        

                        <div class="pt-1">
                            <button type="button" rippleEffect class="btn btn-primary mb-20 fs-12" routerLink="/isicCardApplication">Apply Now</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>-->

<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-md-6 ">
                        <div class="d-none d-lg-block">
                        <h6 class=" float-left mb-0 ml-10 fs-18 fw-700" style="color: #1D2A62;margin-left: 20px;">
                            International Student Identity Card</h6>
                            </div>
                        <div class="col-11 col-md-6 ">
                            <img src="assets/images/icons/isiccard.png" class="isic-img" />
                        </div>
                        <div class="col-8 col-md-6">
                            <div class="isic-text">
                                Load Your Student Pay Card with $3,000 or more to become eligible for an ISIC card.
                            </div>
                        </div>
                        <div class="col-6 col-md-6">
                            <div class="isic-text1">
                                <p> WSFX Global Pay provides you with an annual membership of a complimentary virtual
                                    ISIC card in association with International Student Identity Card (ISIC) when you
                                    transact with WSFX Global Pay. With this highly beneficial card, you can save money
                                    and make the most of your study abroad experience. Get 1,50,000+ discounts on food,
                                    travel, accommodation, entertainment and more in 125+ countries. Transact with us
                                    today to receive your complimentary Virtual ISIC card on behalf of WSFX Global Pay.
                                </p>
                                <p style="margin-top: -10px;">For Terms and Conditions and other details visit-<span
                                        style="color: #53C9EA;">https://www.isic.org/</span></p>
                            </div>
                        </div>
                        <div class="col-md-6 col-10 mt-20">
                            <button type="submit" rippleEffect class="btn-isic fs-10 w-80" routerLink="/link-isic-card">
                                Apply Now
                            </button>
                        </div>

                    </div>
                      
                  
                    <div class="isic-border dash-isic">
                        <div class="col-md-12">
                            <ul ngbNav #nav="ngbNav" 
                                class="nav nav-pills nav-justified justify-content-center nav-fill mb-0" 
                                style="display: flex; flex-wrap: wrap;">
                                
                                <li class="nav-item" ngbNavItem style="flex: 1 1 50%; max-width: 50%;">
                                    <a ngbNavLink class="isic-link fs-12" href="" data-toggle="tab" (click)="setActiveTab(0)"
                                    style="border: 1.2px solid #1D2A62; padding: 15px; border-radius: 0; width: 115%;">
                                    Features
                                    </a>
                                    <div *ngIf="activeTab === 0" class="row mt-6 isic-row">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mt-1"
                                            *ngFor="let singleItem of menuItems" style="margin-bottom: -2rem;">
                                            <div class="d-flex flex-row align-items-center mb-3">
                                                <img src="assets/images/isiccard/{{singleItem.icon}}"
                                                    style="width: 40px; height: 40px; margin-right: 15px;">
                                                <div class="fs-11 fw-600 mb-50"
                                                    style="color: #000; text-align: left; white-space: nowrap;">
                                                    {{ singleItem.title }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                
                                <li class="nav-item" ngbNavItem style="flex: 1 1 50%; max-width: 50%;">
                                    <a ngbNavLink class="isic-link fs-12" href="" (click)="setActiveTab(1)"
                                    style="width: 107%; border: 1.2px solid #1D2A62; padding: 15px; margin-left: 0px; border-radius: 0; width: 107%;">
                                    Specifications
                                    </a>
                                    <div *ngIf="activeTab === 1" class="row mt-6 isic-specifications-row"
                                        style="margin-left :-130px;">
                                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2"
                                            *ngFor="let singleItem of specItems"
                                            style="margin-bottom: -2rem; margin-top: 5px;">
                                            <div class="d-flex flex-row align-items-center" style="height: 100%;">
                                                <img src="assets/images/isiccard/{{singleItem.icon}}"
                                                    style="width: 40px; height: 40px; margin-right: 15px; margin-bottom: 50px;">
                                                <div class="fs-11 fw-600"
                                                    style="color: #000; text-align: left; margin-bottom: 50px; white-space: nowrap;">
                                                    {{ singleItem.title }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-6 text-left ">
                        <button  class="btn btn-isic1" (click)="goToNewFeatures()">
                            Back
                        </button>
                        </div>
                   
                    
                </div>
            </div>
        </div>
    </div>
</div>