<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <!-- <loader *ngIf="showLoader"></loader> -->
            <div class="card-body" style="margin-bottom:10px">
                <div class="row">
                    <div class="col-md-6 mb-20">
                        <h6 class=" float-left mb-0 ml-10 fs-18 fw-700" style="color: #1D2A62">FAQ</h6>
                    </div>
                </div>

                <div class="row">
                    
                    <div class="accordion collapse-margin mt-1 w-100" *ngFor="let faq of faqList">
                        <div class="tab-pane collapse-icon" id="faq-delivery" role="tabpanel" aria-labelledby="delivery" aria-expanded="false">
                            <ng-container>
                                <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                    <ngb-panel  [cardClass]="'collapse-margin'">
                                        <ng-template ngbPanelTitle>
                                            <span  class="text-black fw-600 fs-16">{{faq.srNo}}. {{faq.topic}}</span>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <div style="white-space: pre-line;" [innerHTML]="faq.description  | safe: 'HTML'"><!--  {{ | Safe: 'HTML'}} -->
                                            </div>
                                        </ng-template>
                                    </ngb-panel>
                                </ngb-accordion>
                            </ng-container>
                        </div>
                    </div>



                </div>
            </div>
        </div>
    </div>
</div>