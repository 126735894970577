import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseProvider } from "app/shared/helper/http/BaseProvider";

@Injectable()
export class ISICCardService extends BaseProvider {
  
  constructor(public http: HttpClient) {
    super(http);
  }
 
  isicRegister(requestModel){
    // var resourceUrl = "isicRegister";
    var resourceUrl = "requestIsicCard";
    return this.makePostCall(resourceUrl, requestModel);
  }

  isicDocumentUpload(imageData){
    var resourceUrl = "isicDocumentUpload";
    return this.makeUploadCall(resourceUrl, imageData);
  }

  isicApplicationStatus() {
    var resourceUrl = "isicViewCard";
    return this.makePostCall(resourceUrl, {});    
  }

  uploadPhotoForIsicCard(imageData) {
    var resourceUrl = "isicSetPhoto";
    return this.makeUploadCall(resourceUrl, imageData);
  }
  


}