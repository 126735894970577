
import { Injectable } from "@angular/core";

@Injectable()
export class ConstantsKey {
    public static X_AUTH_TOKEN = "X-AUTH-TOKEN";
    public static CONSUMER_DATA = "CONSUMER-DATA";
    public static CONSUMER_ID = "CONSUMER-ID";
    public static PAXID = "PAXID";
    public static OTP_LOGIN_RESPONSE = 'OTP_LOGIN_RESPONSE';
    public static LOGIN_USER_INFO = "LOGIN_USER_INFO";
    public static AGENT_ID = "AGENT-ID";
    public static AGENT_THEME = "AGENT-THEME";
    public static AGENT_KEY = "AGENT-KEY";

    public static BENEFICIARY_ORDER_DATA = "BENEFICIARY-ORDER-DATA";

    public static SS_ACTIVE_ORDER = "SESSION_STORAGE_ACTIVE_ORDER";
    public static SS_ACTIVE_ORDER_CARD = "SESSION_STORAGE_ACTIVE_ORDER_CARD";
    public static CARD_ADD_BALANCE_CURRENCY = "CARD_ADD_BALANCE_CURRENCY";
    public static SS_CARD_RATE_CALCULATOR = "SESSION_STORAGE_CARD_RATE_CALCULATOR";


    public static IS_FROM_EMAIL_LINK = "IS-FROM-EMAIL-LINK";
    public static EMAIL_LINK_DATA = "EMAIL-LINK-DATA";

    public static SS_ORDER_DATA = "SS-ORDER-DATA";
    public static BENE_ID = "BENE_ID";
    public static CONSUMER_SIGNATURE = "CONSUMER_SIGNATURE";
    public static CONSUMER_PAN = "CONSUMER_PAN";
    public static INSURANCE_RESPONSE = "INSURANCE_RESPONSE";
    public static INSURANCE_REQUEST = "INSURANCE_REQUEST";
    public static BENEFICIARY_PURPOSE_CODE = "BENEFICIARY_PURPOSE_CODE";
    public static INSURANCE_TYPE = "INSURANCE_TYPE";
    public static INSURANCE_CART_DETAILS = "INSURANCE_CART_DETAILS";
    public static QUOTATION_REQUEST_FOR_ENCASHMENT = "QUOTATION_REQUEST_FOR_ENCASHMENT";
    public static QUOTATION_RESPONSE_FOR_ENCASHMENT = "QUOTATION_RESPONSE_FOR_ENCASHMENT";
    public static ORDER_REQUEST = "ORDER_REQUEST";
    public static LINK_CARD_NAVIGATED = "LINK_CARD_NAVIGATED";
    public static IS_VCIP_COMPLETE = "IS_VCIP_COMPLETE";

    public static SSO_RESP = 'SSO_RESP';
    public static PRODUCT = 'PRODUCT';

    public static IS_FROM_RELOAD = "IS_FROM_RELOAD";
    public static IS_FROM_BUYNEWCARD = "IS_FROM_BUYNEWCARD";
    public static CARD_TYPE = "CARD_TYPE";

    public static PAYMENT_METHOD = 'PAYMENT_METHOD';
    public static IS_FROM_REMITTANCE_FLOW = "IS_FROM_REMITTANCE_FLOW";
    public static IS_FROM_RETRY_PAYMENT = "IS_FROM_RETRY_PAYMENT";
    

    public static MEDICAL_TREATMENT_ABROAD = 'MEDICAL_TREATMENT_ABROAD';
    public static EMIGRATION = 'EMIGRATION';
    public static OVERSEAS_EDUCATION = 'OVERSEAS_EDUCATION';
    public static PRIVATE_VISIT = 'PRIVATE_VISIT';
    public static SELECTED_CART_TYPE = 'SELECTED_CART_TYPE';
    public static STUDENT_CARD_DATA = 'STUDENT_CARD_DATA';
    public static STUDENT_CARD_APPLY = 'STUDENT_CARD_APPLY';
    public static ESIM_SELECTED_PLAN = 'ESIM_SELECTED_PLAN';
    public static ISIC_STATUS_DATA = 'ISIC_STATUS_DATA';
    public static MY_CARD_CHOOSE = 'MY_CARD_CHOOSE';
    public static STUDENT_CASHOUT_DATA = 'STUDENT_CASHOUT_DATA';
    public static MANAGE_CARD_STATUS = 'MANAGE_CARD_STATUS';
    public static IS_STUDENT_PAY_CARD = 'IS_STUDENT_PAY_CARD';
    public static CART_ORDER_TYPE = 'CART_ORDER_TYPE';
    
}