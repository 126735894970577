import { Injectable } from '@angular/core';
import { WindowReferenceService } from './window-reference.service';
import moment from 'moment';
import { environment } from 'environments/environment';


@Injectable({
    providedIn: 'root'
})
export class GTMDataLayerService {
   private window; 
   isProd: boolean = false;
   constructor (private _windowRef: WindowReferenceService)
   {
       this.window = _windowRef.nativeWindow; // intialise the window to what we get from our window service
       if(environment.base_url=='https://apimerged.wsfx.in/' || environment.base_url_grid=='https://api.wsfx.in/'){
            this.isProd=true;
       }
   }

    public dataLayerPushData(gtmObj)
    {
    
        let currentDate = moment(new Date()).format('DD-MM-YYYY HH:mm:ss');
        gtmObj["datatime"] = currentDate;
        if(gtmObj) {
            console.log("environment>>"+this.isProd);
            try {
                if(this.isProd) {
                    gtmObj["env"] = "PROD";  
                    console.log("GTM DATA =================================================================");
                    this.window.dataLayer.push(gtmObj);
                    console.log(this.window.dataLayer);     
                } else {
                    // gtmObj["env"] = "QA";  
                    // console.log("GTM DATA =================================================================");
                    // this.window.dataLayer.push(gtmObj);
                    // console.log(this.window.dataLayer);     
                }
            } catch(e) {
                console.log(e);
            }
                  
        }
    }

    public resetDataLayerObject() {
        
    }
   
   

}