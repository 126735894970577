import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { CardService } from '../../services/card.service';

@Component({
  selector: 'link-international-card',
  templateUrl: './link-international-card.component.html',
  styleUrls: ['./link-international-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LinkInternationalCardComponent implements OnInit {
  public showLoader: boolean = false;
  showFreePlanCard: boolean = false;
  firstName: string;
  lastName: string;
  email: string;
  mobileNo: string;
  address: string;
  state: string;
  city: string;
  simType: string;
  airTicket: string;
  visa: string;
  panCard: string;
  selectedPlan: any;
  isError: boolean = false;
  errorMsg: string ="";

  @ViewChild('eSIMCardModal') eSIMCardModal: any;  

  constructor(
    public router: Router,
    private modalService: NgbModal,
    private cardService: CardService
  ) {}

  ngOnInit() {
    this.selectedPlan = JSON.parse(sessionStorage.getItem(ConstantsKey.ESIM_SELECTED_PLAN));
    console.log("SELECTED PLAN == ", this.selectedPlan);

  }

  getProfileList() {
 
  }

  proceedToNextPage(pageUrl) {
    this.router.navigate([pageUrl]);
  }

 
  letterOnly(event: KeyboardEvent) {
    var charCode = event.keyCode;
    if (((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8) || charCode == 32)
      return true;
    else return false;
  }

 onSubmit(form: NgForm) {
  if (form.valid) {
    this.buyEsimPackage();
  } else {
    this.markAllFieldsAsTouched(form);
    console.log('Form is invalid, please fill all required fields!');
  }
}

markAllFieldsAsTouched(form: NgForm) {
  Object.values(form.controls).forEach(control => {
    control.markAsTouched();  
  });
}


openeSIMCardModal() {
  this.modalService.open(this.eSIMCardModal, { size: 'lg',windowClass: 'centered-modal' });
}

  


  closeModalAndNavigate() {
    this.modalService.dismissAll();
    if(!this.isError) {
      this.router.navigate(['/esim']);
    }

  }

  buyEsimPackage() {

    let buySIMReqObj = {
      "zetexaConsumerDetails" : {
        "firstName": this.firstName,
        "lastName": this.lastName,
        "email": this.email,
        "mobileNo": this.mobileNo,
        "address": this.address,
        "state": this.state,
        "city": this.city,
      },
      "zetexaPackageList": [this.selectedPlan]
    };

    this.cardService.buyEsimPackage(buySIMReqObj).subscribe(res => {
      this.isError = false;
      this.openeSIMCardModal();
    }, err => {
      this.isError = true;
      this.errorMsg = err.error.message;
      this.openeSIMCardModal();
    });
  }


}
