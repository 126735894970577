import { CoreMenu } from '@core/types';
export const menu: CoreMenu[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'item',
    icon: 'icon-dashboard',
    url: '/new-dashboard'
  },
  {
    id: 'myprofile',
    type: 'section',
    title: 'Account',
    icon: 'icon-account',
    children: [
      {
        id: 'myaccount',
        title: 'My Account',
        type: 'item',
        icon: 'icon-account',
        url: '/my-account'
      },
      // {
      //   id: 'updateprofile',
      //   title: 'Edit Profile',
      //   type: 'item',
      //   icon: 'icon-account',
      //   url: '/update-profile'
      // },
      {
        id: 'relationship',
        title: 'Relationship',
        type: 'item',
        icon: 'icon-users',
        url: '/list-relationship'
      },
      // {
      //   id: 'my-card',
      //   title: 'Card',
      //   type: 'item',
      //   icon: 'icon-credit-card',
      //   url: '/my-card'
      // },
      {
        id: 'beneficiary',
        title: 'Beneficiary',
        type: 'item',
        image: 'assets/images/header-icons/beneficiary.png',
        url: '/list-beneficiary'
      },

      {
        id: 'bankaccount',
        title: 'My Bank Account',
        image: 'assets/images/header-icons/myBankAccount.png',
        type: 'item',
        url: '/list-bank-account',
        externalUrl: false,
        openInNewTab: false
      },

    ]
  },
  {
    id: 'insurance',
    title: 'Insurance',
    type: 'section',
    image: 'assets/images/header-icons/insurance.png',
    children: [
      {
        id: 'insurance-list',
        title: 'Insurance List',
        type: 'item',
        image: 'assets/images/header-icons/insuranceList.png',
        url: '/insurance-list'
      },
      {
        id: 'insurance-form',
        title: 'Insurance Form',
        type: 'item',
        image: 'assets/images/header-icons/insuranceForm.png',
        url: '/insurance'
      },
    ]
  },
  {
    id: 'myorders',
    type: 'section',
    title: 'My Orders',
    image: 'assets/images/header-icons/myOrder.png',

    children: [
      {
        id: 'orders',
        title: 'Orders',
        type: 'item',
        image: 'assets/images/header-icons/orders.png',
        url: '/order-list'
      },
      {
        id: 'pendingorders',
        title: 'Pending Orders',
        type: 'item',
        image: 'assets/images/header-icons/pendingOrders.png',
        url: '/pending-order'
      },
    ]
  },
  {
    id: 'More',
    type: 'section',
    title: 'More',
    icon: 'icon-more',
    children: [
      {
        id: 'refer-and-earn',
        title: 'Refer & Earn',
        type: 'item',
        image: 'assets/images/header-icons/Rewards.png',
        url: 'refer-and-earn'
      },
      {
        id: 'rewards',
        title: 'Rewards',
        type: 'item',
        image: 'assets/images/header-icons/Rewards.png',
        url: 'rewards-credit-points'
      },
      {
        id: 'offers',
        title: 'Offers',
        type: 'item',
        image: 'assets/images/header-icons/Offers.png',
        url: 'offer-coupons'
      },

      {
        id: 'e-sim',
        title: 'E-Sim',
        type: 'item',
        image: 'assets/images/header-icons/Offers.png',
        url: 'esim'
      },

      {
        id: 'lounge-access',
        title: 'Lounge Access',
        type: 'item',
        image: 'assets/images/header-icons/Offers.png',
        url: 'lounge-access'
      },

      {
        id: 'branch-locator',
        title: 'Branch Network',
        type: 'item',
        image: 'assets/images/header-icons/branchNetwork.png',
        url: 'branch-network'
      },
      {
        id: 'currency-converter',
        title: 'Currency Converter',
        type: 'item',
        image: 'assets/images/header-icons/currencyConverter.png',
        url: 'currency-converter'
      },
      {
        id: 'securitysettings',
        title: 'Reset Password',
        type: 'item',
        image: 'assets/images/header-icons/resetPassword.png',
        url: 'security-setting'
      },
      {
        id: 'rates',
        title: 'Rates',
        icon: 'icon-growth-graph',
        type: 'item',
        url: '/rates',
        externalUrl: false,
        openInNewTab: false
      },
      {
        id: 'rate-alert',
        title: 'Set Rate Alert',
        image: 'assets/images/header-icons/rateAlert.png',
        type: 'item',
        url: '/set-rate-alert',
        externalUrl: false,
        openInNewTab: false
      },
      {
        id: 'support',
        title: 'Support',
        icon: 'icon-customer-service',
        type: 'item',
        url: '/support',
        externalUrl: false,
        openInNewTab: false
      },
      {
        id: 'address',
        title: 'Address',
        icon: 'icon-placeholder',
        type: 'item',
        url: '/list-address',
        externalUrl: false,
        openInNewTab: false
      },
      {
        id: 'FAQ',
        title: 'FAQ',
        icon: 'icon-information',
        type: 'item',
        url: '/faq',
        externalUrl: false,
        openInNewTab: false
      },
      {
        id: 'tutorial',
        title: 'Tutorial',
        icon: 'icon-tutorial',
        type: 'item',
        url: '/tutorial',
        externalUrl: false,
        openInNewTab: false
      },
      {
        id: 'logout',
        title: 'Logout',
        type: 'item',
        icon: 'icon-power-off',
        url: '/logout'
      },
    ]
  }
];
