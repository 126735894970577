<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6 mb-20">
                        <h6 class=" float-left mb-0 ml-10 fs-18 fw-700" style="color: #1D2A62">International Student Identity Card</h6>
                    </div>
                </div>
                <div *ngIf="requestStatus == 'NOT_REGISTER'" class="col-md-12 mb-20 text-start" style="color: black;">
                    <p><b>Apply for ISIC Card</b></p>  
                </div>
                <form *ngIf="requestStatus == 'NOT_REGISTER'" #form="ngForm" (ngSubmit)="onSubmit(form)">
                    <div class="row">
                        
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 form-group">
                            <label class="form-label text-black fs-11">Name*</label>
                            <input type="text" name="name" class="form-control fs-12" placeholder="Name*" [(ngModel)]="fullName" required (keypress)="letterOnly($event)">
                            <span *ngIf="form.submitted && !form.controls['name']?.valid" class="invalid-form">
                                <small class="form-text text-danger">This field is required!</small>
                            </span>
                        </div>
                

                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 form-group">
                            <label class="form-label text-black fs-11">Email ID*</label>
                            <input type="email" name="email" class="form-control fs-12" placeholder="Enter Email ID*" [(ngModel)]="email" required>
                            <span *ngIf="form.submitted && !form.controls['email']?.valid" class="invalid-form">
                                <small class="form-text text-danger">This field is required!</small>
                            </span>
                        </div>                        
                      
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 form-group">
                            <label class="form-label text-black fs-11">Gender*</label>
                            <ng-select class="fs-12" [clearable]="false" name="genderListItems" [items]="genderListItems" bindLabel="genderListItems" bindValue="genderListItems" [(ngModel)]="gender"
                                [searchable]="false" placeholder="Select Gender" required>
                            </ng-select>
                            <span *ngIf="form.submitted && gender == ''" class="invalid-form">
                                <small class="form-text text-danger">This field is required!</small>
                            </span>
                        </div>
                
                  
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 form-group">
                            <label class="form-label"><b>DOB*</b></label>
                            <div class="input-group">
                                <input class="form-control fs-12" (click)="basicDP.toggle()" name="dob" style="border-color:black" placeholder="Select Date of Birth" [(ngModel)]="dob" ngbDatepicker #basicDP="ngbDatepicker" required [minDate]="{year: 1900, month: 1, day: 1}">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="basicDP.toggle()" type="button" rippleEffect></button>
                                    </div>
                            </div>
                            <span *ngIf="form.submitted && !form.controls['dob']?.valid"  class="invalid-form">
                                <small class="form-text text-danger">This field is required!</small>
                            </span>
                        </div>
                
               
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 form-group">
                            <label class="form-label text-black fs-11">City</label>
                            <input type="text" name="city" class="form-control fs-12" placeholder="" [(ngModel)]="city" required>
                            <span *ngIf="form.submitted && !form.controls['city']?.valid"  class="invalid-form">
                                <small class="form-text text-danger">This field is required!</small>
                            </span>
                        </div>                        

                        <!-- <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 form-group">
                            <label class="form-label text-black fs-11">Mobile No*</label>
                            <input type="text" name="mobile_no" class="form-control fs-12" placeholder="Enter Mobile No*" [(ngModel)]="mobileNo" required maxlength="10" pattern="^[1-9][0-9]*$" oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';">
                            <span  *ngIf="form.submitted && !form.controls['mobile_no']?.valid" class="invalid-form">
                                <small class="form-text text-danger">This field is required!</small>
                            </span>
                        </div> -->
                    </div>
                
                    <div class="row">
                   
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                            <label class="form-label text-black fs-11">Address*</label>
                            <input type="text" name="address" class="form-control fs-12" placeholder="Enter Address*" [(ngModel)]="address" required>
                            <span *ngIf="form.submitted && !form.controls['address']?.valid" class="invalid-form">
                                <small class="form-text text-danger">This field is required!</small>
                            </span>
                        </div>
                
                    

                
                     
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 form-group">
                            <label class="form-label text-black fs-11">Postal Code</label>
                            <input type="text" name="postalCode" class="form-control fs-12" placeholder="" [(ngModel)]="postalCode" maxlength="6" required>
                            <span *ngIf="form.submitted && !form.controls['postalCode']?.valid"  class="invalid-form">
                                <small class="form-text text-danger">This field is required!</small>
                            </span>
                        </div>
                    </div>
                
                    <!-- <div class="row">
                     
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 form-group">
                            <label class="form-label text-black fs-11">Visiting Country*</label>
                            <ng-select name="countryListItems" class="fs-12" [clearable]="false" [items]="countryListItems" bindLabel="countryName" bindValue="countryCode"
                                [searchable]="false" placeholder="Select Country" required>
                            </ng-select>
                            <span *ngIf="form.submitted && !form.controls['visitingCountry']?.valid"  class="invalid-form">
                                <small class="form-text text-danger">This field is required!</small>
                            </span>
                        </div>
                        
                
                     
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 form-group">
                            <label class="form-label text-black fs-11">Passport Number*</label>
                            <input type="text" name="passport_number" class="form-control fs-12" placeholder="Enter Passport Number*"  (keypress)="restrictSepcialCharacters($event)"
                                maxlength="8" required>
                            <span *ngIf="form.submitted && !form.controls['passport_number']?.valid"  class="invalid-form">
                                <small class="form-text text-danger">This field is required!</small>
                            </span>
                        </div>
                    </div> -->
                
                    <div class="row mt-20">
                        <div class="col-md-12 col-9 text-right">
                         
                            <button type="submit" rippleEffect class="btn btn-primary fs-12">
                                Submit
                              </button>
                        </div>
                    </div>
                </form>
                <div class="row" *ngIf="photoStatus == 'PENDING'">
                    <div class="col-12">
                        <p>The card must contain cardholder's photo which was not uploaded yet, thus the card cannot be displayed. Please upload your photo</p>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-3">
                                <div class="form-group">
                                    <label for="customFile"><b>Upload Photo <span style="color:red; font-size: 10px;">(Only files in JPEG,JPG format can be uploaded)</span></b></label>
                                    <div class="custom-file">
                                        <input type="file" id="customFile" (change)="fileUploadPhoto($event)" class="custom-file-input fs-12" accept="image/jpeg" />
                                        <label class="custom-file-label" for="customFile">Choose file</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <button type="button" rippleEffect class="btn btn-primary  fs-12" (click)="uploadPhoto()">Uplaod</button>
                    </div>
                </div> 
                <div class="row" *ngIf="photoStatus == 'UPLOAD'">
                    <div class="col-12">
                        <img [src]="imageData" />
                    </div>
                </div>
            </div>
        </div>
    </div>  
   
    <ng-template #eSIMCardModal let-modal>
        <div class="success-img">
          <img src="assets/images/esim/success.png" class="success-img" style="margin-top: 30px;margin-left: 200px;" >
        </div>
        <div class="link-text">Your Application has been submitted successfully!</div>
        
        <div class="col-md-12 mt-20">
          <button type="button" rippleEffect class="btn-linkcard1 fs-10 w-80" (click)="closeModalAndNavigate()">
            Ok
          </button>
        </div>
      </ng-template>
    
     