<loader *ngIf="showLoaderForGetCardDetails"></loader>
<loader *ngIf="showLoader"></loader>
<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-7">
                        <div class="fw-600 fs-18" style="color: #1d2a62">Choose your purpose for sending money abroad</div>
                        <div class="mt-10 fs-14 mb-10 fw-600">Student Remittance</div>
                        <section id="wishlist" class="grid-view wishlist-items " >
                            <div class="dash-card" *ngFor=" let singleMenu of studentRemittance ;let i=index " [ngClass]="selectedPurposeItem?.title==singleMenu.title
                            ? 'ecommerce-card-selected' : '' " (click)="getActiveSectionTitle(singleMenu)">
                                <div class="p10">
                                    <div style=" width: 72px; height: 72px; " style="cursor: pointer;">
                                        <div style=" padding-top:13px; ">
                                            <img src="{{ singleMenu.icon }}" style=" width: 42px;margin: auto;display: block; ">
                                        </div>
                                    </div>
                                </div>

                                <div style="padding:5px 13px 10px 13px">
                                    <div class="fw-600 fs-9 text-center">{{ singleMenu.title }}
                                    </div>
                                </div>
                            </div>
                        </section>

                        <div class="mt-30 fs-14 mb-10 fw-600">Personal Remittance</div>
                        <section id="wishlist" class="grid-view wishlist-items " >
                            <div class="dash-card" *ngFor=" let singleMenu of personalRemittance ;let i=index " [ngClass]="selectedPurposeItem?.title==singleMenu.title
                            ? 'ecommerce-card-selected' : '' " (click)="getActiveSectionTitle(singleMenu)">
                                <div class="p10">
                                    <div style=" width: 72px; height: 72px; " style="cursor: pointer;">
                                        <div style=" padding-top:13px; ">
                                            <img src="{{ singleMenu.icon }}" style=" width: 42px;margin: auto;display: block; ">
                                        </div>
                                    </div>
                                </div>

                                <div style="padding:5px 13px 10px 13px">
                                    <div class="fw-600 fs-9 text-center">{{ singleMenu.title }}
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                    <div class="col-md-1 dashed-border"></div>
                    <div class="col-md-4 d-none d-md-block">
                        <ul ngbNav #nav="ngbNav" class="nav nav-pills nav-justified justify-content-center nav-fill" style="margin-bottom: 0px !important; ">
                            <li class="nav-item w-100" ngbNavItem>
                                <a ngbNavLink class="nav-link" href="#sendMoney" [ngClass]="{ 'active':activeTab==='SEND_MONEY'}" (click)="getActiveTab('SEND_MONEY')" data-toggle="tab">Send Money</a>
                                <ng-template ngbNavContent>
                                    <section id="sendMoney">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="row">
                                                    <div class="col-md-12 col-12">
                                                        <div class="input-group">
                                                            <div ngbDropdown class="input-group-prepend">
                                                                <ng-select class="fs-12 dropdown-black" [clearable]="false" [items]="currencyItems" bindLabel="currencyCode" bindValue="currencyCode" [(ngModel)]="quotationCurrencyItem.currencyCode"  (change)="convertAmount()"  [searchable]="false">
                                                                    <ng-template class="ng-select-container" ng-option-tmp ng-label-tmp let-item="item">
                                                                        <img src="assets/images/currencyFlag/{{item.currencyCode}}.png" style="height:15px;width: 15px;"> {{item.currencyCode}}
                                                                    </ng-template>
                                                                </ng-select>
                                                            </div>
                                                            <input type="text" class="form-control" placeholder="Amount" [(ngModel)]="quotationCurrencyItem.currencyAmount" onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 45 && event.keyCode !== 43 && event.keyCode !== 101  && event.keyCode !== 187"
                                                                (keypress)="keyPressNumbers($event)" (keyup)="enterCurrencyAmount()" />
                                                        </div>

                                                    </div>
                                                    <div class="col-md-12 col-12">
                                                        <div class="input-group  mt-20">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text bg-inr" id="basic-addon3">
                                                                    <img src="assets/images/logo/india.png" class="inr-flag">
                                                                    &nbsp; INR
                                                                </span>
                                                            </div>
                                                            <input type="text" class="form-control" id="basic-url3" aria-describedby="basic-addon3" placeholder="Amount" value="{{rateCalculatorResponse?.inrAmount}}" readonly />
                                                        </div>
                                                        <small class="form-text blue-text fs-9 text-right fw-600">
                                                            1 {{ quotationCurrencyItem.currencyCode }} = {{
                                                                rateCalculatorResponse?.rate }} INR
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-12 mt-10">
                                                <span class="fs-18 fw-600">You Pay -  <span class="fs-19 fw-600"> ₹ {{rateCalculatorResponse?.inrAmountWithCharges}}
                                                </span></span>
                                                <div class="text-black40 fs-9 mt-5">Including Forex Markup
                                                    <!-- {{quotationResponse?.finalCharges}} -->
                                                </div>
                                                <div class="fw-600 fs-9">*Plus Government taxes as applicable</div>
                                            </div>
                                            <div class="col-md-12 mt-20">
                                                <button type="submit" rippleEffect class="btn btn-primary fs-10 w-100" (click)="proceedToStepper()">
                                                    Book Now
                                                </button>
                                            </div>
                                        </div>
                                    </section>
                                </ng-template>
                            </li>
                        </ul>
                        <div [ngbNavOutlet]="nav" class="card p-20"></div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- vcip complete or not modal  -->
<ng-template let-modal #vcipCompleteOrNotModal>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <div class="text-center text-black fw-500 mt-10 mb-10 fs-14" style="color: black">
                Dear Customer, Your <span class="fw-600 text-black">Video Based Identification Process (VCIP)</span> is pending.
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="row ">
            <button class="col-md-4 offset-md-1  btn  fs-10 vcip-btn" rippleEffect (click)="VCIPNow()">
                I will complete my VCIP Now
            </button>
            <!-- <div class="col-md-1"></div> -->
            <button class="col-md-4  offset-md-1 btn btn-primary fs-10" rippleEffect (click)="proceedWithourVCIP()">
                I will complete my VCIP Later
            </button>
        </div>
    </div>
</ng-template>

<ng-template let-modal #PANDetailModal>
        <div class="modal-header" style="display:block; ">
            <h5 class="modal-title fw-600" id="myModalLabel1" style="text-align:center; color: #1d2a62;">Customer Details</h5>
        </div>
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <label class="form-label mb-5"><b>Permanent Account Number (PAN)*</b></label>
            <input required type="text" name="panNo" (keypress)="alphaNumberOnly($event)" (input)="getPanName()" class="form-control fs-12" style="border-color:black" placeholder="Enter your PAN"   (paste)="onPaste($event)" [(ngModel)]="updateProfileModel.pan" required minlength="10" maxlength="10" />
        </div>
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <label class="form-label mb-5"><b>Name as per Permanent Account Number*</b></label>
            <input required type="text" name="panName" class="form-control fs-12" style="border-color:black" placeholder="Name as per PAN"   (paste)="onPaste($event)" [(ngModel)]="updateProfileModel.name" required/>
        </div>
        <div class="modal-body">
        <div class="custom-control custom-checkbox col-md-12 fs-12">
            <input type="checkbox" [(ngModel)]="isPANCBChecked" id="checkboxForAcceptTermsAndCondition" class="custom-control-input"  style="border-top:  1.2px dashed rgb(138 144 150 / 75%) !important">
            <label class="custom-control-label text-black fs-11" for="checkboxForAcceptTermsAndCondition">  I declare that the PAN provided belongs to me.</label>
        </div>
        </div>
        <div class="modal-body">
            <label class="form-label"><b>Date Of Birth*</b></label>
            <div class="input-group">
                <input class="form-control fs-12" (click)="basicDP.toggle()" name="dob" style="border-color:black" placeholder="Select Date of Birth" [maxDate]="dobMaxDate" [(ngModel)]="updateProfileModel.dob" ngbDatepicker #basicDP="ngbDatepicker" required [minDate]="{year: 1900, month: 1, day: 1}"
                   >
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="basicDP.toggle()" type="button" rippleEffect></button>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            
            <button class="col-md-12  mt-10 btn btn-primary fs-10"  rippleEffect (click)="validatePanAPI()">
            Proceed
        </button>
        </div>
</ng-template>
<ng-template let-modal #AmountLimitModal>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <span class="text-center">Dear Customer,</span><br>
        <span class="text-center">Please be informed that our online portal facilitates allow transaction up to $25,000 (or equivalent).</span><br>
        <span class="text-center">If your transaction exceeds this limit, we kindly request you to utilize our branches. </span>
    </div>
    <div class="row">
        <button class="offset-md-5 mt-10 mb-10 btn btn-primary fs-10" rippleEffect (click)="modal.dismiss('Cross click')">
        Okay </button>
    </div>
</ng-template>
<ng-template #errorModal let-modal>
    <div class="modal-header">
        <div class="modal-title fs-10 text-black text-center" id="myModalLabel1">

        </div>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="text-center red-text-delete">Invalid Details
        </div>
        <div class="text-center">
            {{panVerificationErrMsg}}       
        </div>
    </div>
    
    <div class="col-md-12  text-center">
        <div class="mb-10 fw-500 fs-10  btn btn-primary" (click)="modal.dismiss('Cross click')"
            style="cursor: pointer;">
            Close
        </div>
    </div>
</ng-template>