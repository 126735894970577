<div class="content-wrapper container-xxl p-0">
    <div class="content-body card-body card">
        <!-- <div class="content-header">
            <h5 class="mb-0 text-black fw-700 fs-14  mb-10">Add Currency</h5>
            <hr class="my-50 hr-color">
        </div> -->
        <div class="row" *ngIf="!isCartEmpty">
            <div class="col-md-3"></div>

            <div class="col-md-6">
                <label class="text-danger fs-10 fw-500">Rates will refresh after {{ progressWidth }}
                    minutes
                </label>
                <div class="progress">
                    <div class="progress-bar bg-danger" role="progressbar"
                        [ngStyle]="{'width.%': progressWidthInPercentage}" aria-valuemin="0"></div>
                </div>
            </div>
        </div>
        <loader *ngIf="showLoader"></loader>
        

        <div class="row mt-30">
            <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12 card-order ">
                <div class="row">
                    <div class="col-md-12  mb-1">
                        <div class="card-header text-black fw-500 fs-14">
                            Add Currency to your Forex Card
                        </div>
                        <div class="row" style="padding:0rem 1.5rem 0.2rem 1.5rem">

                            <div class="col-md-4 col-12  mb-1 pl-0">
                                <ng-select class="fs-11" placeholder="Select Currency" [items]="forexCardCurrencyList"
                                    [(ngModel)]="currencySelected" bindLabel="currencyCode" bindValue="currencyCode"
                                    [clearable]="false" [searchable]="false">
                                    <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                        <img src="assets/images/currencyFlag/{{item.currencyCode}}.png"
                                            style="height:15px;width: 15px;"> {{item.currencyCode}}
                                    </ng-template>
                                </ng-select>
                            </div>
                            <div class="col-md-4 col-12 mb-1 pl-0">
                                <input type="number" class="form-control fs-11" [(ngModel)]="currencyCardAmount"
                                    placeholder="Amount*" aria-label="Amount" style="border-color: black"
                                    (keypress)="keyPressNumbers($event)" />
                            </div>
                            <div class="col-md-4 col-12 mb-1 pl-0 text-right">
                                <button type="submit" rippleEffect class="btn btn-primary btn-block fs-11"
                                    (click)="addCurrencyToCard()" style="height: 38px;">Add to Cart</button>
                            </div>
                        </div>

                        <section class="row mt-20" style="padding:0px 5px 0px 0px;">
                            <div class="col-md-12">
                                <div class="card  bg-light-grey" style="box-shadow : 0 0 0 0!important">
                                    <div class="card-header text-black fw-500 fs-14">
                                        Add Currency Notes (Cash)?
                                    </div>
                                    <div class="card-body row">
                                        <div class="col-md-4 col-12 mb-1 pl-0">
                                            <ng-select class="fs-12" [items]="cashCurrencyList"
                                                placeholder="Select Currency" [(ngModel)]="currencySelectedCash"
                                                bindLabel="currencyCode" bindValue="currencyCode" [clearable]="false"
                                                [searchable]="false">
                                                <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                                    <img src="assets/images/currencyFlag/{{item.currencyCode}}.png"
                                                        style="height:15px;width: 15px;"> {{item.currencyCode}}
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                        <div class="col-md-4 col-12 mb-1 pl-0">
                                            <input type="number" class="form-control fs-11"
                                                [(ngModel)]="currencyAmountCash" placeholder="Amount *"
                                                aria-label="Amount" style="border-color: black"
                                                (keypress)="keyPressNumbers($event)" />
                                        </div>
                                        <div class="col-md-4 col-12 pl-0 text-right">
                                            <button type="submit" rippleEffect class="btn btn-primary btn-block fs-10"
                                                (click)="addCashCurrencyToCard()">Add Cash</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section>
                                    <div class=" text-black fw-500 fs-14">
                                        Select nearest serving branch
                                    </div>
                                    <ng-select name="selectBranchAddress" [items]="branchAddressList" bindLabel="address"
                                        placeholder="Select WSFx Branch" class="fs-12" (change)="selectDeliveryOfficeAddress($event)"
                                        [clearable]="false">
                                        <ng-template ng-option-tmp let-item="item" let-index="index">
                                            <b>{{item.branchName}}</b><br>
                                            <span class="addressDropDown">{{item.address}}</span>
                                        </ng-template>
                                    </ng-select>
                        </section>
                    </div>
                    <loader *ngIf="showLoader"></loader>
                    
                </div>


               

            </div>



            <div class="col-md-6 col-xl-6 col-lg-6 col-sm-12 col-xs-12  " *ngIf="!isCartEmpty">
                <div class="row">
                    <div class="col-md-12  mb-5">
                        <h6 class="content-header-title float-left mb-0" style="color: #1866f4 !important">
                            Your Cart Details
                        </h6>
                    </div>

                </div>
                <div>
                    <table class="table table-bordered table-responsive">
                        <thead>
                            <tr class="table-header">
                                <th>Product</th>
                                <th>Currency</th>
                                <th>FX Amount</th>
                                <th>IBR</th>
                                <th>INR Amount</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody class="fs-10 text-black">
                            <tr *ngFor="let singleTransaction of quotationCurrencyResponsesList; let i = index">
                                <th class="mob-table">Product</th>
                                <td>

                                    {{singleTransaction?.product}}
                                </td>
                                <th class="mob-table">Currency</th>
                                <td>
                                    {{singleTransaction?.currencyCode}}
                                </td>
                                <th class="mob-table">FX Amount</th>
                                <td>
                                    {{singleTransaction?.currencyAmount}}
                                </td>
                                <th class="mob-table">IBR</th>
                                <td>
                                    {{singleTransaction.rateString | number :
                                    '1.2-2'}}
                                </td>
                                <th class="mob-table">INR Amount</th>
                                <td>
                                    {{singleTransaction.inrEquivalentAmountString}}
                                </td>
                                <th class="mob-table">Action</th>
                                <td>
                                    <span class="red-text-delete"><a
                                            (click)="removeCartItemClicked(singleTransaction)">Delete </a></span>

                                </td>
                            </tr>
                        </tbody>
                    </table>


                </div>
                <section class="invoice-preview-wrapper">
                    <div class="table-responsive mt-15 amout-breakup-bg">
                        <table class="table table-condensed table-borderless  fs-12">
                            <tbody>
                                <tr style="border-top:1px solid #f4f4f4">
                                    <td colpan="2"></td>
                                </tr>
                                <tr>
                                    <td class="thick-line text-black fw-600" colspan="3"><strong>Amount</strong></td>
                                    <td class="thick-line text-right text-black fw-600 "> ₹
                                        {{quotationResponse?.billableAmountString}}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="no-line " colspan="3"><strong>Forex Margin</strong></td>
                                    <td class="no-line text-right">₹ {{ quotationResponse?.marginChargesString}} </td>
                                </tr>
                                <tr>
                                    <td class="no-line " colspan="3"><strong>Service Charges </strong></td>
                                    <td class="no-line text-right">₹ {{ quotationResponse?.finalChargesString }} </td>
                                </tr>
                                <tr *ngIf="quotationResponse?.tcs?.tcsAmountString!=0.0">
                                    <td class="no-line " colspan="3"><strong>TCS ({{
                                            quotationResponse?.tcs?.tcsPercentageString }}%)</strong></td>
                                    <td class="no-line text-right">₹ {{ quotationResponse?.tcs?.tcsAmountString }} </td>
                                </tr>
                                <tr>
                                    <td class="no-line " colspan="3"><strong>GST</strong></td>
                                    <td class="no-line text-right">₹ {{ quotationResponse?.gstString }} </td>
                                </tr>

                                <tr style="border-top:1px solid #bdbcbc">
                                    <td class="no-line text-black fw-800 pt-10" colspan="3"> Total Payable </td>
                                    <td class="no-line text-right text-black fw-800 pt-10"> ₹ {{
                                        quotationResponse?.totalBillableAmountString | number }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </section>

            </div>
        </div>
        <hr class="dotted">

        <div class="d-flex justify-content-between mt-20">
            <button type="button" class="btn btn-primary btn-back fs-12" rippleEffect (click)="backClick()">
                <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                <span class="align-middle">Back</span>
            </button>
            <button type="submit" class="btn btn-primary btn-next fs-12" [ngClass]="isFromReload == true ? 'reload-card-order-start' : 'buy-new-card-order-start'" (click)="cartInitiate()" rippleEffect>
                <span class="align-middle">Next</span>
                <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
            </button>
        </div>
    </div>
</div>
<ng-template #deleteConfirmationDailog let-modal>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <div class="text-center text-black fw-500 mt-10 mb-10 fs-12">
                <span class="text-center"> Do you want to Remove </span> <br> {{deleteItemMesg}}
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6 modal-footer border-right">
            <div class="row col-12">
                <div class="fw-500 fs-12 blue-text" (click)="removeCartItem()" style="cursor: pointer; padding-left: 50%;">
                    Yes
                </div>
            </div>
        </div>
        <div class="col-6 modal-footer border-left">
            <div class="row col-12">
                <div class="fw-500 fs-12 red-text-delete" (click)="modal.dismiss('Cross click')"
                    style="cursor: pointer; padding-left: 40%;">
                    No
                </div>
            </div>
        </div>
    </div>
</ng-template>