import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CardService } from '../../services/card.service';
import { ToastrService } from 'ngx-toastr';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import {Location} from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'lounge-access',
  templateUrl: './lounge-access.component.html',
  styleUrls: ['./lounge-access.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoungeAccessComponent implements OnInit {
  public showLoader: boolean = false;
  isChecked: boolean = false; 
  showAll: boolean = false;
  selectedCity: any;
  selectedLounge: any;
  passUrl: string;
  kitId: string;
  isStudentPayCard: boolean = false;


  @ViewChild('loungePassCardModal') loungePassCardModal: any;
  @ViewChild('loungeDocumentViewModal') loungeDocumentViewModal: any;  
 
  public menuItems = [
    {
      'icon': 'lounge1.png',
      'title': 'Vast Network of Partner Lounges',
      'url': '',
    },
    {
      'icon': 'lounge2.png',
      'title': 'Free Wi-Fi and Relaxing Amenities',
      'url': '',
    },
    {
      'icon': 'lounge3.png',
      'title': 'Complimentary Snacks, Drinks, Full Meals',
      'url': '',
    },
    {
      'icon': 'lounge4.png',
      'title': 'Premium Facilities for Added Comfort',
      'url': '',
    },
    {
      'icon': 'lounge5.png',
      'title': 'QR-Based Easy Lounge Entry Access',
      'url': '',
    },
  ]
  public specItems = [
    {
      'icon': 'spec1.png',
      'title': 'Voucher Usage Requires Identification',
      'url': '',
    },
    {
      'icon': 'spec2.png',
      'title': 'Non-Refundable and Non-Transferable',
      'url': '',
    },
    {
      'icon': 'spec3.png',
      'title': 'Stay Duration Limit Applies',
      'url': '',
    },
    {
      'icon': 'spec4.png',
      'title': 'Service Standards May Vary',
      'url': '',
    },
    {
      'icon': 'spec5.png',
      'title': 'Check Services on Website',
      'url': '',
    },
  ]

  public loungeList:any = [];
  public cityItems = [
    {
      'icon': 'Mumbai.png',
      'title': 'Mumbai',
      'url': ''
    },
    {
      'icon': 'New Delhi.png',
      'title': 'New Delhi',
      'url': ''
    },
    {
      'icon': 'Bengaluru.png',
      'title': 'Bengaluru',
      'url': ''
    },
    {
      'icon': 'Chennai.png',
      'title': 'Chennai',
      'url': ''
    },
    {
      'icon': 'Ahmedabad.png',
      'title': 'Ahmedabad',
      'url': ''
    },
    {
      'icon': 'Amritsar.png',
      'title': 'Amritsar',
      'url': ''
    },
    {
      'icon': 'Calicut.png',
      'title': 'Calicut',
      'url': ''
    },
    {
      'icon': 'Chandigarh.png',
      'title': 'Chandigarh',
      'url': ''
    },
    {
      'icon': 'Cochin.png',
      'title': 'Cochin',
      'url': ''
    },
    {
      'icon': 'Hyderabad.png',
      'title': 'Hyderabad',
      'url': ''
    },
    {
      'icon': 'Kannur.png',
      'title': 'Kannur',
      'url': ''
    },
    {
      'icon': 'Kolkata.png',
      'title': 'Kolkata',
      'url': ''
    },
    {
      'icon': 'Pernem.png',
      'title': 'Pernem',
      'url': ''
    },
    {
      'icon': 'Pune.png',
      'title': 'Pune',
      'url': ''
    },
    {
      'icon': 'Thiruvananthapuram.png',
      'title': 'Thiruvananthapuram',
      'url': ''
    }]
   
  public activeTab: number = 0;
  public modalReference: any;

  constructor(public router: Router, private modalService: NgbModal, private cardService: CardService, public toast: ToastrService, private _location: Location, private sanitized: DomSanitizer) { }

  ngOnInit(): void {
    this.cardService.getAllCardDetail().subscribe(resp => {
      let respData:any = resp.body;
      let card = respData.filter(data => data.cardVendor == 'WSFXSTUDENTSCC');
      if(card.length != 0) {
        this.kitId = card[0]["kitId"];
        this.isStudentPayCard = true;
      }
    });
  }

  toggleView() {
    this.showAll = !this.showAll;
  } 
  
  proceedToNextPage(pageUrl: string): void {
    this.router.navigate([pageUrl]);
  }
  onCheckboxChange() {
    console.log('Checkbox state:', this.isChecked);
  }
  setActiveTab(tabIndex: number) {
    this.activeTab = tabIndex;
  }
  openLoungePassCardModal() {
    this.modalService.open(this.loungePassCardModal, { size: 'md', centered: true });
  }
 
  openESIMModal() {    
    if(this.isStudentPayCard && this.isStudentPayCard == true) {
      if(this.validate()) {
        // this.toast.success('Coming soon!');
        this.claimLounge();
        //this.modalService.open(this.loungePassCardModal, { centered: true });
      }
    } else {
      this.toast.error("Sorry! You do not have Student Pay Card");
    }
  }

  closeModalAndNavigate() {
      this.modalService.dismissAll(); 
  }

  getLounge(city) {
    this.showLoader = true;
    this.selectedCity = city;
    this.cardService.getLoungeAccessList(this.selectedCity.title).subscribe(res => {
      this.showLoader = false;
      console.log("Lounge Access List ============== ", res);
      this.loungeList = res.body;
    }, err => {
      this.showLoader = false;
      this.toast.error(err.error.message);
    })
  }

  selectLounge(lounge) {
    this.selectedLounge = lounge;
    console.log(lounge);
  }

  validate() {
    if(!this.selectedCity) {
      this.toast.error("Please select City");
      return false;
    } else if(!this.selectedLounge) {
      this.toast.error("Please select Lounge");
      return false;
    } else if(!this.isChecked) {
      this.toast.error("Please accept terms and condition");
      return false;
    }
    return true;
  }

  
  claimLounge() {
    this.showLoader = true;
    let reqObj = {};
    this.cardService.getLoungeAccess(reqObj, this.kitId).subscribe(res => {
      this.showLoader = false;
      let respData = res.body;
      if(respData && respData["status"] ==  true) {
        let loungeData = respData["data"];
        this.passUrl = loungeData["ecert_url"];
        this.openLoungePassCardModal();
      } else if(respData && respData["status"] ==  false) {
        this.toast.error("Something went wrong!");          
      }
    }, err => {
        this.showLoader = false;
        this.toast.error(err.error.message);
    });
  }

  
  goToNewFeatures() {
    this._location.back();
  }

  transform() { 
    return this.sanitized.bypassSecurityTrustResourceUrl(this.passUrl);
  }
 
  openLoungPass() {
    this.modalService.dismissAll(); 
    this.modalReference = this.modalService.open(this.loungeDocumentViewModal, { centered: true });
  }

  downloadLoungPass() {
    window.open(this.passUrl);
  }

}
