<div class="content-wrapper container-xxl p-0">
    <loader *ngIf="showLoader"></loader>
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6 mb-20">
                        <h6 class=" float-left mb-0 ml-10 fs-18 fw-700 d-none d-lg-block" style="color: #1D2A62">International Lounge Access</h6>
                        <div class="col-12 col-sm-6 col-md-12 mb-4">
                            <div class="lounge-img ">
                                <img src="assets/images/lounge-access/lounge-access-card.png" class="lounge-img" style="margin-top: -0px;margin-left: -20px;" >
                        </div>
                        <div class="lounge-text col-12">
                            Load your Student Pay Card with $3,000 or more to become eligible for lounge access.
                        </div>
                        <div class="row align-items-center mt-3 mb-10">
                            <div class="col-12 col-md-8">
                                <div class="lounge-text1">
                                    Explore Available Lounges Near You
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <button class="btn btn-toggle" (click)="toggleView()">
                                    {{ showAll ? 'View Less' : 'View All' }}
                                </button>
                            </div>
                        </div>
                        

                        <div class="benefit">
                            <div class="row mt-6" style="width: 100%;">  
                                <div class="col-6 col-sm-4 col-md-3 mb-3" *ngFor="let singleItem of cityItems | slice: 0:showAll ? cityItems.length : 4"> 
                                    <div class="d-flex align-items-center" style="flex-direction: column;" (click)="getLounge(singleItem)" [ngClass]="{'selectedCity': ((selectedCity) ? (singleItem.title == selectedCity?.title) : false) }">
                                        <img src="assets/images/lounge-access/{{singleItem.icon}}" style="width: 80px; height: 80px;">
                                        <div class="fs-14 fw-600" style="">
                                            {{ singleItem.title }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                           
                        <div class="row align-items-center mt-2 mb-10">
                            <div class="col-12 col-md-8">
                                <div class="lounge-text1">
                                    Available Lounges at Mumbai
                                </div>
                            </div>
                        </div> 
                            

                            <div class="row mt-6" style="width: 100%">  
                                <div class="col-6 col-sm-4 col-md-3 mb-3" *ngFor="let lounge of loungeList"> 
                                    <div class="d-flex align-items-center" style="flex-direction: column;" (click)="selectLounge(lounge)" [ngClass]="{'selectedCity': ((selectedLounge) ? (lounge.outletId == selectedLounge?.outletId) : false) }">
                                        <img src="assets/images/lounge-access/{{selectedCity.icon}}" style="width: 80px; height: 80px;">
                                        <div class="" style="">
                                            <p class="p-0 m-0 fs-12 fw-500 text-center">{{ lounge.outletName }}</p>
                                            <!-- <p class="p-0 m-0 fs-11 text-center">{{ lounge.outletId }}</p> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                        
                        
                        
                        <div class="lounge-text2 col-12">
                            <label>
                                <input type="checkbox" [(ngModel)]="isChecked" /> 
                            I accept the terms and conditions for claiming the complimentary lounge access provided by WSFX in partnership with DreamFolks. I agree to provide valid identification for verification, and understand that this benefit is non-transferable and non-refundable. I consent to the use of my information for verification purposes.
                        </label>  
                        </div>
                        <div class="col-md-12 mt-20">
                            <button type="submit" rippleEffect class="btn-lounge fs-10 w-80" (click)="openESIMModal()">
                                Claim Now
                            </button>
                        </div>
                    </div>
                </div>
            
                <div class="lounge-border dash-lounge">
                    <div class="col-md-12">
                        <ul ngbNav #nav="ngbNav" class="nav nav-pills nav-justified justify-content-center nav-fill" style="margin-bottom: 0px !important; display: flex; flex-wrap: wrap; padding: 0;">
                            <li class="nav-item col-6 col-md-12" ngbNavItem style="margin-bottom: 0;">
                                <a ngbNavLink class="lounge-link fs-12" href="" data-toggle="tab" (click)="setActiveTab(0)"
                                style="border: 1.2px solid #1D2A62; padding: 15px; border-radius: 0; display: block; width: 139%;">
                                    Features
                                </a>
                                <div *ngIf="activeTab === 0" class="row mt-6 lounge-row" style="margin-bottom: 0;">
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2" *ngFor="let singleItem of menuItems" style="margin-bottom: -2rem; margin-right:50px;">
                                        <div class="d-flex">
                                            <img src="assets/images/lounge-access/{{singleItem.icon}}" style="width: 40px; height: 40px; margin-top: 15px;margin-left: -60px;">
                                            <div class="fs-11 fw-600" style="color: #000; padding-left: 15px; padding-top: 30px; text-align: left; margin-bottom: 40px; white-space: nowrap;">
                                                {{ singleItem.title }}
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </li>
                
                            <li class="nav-item c" ngbNavItem style="margin-bottom: 0;">
                                <a ngbNavLink class="lounge-link fs-12" href="" (click)="setActiveTab(1)"
                                    style="width: 123%; border: 1.2px solid #1D2A62; padding: 15px; margin-left: -10px; border-radius: 0; display: block;">
                                    Specifications
                                </a>
                                <div *ngIf="activeTab === 1" class="row mt-6 lounge-specifications-row" style="margin-left: -126px; margin-bottom: 0;">
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2" *ngFor="let singleItem of specItems" style="margin-bottom: -2rem;">
                                        <div class="d-flex" style="height: 100%;">
                                            <img src="assets/images/lounge-access/{{singleItem.icon}}" style="width: 40px; height: 40px; margin-top: 20px;margin-left: -30px">
                                            <div class="fs-11 fw-600" style="color: #000; padding-left: 15px; padding-top: 30px; text-align: left; margin-bottom: 40px; white-space: nowrap;">
                                                {{ singleItem.title }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-6 text-left ">
                    <button  class="btn btn-lounge1" (click)="goToNewFeatures()">
                        Back
                    </button>
                    </div>
                
            </div>
        </div>
    </div>
</div>

<ng-template #loungePassCardModal let-modal>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="closeModalAndNavigate()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="success-img" *ngIf="!isError" style="width: 100%; text-align: center;">
            <img src="assets/images/lounge-access/success.gif" class="success-img" >
        </div>
        <p class="link-text" *ngIf="!isError">Congratulations!</p>
        <p class="link1-text" *ngIf="!isError">
         You've successfully claimed your free lounge access. Enjoy a peaceful break before your journey!
        </p>
       
        <div class="row">
        <div class="col-md-6 col-12 mt-20">
            <button type="button" rippleEffect class="btn-linkcard1 btn-block" (click)="downloadLoungPass()">View Lounge pass</button><!-- openLoungPass() -->
        </div>
        <div class="col-md-6 col-12 mt-20">
            <button type="button" rippleEffect class="btn-linkcard1 btn-block" (click)="downloadLoungPass()">Download Lounge pass</button>
        </div>
    </div>
    </div>
  </ng-template>
  <ng-template #loungeDocumentViewModal let-modal>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12" *ngIf="this.passUrl !== ''">
            <iframe [src]="transform()" style="height:400px;display:block;width:100%;"></iframe>
        </div>
    </div>
</ng-template>